import { useEffect, useState } from "react";
import { Card, Typography, Button, Form, Input, Space } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FormOutlined, MenuUnfoldOutlined, SendOutlined } from "@ant-design/icons";
import { baseurl } from "../../lib/settings";
import { ButtonStyles } from "../static/css/Style/colors";
import SearchSelectField from "../modal/components/select_search_field";
import { TRAINING_CAPACITY_NUMBER_ERROR, TRAINING_CAPACITY_NUMBER_WARNING, TRAINING_SELECTION_ERROR } from "../../utils/constants";
import { GenericSuccessMessage, SomeThingErrorMessage } from "../Alerts/genericAlert";
// import { format } from "date-fns";

const historyList = [
  {
    title: "Alexandre Gavaza: Add: Fire Fithing",
    time: "09 JUN 7:20 PM",
    color: "green",
  },
  {
    title: "Abdul: Create Training fro Fire Fithing",
    time: "08 JUN 12:20 PM",
    color: "green",
  },
  {
    title: "Add employee to Team Abdul",
    time: "04 JUN 3:10 PM",
  },
  {
    title: "Alexandre Gavaza promote: Abdul to admin",
    time: "02 JUN 2:45 PM",
  },
  {
    title: "Create more Formations",
    time: "18 MAY 1:30 PM",
  },
];

export default function SuggestTraining({controlForm, viewControlForm, chartControlFunction}) {
  const {buttonSyle} = ButtonStyles();
  const { Title, Text } = Typography;
  const [reverse, setReverse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [training, setFormation] = useState(undefined);
  const [numberValue, setNumberValue] = useState(0);
  const [form] = Form.useForm();
  const [formOpenedControl, setFormOpenedControl] = useState(false);

  const openForm = () => {
    setFormOpenedControl(true);
    controlForm(true);
  }
  const sendSugestTraining = (value) => {
    if(training !== undefined){
      setFormOpenedControl(false);
      controlForm(false);
      const data = {
        formation_id:training,
        capacity:value.capacity,
      }
      baseurl.post('v1/formation_suggestion/', data).then((resp) => {
        setMessage(resp.data['message']);
        GenericSuccessMessage(resp.data['message']);
        form.resetFields();
        chartControlFunction(true);
      }).catch((error) => {
        console.log("Error ", error);
        setMessage(error.response.data['message']);
        SomeThingErrorMessage(error.response.data['message']);
        setFormOpenedControl(true);
        controlForm(true);
      })
    }
  }
  return (
    <>
      <Card bordered={true} className="criclebox h-full">
        
          <Space style={{width:'100%'}} direction="vertical" size={12}>
            <div className="timeline-box" style={{ alignContent: "center", textAlign: "center", }}>
              <Button onClick={openForm} disabled={viewControlForm} style={{
                backgroundColor: viewControlForm ? buttonSyle.disabledColor : buttonSyle.defaultColor
              }} block>
                <Title level={5}>Request Training</Title>
              </Button>
            </div>
            {viewControlForm ? <Form
              layout="vertical"
              disabled={isLoading}
              form={form}
              onFinish={sendSugestTraining}
            >
              <Form.Item 
                label="Description"
                name='training'
                >
                <SearchSelectField
                  formations={(value) => {
                    setFormation(value);
                    // training(value);
                  }}
                />
              </Form.Item>

              <Form.Item 
                label="No. Employees to attend"
                name='capacity'
                rules={[
                  {
                    required: true,
                    message: TRAINING_CAPACITY_NUMBER_WARNING,
                  },
                  {
                    validator(_, value) {
                      if (!value || value <= 25) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          TRAINING_CAPACITY_NUMBER_ERROR
                        );
                      }
                    },
                  },
                ]}>
                <Input 
                  min="1"
                type="number"/>
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" block icon={<SendOutlined />}></Button>
              </Form.Item>
            </Form> : <></>}
          </Space>
        
      </Card>
    </>
  );
}
