import React, { useState } from "react";
import { Card, Col, Row, Input, Button, Form, Switch, Space, Typography, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { baseurl } from "../../lib/settings";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
} from "../../components/Alerts/genericAlert";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { userCreationDetails } from "../../components/Messages/customs";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
const { Title, Paragraph, Text, Link } = Typography;
export default function UserRegistratition() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("automatic");
  const [userPermission, setUserPermission] = useState("supervisor");
  const [sendEmail, setSendEmail] = useState(true);
  const [success, setSuccess] = useState();
  const [info, setInfo] = useState();
  const [error, setError] = useState();

  function onFinish(values) {
    setSuccess(false)
    setInfo(false)
    setError(false)
    setLoading(true);
    const data = {
      full_name: values.full_name,
      email: values.email,
      password: values.password,
      send_email: sendEmail,
      password_type: passwordType,
      user_permission: userPermission,
    };

    baseurl
      .post("register", data)
      .then((resp) => {
        if (resp.data.created) {
          setLoading(false);
          setSuccess("User successfully registered");
        }
        // form.resetFields()
        setInfo(resp.data.info);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status === 400) {
          setError(e.response.data);
        }
      });
  }

  const validateOption = (type, valeu) => {
    if (type === "password") {
      if (valeu === true) {
        setPasswordType("automatic");
      } else {
        setPasswordType("manual");
      }
    } else if (type === "email") {
      setSendEmail(valeu);
    }
  };

  return (
    <>
      
      <Col style={{
                textAlign:'-webkit-center'
            }} xs={24} className="mb-24">
        <Card
          // style={{
          //   width:'40%'
          // }}
          className="header-solid h-full ant-card-p-0"
          // title={
          //   <>
          //     <Row
          //       gutter={[24, 0]}
          //       className="ant-row-flex ant-row-flex-middle"
          //     >
          //       <Col xs={24} md={12}>
          //         <h6 className="font-semibold m-0">User registration</h6>
          //       </Col>
          //       <Col xs={24} md={12} className="d-flex"></Col>
          //     </Row>
          //   </>
          // }
        >
          <div style={{width:'50%'}}>
          <Title level={2}>User registration</Title>
          <Paragraph>
            <pre>{userCreationDetails}</pre>
          </Paragraph>
          {error && (
        <>
          <ErrorAlert message={error} />
          <br />
        </>
      )}
      {success && (
        <>
          <SuccessAlert message={success} />
          <br />
        </>
      )}
      {info && (
        <>
          <InfoAlert message={info} />
          <br />
        </>
      )}
          </div>
          <div
            style={{
              alignContent: "center",
              width:'50%',
              border:'2px solid rgba(140, 140, 140, 0.35)',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              padding:'2%',
              borderRadius:'10px'
            }}
          >
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                disabled={loading}
              >
                  <Row type="flex" justify="center" align="middle">
                  <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} name="full_name">
                    <Input type="text" placeholder="Full name" />
                  </Form.Item>

                  <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} name="email">
                    <Input type="text" placeholder="Email" />
                  </Form.Item>
                  <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} name="user_permission">
                    <Select
                      // placeholder="Select the division"
                      onChange={(e)=>  setUserPermission(e)}
                      defaultValue={userPermission}
                      value={userPermission}
                      style={{ width: '100%'}}
                      disabled={loading}
                    >
                      <Option key={1} value='supervisor'>
                        Supervisor
                      </Option>
                      <Option key={2} value='admin'>
                          Administrator
                      </Option>
                    </Select> 
                  </Form.Item>
                  {passwordType == "manual" && (
                    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} name="password">
                      <Input width={'100%'} type="password" placeholder="Password" />
                    </Form.Item>
                  )}
                      <Space style={{width:'100%'}} size={10} direction="vertical">
                          {/* <Form.Item  style={{ width: "100%", marginRight: 0 }}> */}
                            <Space style={{width:'100%'}} size={5} direction="horizontal">
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={(e) => validateOption("email", e)}
                                defaultChecked
                              /> 
                              <Typography >Send email with credentials</Typography>
                            </Space>
                            {/* <Checkbox
                              defaultChecked={true}
                              onChange={(e) => {
                                validateOption("email", e.target.checked);
                              }}
                            >
                              Send email with credentials
                            </Checkbox> */}
                          {/* </Form.Item> */}
                          {/* <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}> */}
                          <Space style={{width:'100%'}} size={5} direction="horizontal">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              onChange={(e) => validateOption("password", e)}
                              defaultChecked
                            /> 
                            <Typography>Generate password by the system</Typography>
                          </Space>
                            {/* <Checkbox
                              defaultChecked={true}
                              onChange={(e) => {
                                validateOption("password", e.target.checked);
                              }}
                            >
                              Generate password by the system
                            </Checkbox> */}
                          {/* </Form.Item> */}
                      </Space>
                  </Row>
                  <br/>
                <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                  <Button block loading={loading} disabled={loading} type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
          </div>
        </Card>
      </Col>
    </>
  );
}
