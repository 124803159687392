import UploadModelsByDocs from "../pages/docs/upload_employee";
import UploadLNAData from "../pages/lna/upload_data";

export const itemUploadTabTitle = [
    {
      key: '1',
      label: 'Staff',
      children: <UploadModelsByDocs />,
    },
    {
      key: '2',
      label: 'LNA',
      children: <UploadLNAData />,
    },
    {
      key: '3',
      label: 'Video',
      children: '',
    },
  ];

  export const UPDLOAD_LNA_DOC_DESC = 'For reasons of processing and data volume, this document will take a little longer than normal to be processed. We are working on solutions to improve this process.';
  export const UPDLOAD_LNA_DOC_SUCCESS_DESC = 'Congratulations, your document has been uploaded successfully. Go to the LNA page to see the uploaded data.';
  export const TRAINING_CAPACITY_NUMBER_WARNING = 'Please enter employee to atende number!';
  export const TRAINING_SELECTION_ERROR = 'Please select training!';
  export const TRAINING_CAPACITY_NUMBER_ERROR = 'Please the employee to atende cannot exceed 25!';

  export const ALLOWED_DOMINE = 'syrahresources';
  export const MANAGER_PERMISSION = 'manager_permission';
  export const ADMIN_PERMISSION = 'admin';
  export const DEFAULT_PRESET_COLOR_PIKER = [
    {
      label: 'Recommended',
      colors: [
        '#000000',
        '#000000E0',
        '#000000A6',
        '#00000073',
        '#00000040',
        '#00000026',
        '#0000001A',
        '#00000012',
        '#0000000A',
        '#00000005',
        '#F5222D',
        '#FA8C16',
        '#FADB14',
        '#8BBB11',
        '#52C41A',
        '#13A8A8',
        '#1677FF',
        '#2F54EB',
        '#722ED1',
        '#EB2F96',
        '#F5222D4D',
        '#FA8C164D',
        '#FADB144D',
        '#8BBB114D',
        '#52C41A4D',
        '#13A8A84D',
        '#1677FF4D',
        '#2F54EB4D',
        '#722ED14D',
        '#EB2F964D',
      ],
    }
    
  ];