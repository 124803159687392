import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Input,
  Select,
  Button,
  Form,
  Switch,
  Alert,
} from "antd";
import UploadDataFile from "../../components/Upload/Custom/upload";
import { loadDataMessage } from "../../components/Messages/customs";
import { baseurl } from "../../lib/settings";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
export default function UploadModelsByDocs() {
  const [form] = Form.useForm();
  const [fileData, setFileData] = useState();
  const [option, setOption] = useState("update");
  const [data_type, setDataType] = useState(0);
  const [replace, setReplace] = useState(false);
  const [update, setUpdate] = useState(true);
  const [replaceAll, setReplaceAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [info, setInfo] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const onGenderChange = (value) => {
    switch (value) {
      case "1":
        setDataType(1);
        return;
      case "2":
        setDataType(2);
        return;
      case "3":
        setDataType(3);
    }
  };
  const onOptionChange = (e, option) => {
    if (e == true) {
      switch (option) {
        case "replace":
          setOption("replace");
          setUpdate(false);
          setReplaceAll(false);
          setReplace(true);
          return;
        case "update":
          setOption("update");
          setReplaceAll(false);
          setReplace(false);
          setUpdate(true);
          return;
        case "replace_all":
          setOption("replace_all");
          setReplace(false);
          setUpdate(false);
          setReplaceAll(true);
          return;
      }
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("excel_file", fileData);
    formData.append("file_type", fileData.type);
    formData.append("option", option);
    formData.append("data_type", data_type);
    baseurl
      .post("v1/load_data_file/", formData)
      .then((resp) => {
        if (resp.data.error) {
          setError(resp.data.errorMessages);
          console.log("Have error hera", resp.data.errorMessages);
        }
        if (resp.data.info) {
          setInfo(resp.data.infoMessage);
          console.log("Have info hera", resp.data.infoMessage);
        }
        setSuccess("Table loaded successfully");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);

        setLoading(false);
      });
  };

  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };
  return (
    <div>
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <br />
        </>
      )}
      {success && (
        <Alert
          message="Success Tips"
          description={success}
          type="success"
          showIcon
        />
      )}
      <br />
      {info && (
        <>
          <Alert
            message="Informational Notes"
            description={info}
            type="info"
            showIcon
          />
          <br />
        </>
      )}
      <Col xs={24} className="mb-24">
        <Card
          className="header-solid h-full ant-card-p-0"
          title={
            <>
              <Row
                gutter={[24, 0]}
                className="ant-row-flex ant-row-flex-middle"
              >
                <Col xs={24} md={12}>
                  <h6 className="font-semibold m-0">Data loading</h6>
                </Col>
                <Col xs={24} md={12} className="d-flex"></Col>
              </Row>
            </>
          }
        >
          <Row gutter={[24, 0]}>
            <Col span={24} md={12} className="mb-24 ">
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                disabled={loading}
              >
                <Col span={12}>
                  <Form.Item
                    name="note"
                    label="Data type"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      onChange={onGenderChange}
                      allowClear
                    >
                      <Option value="1">Emploee</Option>
                      <Option value="2">Supervisor</Option>
                      <Option value="3">Department</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="options"
                    label="Loading options "
                    labelCol={{ span: 24 }}
                  >
                    <ul className="list settings-list">
                      <li>
                        <h6 className="list-header text-sm text-muted">
                          {loadDataMessage}
                        </h6>
                      </li>
                      <li>
                        <Switch
                          checked={replace}
                          onChange={(e) => {
                            onOptionChange(e, "replace");
                          }}
                        />

                        <span>Load and replace</span>
                      </li>
                      <li>
                        <Switch
                          checked={update}
                          defaultChecked
                          onChange={(e) => {
                            onOptionChange(e, "update");
                          }}
                        />
                        <span>Load and update</span>
                      </li>
                      <li>
                        <Switch
                          checked={replaceAll}
                          onChange={(e) => {
                            onOptionChange(e, "replace_all");
                          }}
                        />
                        <span>Replace all</span>
                      </li>
                    </ul>
                  </Form.Item>
                </Col>
                <Form.Item {...tailLayout}>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24} md={8} className="mb-24 ">
              <UploadDataFile setFileData={setFileData} />
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
}
