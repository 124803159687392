export const trainigDocTableColum = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Creation date",
    dataIndex: "creation_date",
    sorter: {
      compare: (a, b) => a.chinese - b.chinese,
      multiple: 3,
    },
  },
  {
    title: "Created by",
    dataIndex: "created_by",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];
