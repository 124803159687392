import { useState } from "react";
import { Alert, Input, Modal, Row, Select, Spin } from "antd";
import { baseurl } from "../../../lib/settings";
import { LoadingOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/charts";

const EmailModal = ({ visible, onClose, emailUserIdOrDescription, user_exist, is_user_id }) => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailData, setEmailData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null)
  const [gender, setGender] = useState(null)

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setIsValidEmail(emailRegex.test(inputEmail) || inputEmail === '');
  };

  const handleOk = () => {
    console.log("Verify user details ", emailUserIdOrDescription)
    if (isValidEmail) {
      setIsLoading(true);
      const data = {
        updated_email: email,
        user_id_or_description: emailUserIdOrDescription,
        user_exist:user_exist,
        gender:gender,
        is_user_id:is_user_id
      };


      baseurl.post('v1/update/empoyee_email_adress/', data)
        .then((response) => {
          setEmailData(response.data);
          setMessage(response.data.message);
        })
        .catch((error) => {
          console.log("error: ", error);
        })
        .finally(() => {
          setIsLoading(false);
                setTimeout(() => {
                    onClose();
                    setMessage(null);
                }, 3000)
        });
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const titleContent = (
    <>
      <span>The selected participant does not have an email</span>
    </>
  );

  const genderControlChange = (value) => {
    console.log(`selected ${value}`);
    setGender(value)
  };

  return (
    <>
    <Modal
      title={titleContent}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      {isLoading ? (
                  <div style={{textAlign: "center"}}>
                     <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                  </div>

      ) : message ? (
        <Alert message={message} type="success" />
      ) : (
        <>
          {user_exist ? <></> : <Alert
      message="You are trying to add a new participant to your list, to do this we will need you to indicate the participant's gender."
      banner
      closable
    /> }
          <p>Please enter the participant email:</p>
          {isLoading ? (
            <div style={{textAlign: "center"}}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
         </div>
          ) : (
            <Row gutter={[0, 16]}>
            <Input
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              style={{ borderColor: isValidEmail ? '' : 'red' }}
            />
            { user_exist ? <></> : <Select
              size="large"
              placeholder='Select the gender'
              style={{
                width: 160,
              }}
              
              onChange={genderControlChange}
              options={[
                {
                  value: 'Male',
                  label: 'Male',
                },
                {
                  value: 'Famele',
                  label: 'Fameles',
                },
              ]}
            />}
            </Row>
            
            
          )}
          {!isValidEmail && (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Please enter a valid email address
            </p>
          )}
        </>
      )}
    </Modal>
    </>
  );
};

export default EmailModal;
