import React, { useState, useEffect } from "react";
import { baseurl } from "../lib/settings";
import { Button, Card, Col, Row, Tooltip, Space, Spin, Typography } from "antd";
import dayjs from 'dayjs';
import moment from "moment";
import FeadbackScatterChart from "../components/charts/scatterChart";
import { SearchOutlined } from '@ant-design/icons';
import CustomDatepiker from "../DatePicker/custom_date_picker";
import SentimentalBarChart from "../components/charts/custom_setimental_barchart";
import { LoadingOutlined } from '@ant-design/icons';
import DetailedFeedbackForm from "../components/charts/custom_details_feedback_barchart";
import { DescriptionSpinLoading } from "../components/Spin/custom_spin";
import PieChartFeedback from "../components/charts/custom_general_feedback_pieChart";


const dateFormat = 'DD/MM/YYYY';
const submitDateFormat = 'YYYY-MM-DD';
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
function FeedbackForm() {
  const [feedbackForm, setFeedbackform] = useState([]);
  const [initDate, setinitDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [sugestionLoading, setSugestionLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [barChartData, setBarChartdata] = useState({});
  const [filterDataNumber, setFilterDataNumber] = useState(10);
  const [sugestionData, setSugestionData] = useState([]);

  useEffect(() => {
    setIsLoading(true)
    getInitDate()
    baseurl.get("v1/submit_feedback_form/").then((resp) => {
      setFeedbackform(resp.data);
      console.log("DADOS DA TABELA", resp.data);
    });
    baseurl.post("v1/feedback_form/sentimental_filter/", {
      initial_date:moment(initDate).format(submitDateFormat).toString(),
      end_date:moment(new Date().toDateString()).format(submitDateFormat  ).toString(),
      sugestion_only:false
    }).then((resp) => {
      setBarChartdata(resp.data)
      setSugestionData(resp.data.sugestions);
      setIsLoading(false)
    }).catch((err) => {
      setSearchLoading(false)
      console.log("Error in chart request ", err)
    });
  }, []);

  function getInitDate(){
    const current = new Date();
    var day = current.getDate()
    day = day - 1
    current.setDate(current.getDate()-day)
    setinitDate(current.toDateString())
    return current.toDateString()
  }

  const searchSugestionData = (value) => {
    console.log("Sene data cvalue ", value)
    setFilterDataNumber(value)
    setSugestionLoading(true)
    
    baseurl.post("v1/feedback_form/sentimental_filter/", {
      initial_date:moment(initDate).format(submitDateFormat).toString(),
      end_date:moment(new Date().toDateString()).format(submitDateFormat).toString(),
      sugestion_data_lenght:value,
      sugestion_only:true
    }).then((resp) => {
      setSugestionData(resp.data.sugestions);
      setSugestionLoading(false);
    }).catch((err) => {
      setIsLoading(false)
      console.log("Error in chart request ", err)
    });
  }

  const searchBarchartSentimentalAnalysis = () =>{
    setSearchLoading(true)
    baseurl.post("v1/feedback_form/sentimental_filter/", {
      initial_date:moment(initDate).format(submitDateFormat).toString(),
      end_date:moment(new Date().toDateString()).format(submitDateFormat  ).toString(),
      sugestion_only:false
    }).then((resp) => {
      setBarChartdata(resp.data);
      setSugestionData(resp.data.sugestions);
      setSearchLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      console.log("Error in chart request ", err)
    });
  }

  const columns = [
    {
      title: "msisdn",
      dataIndex: "msisdn",
    },
    {
      title: "training_code",
      dataIndex: "training_code",
    },
    {
      title: "q_train1",
      dataIndex: "q_train1",
    },
    {
      title: "q_train2",
      dataIndex: "q_train2",
    },
    {
      title: "q_train3",
      dataIndex: "q_train3",
    },
    {
      title: "q_form1",
      dataIndex: "q_form1",
    },
    {
      title: "q_form2",
      dataIndex: "q_form2",
    },
    {
      title: "q_form3",
      dataIndex: "q_form3",
    },
    {
      title: "sugestion",
      dataIndex: "sugestion",
    },
  ];

  // // const data = feedbackForm['feedback'].map((item) => (
  // //     {
  // //         key: '1',
  // //         // training_code: item['feedback'],
  // //         msisdn: `${item.training_code}`,
  // //         // q_train1: `${item}`,
  // //         // q_train2: `${item.q_train2}`,
  // //         // q_train3: `${item.q_train3}`,
  // //         // q_form1: `${item.q_form1}`,
  // //         // q_form2: `${item.q_form2}`,
  // //         // q_form3: `${item.q_form3}`,
  // //         // sugestion: `${item.sugestion}`,
  // //     },
  // //     console.log("tud vem aqui", item['feedback']['msisdn'])
  // // ))

  const data =
    feedbackForm.length > 0 &&
    feedbackForm[0]["feedback"].map((item, i) => ({
      key: i,
      training_code: "2",
      msisdn: "2",
      q_train1: 2,
      q_train2: "2",
      q_train3: "2",
      q_form1: "2",
      q_form2: "2",
      q_form3: "2",
      sugestion: "2",
    }));

  return (
    <div>
      {/* <Col xs={24} sm={24} md={50} lg={24} xl={24} className="mb-24"> */}
        <Card title={
          <Row
          gutter={[24, 0]}
          className="ant-row-flex ant-row-flex-middle"
        >
          <Col xs={24} md={12}>
            <h6 className="font-semibold m-0">
              Training Session Feedback Report
            </h6>
          </Col>
          <Col xs={24} md={12} className="d-flex">
            <Space direction="horizontal" size={12}>
              <CustomDatepiker 
                dateFormat={dateFormat}
                initDate={dayjs(moment(initDate).format(dateFormat).toString(), dateFormat)}
                endDate={dayjs(moment(new Date().toDateString()).format(dateFormat).toString(), dateFormat)}
                onChange={(e) => {
                  setinitDate(moment(e[0].$d).format(submitDateFormat).toString())
                  setEndDate(moment(e[1].$d).format(submitDateFormat).toString())
                }} />
              <Tooltip title="Search">
                <Button loading={searchLoading} onClick={searchBarchartSentimentalAnalysis} type="primary" >
                  <SearchOutlined />
                </Button>
              </Tooltip>
              
            </Space>
          </Col>
        </Row>
        }>
          {!isLoading ? <Space style={{width:'100%'}} size={10} direction="vertical">
          {/* <Space style={{width:'100%'}} direction="vertical" size={0}> */}
          <SentimentalBarChart 
              sugestionData={sugestionData}
              filterDataNumber={filterDataNumber}
              setFilterDataNumber={searchSugestionData}
              loading={sugestionLoading}
              barData={barChartData} />
              {barChartData.feedback_by_question !== undefined && <Row  gutter={[10, 0]}>
            <DetailedFeedbackForm 
              feedback_question={barChartData.feedback_by_question}
              answers={barChartData.answers}/>
            
            <PieChartFeedback feedback={barChartData.full_analysis} />
              </Row>}
          {/* </Space> */}
          </Space> : <div style={{
            alignItems:'center',
            alignContent:'center',
            textAlign:'center'
        }}>
          <DescriptionSpinLoading description={'Loading questions...'} size={30} />
        {/* <Spin indicator={antIcon} />
        <Typography color="grey">...</Typography> */}
      </div>}
          
        </Card>
      {/* </Col> */}
    </div>
  );
}

export default FeedbackForm;
