import { Modal, Form, Input, Radio, DatePicker, Button } from "antd";

export default function VisitorConfirmation({
  visible,
  setVisible,
  setConfirmation,
}) {
  // const {visible, setVisible, setConfirmation} = props;

  const confirmation = (value) => {
    setConfirmation(value);
    setVisible(false);
  };
  return (
    <>
      <Modal
        visible={visible}
        title="Found visitor"
        okText="Create"
        onCancel={() => {
          setVisible(false);
          setVisible(false);
          confirmation("canceled");
        }}
        footer={[
          <Button key="back" onClick={() => confirmation("replace")}>
            Replace
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => confirmation("keep")}
          >
            To maintain
          </Button>,
        ]}
      ></Modal>
    </>
  );
}
