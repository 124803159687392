import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Select, Typography } from "antd"



export function EditSelectedQuestion ({rigthAnswerChoise, questionFields, deleteItem})  {

    return (
        <>
            <Typography.Title level={5} style={{ margin: 0 }}>
                                Question:
                            </Typography.Title>
                            <Form.Item name="question_name">
                                <Input 
                                    defaultValue={questionFields.question_name}
                                    placeholder="Input the question description"/>
                            </Form.Item>
                            <Row gutter={[24, 24]}>
                                <Col span={17}>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                            Question type:
                                    </Typography.Title>
                                    <Form.Item name='question_type'>
                                        <Select
                                            // mode="tags"
                                            disabled
                                            style={{ width: '100%' }}
                                            placeholder="component"
                                            // onChange={handleChange}
                                            defaultValue="Radio"    
                                            options={[{
                                                        value: 'Radio',
                                                        label: 'Radio',
                                                    }]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <br/>
                                    <Button 
                                        style={{
                                            display:'inline-flex',
                                            alignItems:'center',
                                        }}
                                        type="primary" 
                                        icon={<PlusOutlined />} 
                                        size="large">
                                        Options
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    First option:
                                </Typography.Title>
                                    <Form.Item name='first_option'>
                                        <Input 
                                            defaultValue={questionFields.first_option}
                                            placeholder={questionFields.first_option} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    Second option:
                                </Typography.Title>
                                <Form.Item name='second_option'>
                                <Input 
                                    defaultValue={questionFields.second_option}
                                    placeholder={questionFields.second_option} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={12}>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                            Third option:
                                    </Typography.Title>
                                    <Form.Item name='third_option'>
                                        <Input 
                                            defaultValue={questionFields.third_option}
                                            placeholder={questionFields.third_option} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Typography.Title level={5} style={{ margin: 0 }}>
                                    Fourth option:
                                </Typography.Title>
                                <Form.Item name='fourth_option'>
                                    <Input 
                                        defaultValue={questionFields.fourth_option}
                                        placeholder={questionFields.fourth_option} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Right_Answer:
                            </Typography.Title>
                            <Form.Item name="right_answer">
                                    <Select
                                        style={{ width: '100%' }}
                                        // onChange={handleChange}
                                        defaultValue={questionFields.right_answer}    
                                        options={rigthAnswerChoise}
                                    />
                            </Form.Item>
                            <Col span={16} offset={13}>
                                <Row gutter={[8, 24]}>
                                    <Col span={13}>
                                        <Button
                                            style={{
                                                display:'inline-flex',
                                                alignItems:'center',
                                            }}
                                            danger
                                            onClick={() => deleteItem()}
                                            icon={<DeleteOutlined />}
                                        >Delete</Button>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item>
                                            <Button
                                                style={{
                                                    display:'inline-flex',
                                                    alignItems:'center',
                                                }}
                                                htmlType="submit"
                                                type="primary"
                                                icon={<SaveOutlined />}
                                                // onClick={saveQuestion}
                                                // loading={loadings[2]}
                                                // onClick={() => enterLoading(2)}
                                            >Save</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
        </>
    )
}

export function QuestionForm({rigthAnswerChoise}){
    return (
        <>
            <Typography.Title level={5} style={{ margin: 0 }}>
                Question:
            </Typography.Title>
            <Form.Item name="question_name">
                <Input placeholder="Input the question description" />
            </Form.Item>
            <Row gutter={[24, 24]}>
                <Col span={17}>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                            Question type:
                    </Typography.Title>
                    <Form.Item name='question_type'>
                        <Select
                            // mode="tags"
                            disabled
                            style={{ width: '100%' }}
                            placeholder="Tags Mode"
                            // onChange={handleChange}
                            defaultValue='Radio'    
                            options={[{
                                        value: 'Radio',
                                        label: 'Radio',
                                    }]}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <br/>
                    <Button 
                        style={{
                            display:'inline-flex',
                            alignItems:'center',
                        }}
                        type="primary" 
                        icon={<PlusOutlined />} size="large">
                        Options
                    </Button>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                    First option:
                </Typography.Title>
                    <Form.Item name='first_option'>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                    Second option:
                </Typography.Title>
                <Form.Item name='second_option'>
                <Input />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                            Third option:
                    </Typography.Title>
                    <Form.Item name='third_option'>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                    Fourth option:
                </Typography.Title>
                <Form.Item name='fourth_option'>
                    <Input />
                </Form.Item>
                </Col>
            </Row>
            <Typography.Title level={5} style={{ margin: 0 }}>
                Right_Answer:
            </Typography.Title>
            <Form.Item name="right_answer">
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Tags Mode"
                        // onChange={handleChange}
                        defaultValue='Radio'    
                        options={rigthAnswerChoise}
                    />
            </Form.Item>
            <Col span={6} offset={15}>
            <Form.Item>
                <Button
                    style={{
                        display:'inline-flex',
                        alignItems:'center',
                    }}
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                    // onClick={saveQuestion}
                    // loading={loadings[2]}
                    // onClick={() => enterLoading(2)}
                >
                    Save question
                </Button>
            </Form.Item>
            </Col>
        </>
    )
}