import { Alert } from "antd";


export function CustomMessageAlert({error, success, info, message }){
    return(
        <>
        {error && (
        <>
          <Alert closeIcon message="Error" description={message} type="error" showIcon />
          <br />
        </>
      )}
      {success && (
        <Alert
          closeIcon
          message="Success Tips"
          description={message}
          type="success"
          showIcon
        />
      )}
      <br />
      {info && (
        <>
          <Alert
            closeIcon
            // message="Informational Notes"
            description={message}
            type="info"
            // showIcon
          />
          <br />
        </>
      )}
        </>
    )
}