import { Avatar, Card, List, Col, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { itemUploadTabTitle } from "../../utils/constants";

export default function CategoryUpload() {
  const navigate = useNavigate();
  const redirect = (route) => {
    navigate(route);
  };
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
        <Card bordered={false} className="criclebox h-full">
          <Tabs defaultActiveKey="1" items={itemUploadTabTitle  }  />
          {/* <Row>
            <Card
              hoverable
              onClick={() => redirect("/updload_docments")}
              style={{
                width: 300,
                margin: "20px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <h1>Employees</h1>
            </Card>
            <Card
              hoverable
              onClick={() => redirect("/upload_videos")}
              style={{
                width: 300,
                margin: "20px",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <h1>Videos</h1>
            </Card>
          </Row> */}
        </Card>
      </Col>
    </>
  );
}
