import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const linkToPPTFile =
  "https://demotraining.atendimento.co.mz/api/docs/workatheight.pptx";

export default function PPTX() {
  const location = useLocation();
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (location.state != undefined) {
      setFile(location.state);
    }
  });
  const file_url = file.map((url) => url.powerpoint).toString();

  return (
    <>
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${file_url}`}
        width="100%"
        height="600px"
        frameBorder="0"
        title="slides"
      ></iframe>
    </>
  );
}
// Gumana
