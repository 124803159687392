import { LoadingOutlined, ReadFilled } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row, Space, Spin, Typography } from "antd";
import plusIcon from '../../components/assets/icons/plus_icon.png'
import { useState } from "react";
import FormCreator from "./components/FormCreation/card_creator";
import LastEditedQuiz from "./components/Quiz";
// import { DefaultSpinLoading } from "../../components/Spin/custom_spin";
// import './components/style/css/botton_style.css'


const { Paragraph, Text, Title } = Typography;
const antIcon = (size) => <LoadingOutlined style={{ fontSize: size }} spin />;

export default function FormsControl(){
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingQuiz, setIsLoadingQuiz] = useState(false);
    const [controlQuizHistory, setControlQuizHistory] = useState(false);
    const [quizError, setQuizError] = useState(false);
    const [quizs, setQuizs] = useState([1,2,3]);
    const [quizs1, setQuizs1] = useState([1,2,3, 4]);
    const [showModal, setShowModal] = useState(false);

    const createForm = () => {
        console.log("Card cliked")
        setShowModal(true)
    }

    const getQuizHistory = () => {
        setControlQuizHistory(true); 
        setQuizError(false);
        console.log("Get quiz history")
    }
    return (
        <>
            <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
            {/* <Paragraph underline title="Controle de formulario" editable/> */}
            <Divider orientation="left"><Text strong>Create Form {isLoading && <> <Spin indicator={antIcon(15)} /></>}</Text></Divider>
            {/* <Title strong>Create Form</Title> */}
            <Card 
                bordered={false} 
                className="criclebox h-full">
                <br/>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                <Card 
                    size="small"
                    onClick={() => createForm()}
                    style={{ 
                        justifyContent:"center",
                        alignItems:"center",
                        textAlign:"center",
                        height:150,
                        width: 250 }}>
                    <Image
                        style={{
                            marginTop:"10%",
                            height:100
                        }}
                        preview={false}
                        src={plusIcon}
                     />
                </Card>
                </Col>
                {quizs.map((quiz, index) => (
                    <Col style={{textAlign:"center"}} className="gutter-row" span={6}>
                        <Space direction="vertical" >
                            <Card 
                                size="small" 
                                style={{ 
                                    justifyContent:"center",
                                    alignItems:"center",
                                    textAlign:"center",
                                    height:150,
                                    width: 250 }}>
                                
                            </Card>
                            {/* <Title level={4}>Modulo {index}</Title>   */}
                            <h6 className="font-semibold m-0">
                            Modulo {index}
                            </h6>
                        </Space>        
                </Col>
                ))}
                
                </Row>
            </Card>

            <br/>
            <br/>
            <Divider orientation="left"><Text strong>My Forms</Text></Divider>
            {/* <Title level={4} strong>MY FORMS</Title> */}
            <Card 
                style={{
                    textAlign:'center'
                }}
                bordered={false} 
                className="criclebox h-full">
                <br/>
                <LastEditedQuiz 
                           setControlQuizData={setControlQuizHistory}
                           controlQuizData={controlQuizHistory} />
                </Card>
                {showModal && <FormCreator 
                setShowModal={setShowModal}
                showModal={showModal}
                loading={setIsLoading}
                setControlQuizHistory={setControlQuizHistory}
            
            />}
            </Col>
            
        </>
    )
}