import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Progress,
  Avatar,
  Button,
  Radio,
  Table,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  ColorPicker,
  Tabs,
  Tag,
  Space,
  List,
  Skeleton,
  Divider,
  AutoComplete
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ava1 from "../components/assets/images/engineering.png";
import pencil from "../components/assets/images/pencil.svg";
import { baseurl } from "../lib/settings";
import { CollectionsPage2 } from "../components/modal/scheduled/create_training";
import Footer from "../components/footer/footer";
import { DescriptionSpinLoading } from "../components/Spin/custom_spin";
import InfiniteScroll from 'react-infinite-scroll-component';
import { DeleteFilled, LeftSquareFilled, UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenericErrorMessage, GenericSuccessMessage } from "../components/Alerts/genericAlert";
import { AuthContext } from "../components/Context/auth_context";
import { VerifyAllowedUserCompany } from "../components/UserControl/user_control";
import "./add_employee_to_training.css"
import EmailModal from "../components/modal/forms/add_email";
import { MANAGER_PERMISSION } from "../utils/constants";

const { Text, Title } = Typography;
const project = [
  {
    title: "Color",
    dataIndex: "color",
  },
  {
    title: "TRAINING",
    dataIndex: "name",
    width: "32%",
  },
  {
    title: "DATE",
    dataIndex: "date",
  },
  {
    title: "TIME",
    dataIndex: "time",
  },
  {
    title: "CAPACITY",
    dataIndex: "completion",
  },
];

const formatTime = (time) => {
  const formattedTime = moment(time, "HH:mm:ss").format("HH[h]mm");
  return formattedTime;
};

const dataproject = [
  {
    key: "1",
    color: (
      <>
         <ColorPicker disabledAlpha />
      </>
    ),
    name: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    employees: (
      <>
        <div className="semibold">$14,000</div>
      </>
    ),
    time: (
      <>
        <div className="text-sm">working</div>
      </>
    ),
    completion: (
      <>
        <div className="ant-progress-project">
          <Progress percent={30} size="small" />
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
  },
];

export default function ScheduleAddEmployee() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isBottonloading, setIsBottonloading] = useState(false);
  const [isSearchloading, setIsSearchloading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [control, setControl] = useState(false);
  const [training, setTraining] = useState();
  const { Option } = Select;
  const location = useLocation();
  const [schedule, setSchedule] = useState(undefined);
  const [eventFilter, setEventFilter] = useState();
  const [showEmployeeListt, setShowEmployeeList] = useState(false);
  const [treaningDetailDescription, setTreaningDetailDescription] = useState('Add Employee');
  const [requestType, setRequestType] = useState('all')
  const [filterDayRange, setFilterDayRange] = useState('all')
  const [autoCompliteOption, setAutoCompliteOption] = useState([])
  const [verifiedDomine, setVerifiedDomine] = useState(VerifyAllowedUserCompany());
  const [selectedUserEmail, setselectedUserEmail] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [emailUserIdOrDescription, setEmailUserIdOrDescription] = useState('');
  const [userExist, setUserExist] = useState(false);
  const [isUserID, setIsUserID] = useState(true);
  const [havePermission, setHavePermission] = useState(false);

  useEffect(() => {
    // setIsloading(true);
    var data = {request_type:'all'}
    baseurl.get("v1/get_training/", {params: {range_of_day:'all', request_type:'all'}}).then((resp) => {
      setEvents(resp.data);
      setIsloading(false);
    }).catch((err) => {
      setIsloading(false);
    });

    baseurl.get('v1/employee_history/auto_complite/').then((resp) => {
      creatAutoCompliteHistory(resp.data)
    }).catch((err) => {
      setIsloading(false);
    });

    
    if (location.state != undefined) {
      // setSchedule(location.state.item);
      if (schedule == undefined){
        get_treaning_information(location.state.item.id)
      }
    }

   const creatAutoCompliteHistory = (employeers) => {
    var filter = employeers.map((item) =>  { return {
      label:item.description,
      value: item.description}});
    setAutoCompliteOption(filter)
    
   }
   getUserPermission();
  //  const creatAutoCompliteHistory = (employeers) => employeers.map((item) => setAutoCompliteOption([...autoCompliteOption, {value: item.description}]));

    
    setControl(false);
  }, [control]);


  const get_treaning_information = (treainig_id) => {
    baseurl.get(`v1/get_training_by_id/${treainig_id}`).then((resp) => {
      console.log("Treining ", resp);
      setSchedule(resp.data);
      setIsloading(false);
    }).catch((err) => {
      setIsloading(false);
      console.log("error get treaining details")
    })
  }

  const get_employee_by_character = (value) => {
    
    if (value.trim().length !== 0){
      console.log("Strt filter ", value.trim().length, value)
      setIsSearchloading(true)
      baseurl.post(`v1/employer/filter/`, {'filter_param':value}).then((response) => {
        setUsers(response.data);
        setIsSearchloading(false);
      }).catch((err) => {
        setIsSearchloading(false);
      });
    }
  }

  const fordAutos = events.filter((trainings) => {
    if (schedule){
      trainings.formation.description.includes(schedule.formation.description)
    }
  }
  );
  const employee = fordAutos.map((item) => item.employee);


  function check_if_user_have_training(user) {
    const format = events.filter((auto) =>
      auto.formation.description.includes(schedule.formation.description)
    );
    const usersss = format.map((item) => item.employee);

    if (usersss.length != 0) {
      if (usersss[0].length != 0) {
        if (schedule.employee.filter((e2) => e2.description === user).length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        console.log("Sem Usuario para filtar");
      }
    } else {
      console.log("Ainda nao da para filtrar");
      return false;
    }
  }
  const list_of_trainings = events.map((item) => ({
    key: item.id,
    color: (
      <>
         {/* <ColorPicker 
          disabled 
          color={'#FF5733'}
          defaultValue={'#FF5733'} /> */}
          <div style={{
            height:'5px',
            backgroundColor:`${item.formation.color}`
          }}>
          </div>
      </>
    ),
    name: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>{item.formation.description}</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    date: (
      <>
        <div className="semibold">
          {moment(item.start_date).format("DD/MM")} -{" "}
          {moment(item.end_date).format("DD/MM")}
        </div>
      </>
    ),
    time: (
      <>
       <div className="semibold">{formatTime(item.start_time)} - {formatTime(item.end_time)}</div>

        {/* {item.is_open ? (
          <div className="text-sm">Open</div>
        ) : (
          <div className="text-sm">Close</div>
        )} */}
      </>
    ),
    completion: (
      <>
        <div className="ant-progress-project">
          <Progress
            percent={((item.employee.length / item.capacity) * 100).toFixed(0)}
            size="small"
          />
          <span>
            <div className="text-sm">
              {item.employee.length}/{item.capacity}
            </div>
          </span>
        </div>
      </>
    ),
  }));

  async function sendData(value, status) {
    setIsBottonloading(true)
    const formation_employee_number = schedule.employee.length
    var total_free_space = Number(schedule.capacity) - Number(formation_employee_number)
    if (Number(schedule.capacity) === Number(formation_employee_number)) {
      // console.log("Sala Cheia");
      setIsBottonloading(false)
      return Modal.info({
        title: schedule.formation.description,
        content: `The Room has reached the maximum number of participants.`,
      });
    } else if (Number(verifiedDomine ? selectedUsers.length : 1)  > total_free_space) {
      setIsBottonloading(false)
      return Modal.info({
        title: schedule.formation.description,
        content: `You can only add more ${
          total_free_space
        } members of your team.`,
      });
    } else {
      if (status === "Modal") {
        const data = {
          formation: training,
          capacity: parseInt(value.capacity),
          start_date: moment(value.date[0]._d).format("YYYY-MM-DD"),
          end_date: moment(value.date[1]._d).format("YYYY-MM-DD"),
        };
        const send = await baseurl.post("v1/booking/training/", data);
        setControl(true);
        setIsBottonloading(false);
      } else if (status == "AdddEmployeer") {
        setIsBottonloading(true)
        const data = {
          allowed_domine:verifiedDomine,
          employee: selectedUsers,
          formation: schedule.id,
        };
        const send = await baseurl.post("v1/training/add_employee/", data).then((resp) => {
          get_treaning_information(schedule.id);
          setControl(true);
          setSelectedUsers([]);
          form.resetFields();
          setIsBottonloading(false)
          setUsers([])
          GenericSuccessMessage('Participants added successfully');
        }).catch((err) => {
          console.log("Response error", err)
          setIsBottonloading(false)
        });
        
      }
    }
  }

  function getUserPermission() {
    var usr1 = localStorage.getItem('user')
    var usr2 = JSON.parse(usr1)
    if (usr1 != undefined){
      usr2.list_permissions.map((permission, index)=> {
        if (permission.description == MANAGER_PERMISSION){
          setHavePermission(true);
        }
      });
    }

    return usr2.web_permission
    
  }

  const CallModal = () => {
    if(havePermission){
      setIsModal(true);
    }else{
      Modal.error({
        title: 'Action not permitted',
        content: 'Unfortunately you do not have permission to perform this operation.',
      });
    }
    
  };

  const getUserDetails = async (user_id_or_description, is_id) => {
    setEmployeeLoading(true);
    try {
      const response = await baseurl.get(`v1/check_employee_email/${404}`, {params:{is_id:is_id, user_id_or_description:user_id_or_description}});
      setEmployeeLoading(false);
      return response.data;
    } catch (error) {
      setEmployeeLoading(false);
      console.error("Error:", error);
      return null;
    }
  };

  const handleChange = async (value) => {
    if(value instanceof Array){
      const detailsPromises = value.map(async (userId) => {
        const userDetails = await getUserDetails(userId, true);
        console.log("DET: ", userId, userDetails);
  
        if (userDetails && userDetails['has_email'] === false) {
          setIsEmailModalVisible(true);
          console.log('SELECINOUN ESTE: ', userId)
          setEmailUserIdOrDescription(userId)
  
        }
        return { userId, userDetails };
      });
      const userDetails = await Promise.all(detailsPromises);
      setselectedUserEmail(userDetails);
      setSelectedUsers(value);
    }else{
      console.log("Acept value", value)
      // const userDetails = await Promise.all(detailsPromises);
      // setselectedUserEmail(userDetails);
      setSelectedUsers(value);
    }
    
  
   
  };

  const onCustomSearchEmployee = async (value) => {
    console.log(`Selected: ${value}`, typeof(value));
    const detailsPromises = value.map(async (userId) => {
      setEmailUserIdOrDescription(userId)
      const userDetails = await getUserDetails(userId, false);
      console.log("DET: ", userId, userDetails);

      if (userDetails && userDetails['has_email'] === false) {
        setIsEmailModalVisible(true);
        console.log('SELECINOUN ESTE: ', emailUserIdOrDescription, userDetails)
        setIsUserID(false)
        setUserExist(userDetails['user_exist'])

      }
      return { userId, userDetails };
    });
    setSelectedUsers(value);
    
  };


  // function handleChange(value) {
  //   console.log("add employee ",value)
  //   setSelectedUsers(value);
  // }


  const onChange = (e) => {
    setIsloading(true);
    setRequestType(e.target.value)
    baseurl.get("v1/get_training/", {params: {
      range_of_day:filterDayRange,
      request_type:e.target.value}}).then((resp) => {
      setEvents(resp.data);
      setIsloading(false);
    }).catch((err) => {
      setIsloading(false);
      console.log("error get treaining list")
    });
  };
  

  const searchTreaningByRangofDay = (value) => {
    setIsloading(true);
    setFilterDayRange(value)
    baseurl.get("v1/get_training/", {params: {
      range_of_day:value,
      request_type:requestType}}).then((resp) => {
      setEvents(resp.data);
      setIsloading(false);
    }).catch((err) => {
      setIsloading(false);
      console.log("error get treaining list")
    });
  }

  const getSelectedCourse = (course) => {
    setSchedule(course);
    setTreaningDetailDescription('Add Employee')
    setShowEmployeeList(false);
    setUsers([]);
  }

  const removeEmployeeFromTreaning = (value) => {
    const data = {
      emlpyees:[value.id],
      fromation_id:schedule.id,
      request_type:'remove_employee'
    }
    baseurl.post('v1/training/manage_employee/', data).then((resp) => {
      get_treaning_information(schedule.id);
      setControl(true);
      
    }).catch((err) => {
      GenericErrorMessage(err.response.data.message)
      console.log("error on remove employee", err)
    });
  }

  function controlExpaireTraining () {
    return schedule ? moment(schedule.end_date) > moment.now() ? <>Active</> : <>Due on: {moment(schedule.end_date).format("DD.MM.YY")}</> : <>--/--/----</>
  }

  return (
    <>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={16}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          Scheduled Trainings
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button onClick={CallModal} type="primary">
                          Add New Schedule
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title=""
                  extra={
                      <Space size={5}>
                        {/* <Select
                          style={{ width: "120px" }}
                          size="large"
                          onChange={searchTreaningByRangofDay}
                          defaultValue={'30'}
                          
                          // tokenSeparators={[","]}
                          // options={filterOption}
                        >
                         <Option value='all'>All</Option>
                          <Option value='30'>30 dias</Option>
                          <Option value='60'>60 dias</Option>
                        </Select> */}
                      <Radio.Group onChange={onChange} defaultValue="all">
                        <Radio.Button value="all">All</Radio.Button>
                        <Radio.Button value="valid">Valid</Radio.Button>
                        <Radio.Button value="expired">Expired</Radio.Button>
                      </Radio.Group>
                    </Space>
                  }
                >
                  <div className="table-responsive" style={{ maxHeight: '550px', overflowY: 'auto' }}>
                <Table
                  loading={isloading}
                  columns={project}
                  dataSource={list_of_trainings}
                  pagination={false}
                  className="ant-border-space"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => { getSelectedCourse(events[rowIndex]) },
                    };
                  }}
                />
              </div>
                  {/* <div className="table-responsive">
                    <Table
                      loading={isloading}
                      columns={project}
                      dataSource={list_of_trainings}
                      pagination={false}
                      className="ant-border-space"
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {getSelectedCourse(events[rowIndex])},
                        };
                      }}
                    />
                  </div> */}
                  <div className="uploadfile pb-15 shadow-none"></div>
                </Card>
              </Card>
            </Col>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card
              bordered={false}
              className="header-solid h-full ant-invoice-card"
              title={[
                <Space size={5}>
                    <p>{showEmployeeListt ? <AiOutlineArrowLeft size={25} onClick={() => {setShowEmployeeList(!showEmployeeListt); setTreaningDetailDescription('Add Employee')}}/> : <></>}</p>
                    <p>{treaningDetailDescription}</p>
                  </Space>]}
              // extra={[
              //   <Button type="primary">
              //     <span>Expand</span>
              //   </Button>,
              // ]}
            >
              {showEmployeeListt ? <>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 400,
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                    borderRadius: '10px'
                  }}
                >
                  <InfiniteScroll
                    dataLength={schedule.employee.length}
                    // next={loadMoreData}
                    // hasMore={schedule.employee.length < 50}
                    // loader={
                    //   <Skeleton
                    //     avatar
                    //     paragraph={{
                    //       rows: 1,
                    //     }}
                    //     active
                    //   />
                    // }
                    endMessage={<Divider plain>End</Divider>}
                    scrollableTarget="scrollableDiv"
                  >
                <List
                  className="demo-loadmore-list"
                  loading={isloading}
                  itemLayout="horizontal"
                  // loadMore={loadMore}
                  dataSource={schedule.employee}
                  renderItem={(item) => (
                    <List.Item
                      actions={[<a onClick={(e) => removeEmployeeFromTreaning(item)} key="list-loadmore-more"><DeleteFilled /></a>]}
                    >
                      <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                          avatar={<Avatar icon={<UserOutlined />} src={''} />}
                          title={<>{item.description}</>}
                          description={item.code}
                        />
                        {/* <div>content</div> */}
                      </Skeleton>
                    </List.Item>
                  )}
                />
                </InfiniteScroll> </div>
              </>  : <Card className="header-solid h-full ant-invoice-card">
                <br />
                <Form form={form} layout="vertical">
                  <Form.Item name="training">
                    <Space style={{
                      width:'100%'
                    }} size={5} direction="vertical">
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <>Training</>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Tag 
                          color={schedule ? moment(schedule.end_date) > moment.now() ? '#00b300' : '#FF0000' : '#808080'}>
                          <Text style={{
                            fontSize:'100%',
                            color:'white',
                          }} strong>{controlExpaireTraining()}</Text>
                        </Tag>
                      </Col>
                    </Row>
                    <Input
                      value={schedule ? schedule.formation.description : <></>}
                      placeholder={schedule ? schedule.formation.description : <></>}
                      disabled
                    />
                    </Space>
                  </Form.Item>
                  <Form.Item label="Team Members" name="employeer">
                    {verifiedDomine ? <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      filterOption={false}
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      onSearch={(e) => get_employee_by_character(e)}
                      loading={isSearchloading}
                      
                      // tokenSeparators={[","]}
                      // options={filterOption}
                    >
                      {users.map((item) => {
                        return (
                          <Option value={item.id} disabled={check_if_user_have_training(item.description)}>
                            {item.description}
                          </Option>
                        );
                      })}
                    </Select> : <Select
          mode="tags"
          size={'middle'}
          placeholder="Insert the employee’s name here"
          // defaultValue={['a10', 'c12']}
          onDeselect={e => console.log("Removendo employee", e)}
          onChange={onCustomSearchEmployee}
          style={{
            width: '100%',
          }}
          options={autoCompliteOption}
        /> 
        // <AutoComplete
        //               options={autoCompliteOption}
        //               // style={{ width: 200 }}
        //               onChange={handleChange}
        //               onSelect={handleChange}
        //               // onSearch={(text) => setAutoCompliteOption([...autoCompliteOption, {value: text}])}
        //               placeholder="Insert the employee’s name here"
        //             />
                    }
                  </Form.Item>
                  <Form>
                  <Space size={5}>
                    <Button
                        type="primary"
                        style={{
                          backgroundColor:'darkcyan'
                        }}
                        disabled={isBottonloading ? true : schedule !== undefined ? schedule.employee.length > 0 ? false : true : true}
                        onClick={(e) => {setShowEmployeeList(!showEmployeeListt); setTreaningDetailDescription(`Manage list of employees for ${schedule.formation.description}`)}}
                      >
                        Participants
                      </Button>
                      <Button
                        type="primary"
                        loading={isBottonloading}
                        onClick={(e) => sendData(e, "AdddEmployeer")}
                      >
                        Add
                      </Button>
                  </Space>
                  </Form>
                </Form>
              </Card>}
            </Card>
          </Col>
          {isModal && (
            <CollectionsPage2
              onChange={(item) => {
                sendData(item, "Modal");
                setIsModal(item);
              }}
              onCancel={(item) => {
                setIsModal(item);
              }}
              date={(item) => {}}
              trainingID={(item) => {
                setTraining(item);
              }}
            />
          )}
        </Row>
      <Footer />
      <EmailModal
      visible={isEmailModalVisible}
      onClose={() => setIsEmailModalVisible(false)}
      emailUserIdOrDescription={emailUserIdOrDescription}
      user_exist={userExist}
      is_user_id={isUserID}
    />
    </>
  );
}
