import React, { useState, useEffect } from "react";
import Navbar from "./nav";
import Sidebar from "./sidebar";
import PainelPrincipal from "./principal";
import "./stream.css";
// import Footer from "./footer";
import { baseurl } from "../../lib/settings";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Spin, theme } from "antd";
import Quiz from "./quiz/Quiz";
import SecondSidBar from "./sidebar/second_side_bar";
import VideoStreemPage from "./streemPage/video_streem";

const { Header, Content, Footer, Sider } = Layout;

function Stream() {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [streamingList, setStreamingList] = useState([]);
  const [linkClicked, setLinkClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formControl, setFormControl] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [moduleCode, setModuleCode] = useState()
  const [selectedQuizData, setSelectedQuizData] = useState([])
  const[isupdate, setIsUpdate] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const antIcon2 = (
    <LoadingOutlined
      style={{
        fontSize: 50,
      }}
      spin
    />
  );

  useEffect(() => {
    setIsLoading(true);
    baseurl.get("v1/streaming/module_videos/").then((resp) => {
      setCourseModules(resp.data);
    });
    baseurl.get("v1/streaming/training/").then((resp) => {
      setCourses(resp.data);
      setIsLoading(false);
    });
  }, []);

  function makeUpdate(update){
    if (update === true){
      baseurl.get("v1/streaming/module_videos/").then((resp) => {
        setCourseModules(resp.data);
      });
    }
    
  }

  const handleClick = () => {
    setLinkClicked(true);
  };

  const handleCourseSelection = (course, requestType) => {

    setIsLoadingContent(true);
    if (requestType === 'quiz'){
      
      setSelectedQuizData(course)
      setFormControl(false);
      setIsLoadingContent(false);
    }else if(requestType === 'video'){
      var data = {
        code: course.code,
      };
      baseurl.post("v1/streaming/module_videos/", data).then((resp) => {
        setSelectedCourse(resp.data);
        setFormControl(true);
        setIsLoadingContent(false);
      }).catch(err =>{
        console.log("Error Geting video details", err)
      });
    }
    handleClick();
  };

  return (
      <Layout   style={{ height: "100vh" }}>
        <Navbar />
        <Layout >
          <SecondSidBar 
                  courses={courseModules}
                  handleCourseSelection={handleCourseSelection}
                  formControl={setFormControl}
                  setModuleCode={setModuleCode}
          />
        <Layout>
          <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, minHeight: 340, background: colorBgContainer }}>
            {isLoadingContent ? (
              <div>
                <div className="centered">
                  <Spin indicator={antIcon2} />;
                </div>
              </div>
            ) : (
              formControl ? <VideoStreemPage selectedCourse={selectedCourse} moduleCode={moduleCode} setUpdate={makeUpdate}/> :
              <Quiz update={makeUpdate} selectedQuizData={selectedQuizData}  />
            )}
              </div>
          </Content>
          {/* <Footer style={{
            bottom:'0',
            padding:'12px',
            textAlign: 'center' }}>Copyright &copy; 2023 Speranza Lda. All rights reserved.</Footer> */}
          </Layout>
          </Layout>
      </Layout>
              
         
      
  );
}

export default Stream;