import { useState } from "react";
import "../../static/css/upload_data_file.css";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import csvimage from "../../assets/images/csv-96.png";

function UploadDataFile({ setFileData, loading }) {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  return (
    <div style={{
      width:'100%',
      marginTop:10
      // alignContent:'center',
      // alignItems:'center',
      // textAlign:''
    }}>
      <div className="form_leyout">
        <form onClick={() => document.querySelector(".input-field").click()}>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="input-field"
            hidden
            onChange={({ target: { files } }) => {
              files[0] && setFileName(files[0].name);
              if (files) {
                setImage(csvimage);
                setFileData(files[0]);
              }
            }}
          />

          {image ? (
            <img src={image} width={150} height={150} alt={fileName} />
          ) : (
            <>
              <MdCloudUpload color="#1475cf" size={60} />
              <p>Browse Files to upload</p>
            </>
          )}
        </form>
      </div>

      <div style={{
          backgroundColor:`${loading ? '#cccccc' : "#e9f0ff"}`
        }} className="uploaded-row">
        <AiFillFileImage color={loading ? '#808080' : "#1475cf"} />
        <span  className="upload-content">
          {fileName} -
          <MdDelete
            onClick={() => {
              if(!loading){
                setFileName("No selected File");
                setImage(null);
                setFileData(null)
              }
              
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default UploadDataFile;
