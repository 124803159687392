import { Divider } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function CourseListItem({ course, onCourseSelect }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(
      "/courses/formation/induction",
      { state: course }
    );
  }

  return (
    <div className="course-list-item" onClick={handleClick}>
      <h3>{course.title}</h3>
      <p>{course.description}</p>
      <Divider />
    </div>
  );
}

export default CourseListItem;
