import React, { useState, useEffect } from "react";
import { Avatar, Card, List, Spin, Col, Row } from "antd";
import info from "../assets/icons/icons8-info.gif";
import intructor from "../assets/icons/svg/constr.svg";
import calendar from "../assets/icons/svg/rounded-calendar.svg";
import team from "../assets/icons/svg/team-leader.svg";
import { baseurl } from "../../lib/settings";
import { LoadingOutlined } from "@ant-design/icons";
import "./twigg-info.css";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
)

export default function TwinggInfo() {
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tweggInfo, setTwiggInfo] = useState({});
  useEffect(getInit, []);

  function getInit() {
    setIsLoading(true);
    baseurl
      .get("v1/twigg_information/")
      .then((resp) => {
        setTwiggInfo(resp.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  }

  return (
    <>
     
        <Row gutter={16} style={{}}> 
          {(isloading && (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Spin indicator={antIcon} />
            </div>
          )) ||
            (error && (
              <>
                {" "}
                <div>
                  Something went wrong!&nbsp;<a onClick={getInit}>try again.</a>
                </div>
              </>
            )) || (
              <>
                <Col  span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center",textAlign: "center", height: "60px", }} > 
                  <Card bordered={false} style={{height: "100%",  display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img className="book-svg" src={intructor} /> &nbsp; &nbsp;
                    <span style={{ fontFamily: 'Montserrat'}}>Trainings: {tweggInfo["trainings"]}</span>
                    
                  </Card>
                </Col>
                <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center",textAlign: "center", height: "60px",}}>
                  <Card bordered={false} style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img className="calendar-svg" src={calendar} /> &nbsp;
                    &nbsp; Scheduled: {tweggInfo["scheduled"]}
                  </Card>
                </Col>
                <Col span={8} style={{display: "flex", alignItems: "center", justifyContent: "center",textAlign: "center", height: "60px",}}>
                  <Card bordered={false} style={{height: "100%",  display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img className="team-svg" src={team} /> &nbsp; &nbsp; Supervisors:{" "}
                    {/* {tweggInfo["teams"]} */}2/9
                  </Card>
                </Col>
              </>
            )}
        </Row>
    </>
  );
}
