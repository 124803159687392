import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Radio, DatePicker, TimePicker, Space, Col, Row, Select, Typography } from "antd";
import { ADMIN_PERMISSION, MANAGER_PERMISSION } from "../../utils/constants";
import { CustomMessageAlert } from "../Alerts/custom";
import { baseurl } from "../../lib/settings";
import { GenericErrorMessage, GenericSuccessMessage } from "../Alerts/genericAlert";

const { Option } = Select;
const { Title } = Typography;
export default function UserPermissionForm(props){
    const { visible, setVisible, user, calendarPermission, platformPermission, setCalendarPermission, setPlatformPermission, isLoading, setIsLoading, func } = props;
    const [form] = Form.useForm();
    console.log("Calendar permission ", calendarPermission)

    function updateUserPermission() {
      const data = {
        platform_permission:platformPermission,
        calendar_permission:calendarPermission,
        user_id:user.id
      }
      baseurl.post("v1/manager/update_user_permission/", data).then((resp) => {
        setIsLoading(false);
        form.resetFields();
        setCalendarPermission("");
        setPlatformPermission("");
        GenericSuccessMessage("User permission updated successfully.");
        setVisible(false);
        func();
      })
      .catch((e) => {
        console.log("ERROR ON UPDATE PERMISSION ", e)
        setIsLoading(false);
        GenericErrorMessage("We were unable to fulfill your request, please try again.");
      });
    }
   
    return (
        <Modal
      visible={visible}
      title="Update User Permission"
      okText="Update"
      onCancel={() => {
        setVisible(false);
        setCalendarPermission("");
        setPlatformPermission("");
        form.resetFields();
      }}
      onOk={updateUserPermission}
      confirmLoading={isLoading}
    >
      {!isLoading && (
        <Form 
          form={form} 
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="vertical">
        {/* <Form.Item label="Select Training" name="training" >
          <SearchSelectField
            formations={(value) => {
              setFormation(value);
              training(value);
            }}
          />
        </Form.Item> */}
        <Title level={5}>{user != undefined && user.full_name}</Title>
        <Space style={{width:'100%'}} size={5} direction="vertical">
                    <Typography>Platform permission</Typography>
                    <Select
                      // placeholder="Select the division"
                      size="large"
                      onChange={(e)=>  setPlatformPermission(e)}
                      defaultValue={platformPermission}
                      value={platformPermission}
                      style={{ width: '100%'}}
                      disabled={isLoading}
                    >
                      <Option key={1} value='supervisor'>
                        Supervisor
                      </Option>
                      <Option key={2} value={'admin'}>
                          Administrator
                      </Option>
                    </Select> 

                    <Typography>Calendar permission</Typography>
                    <Select
                      // placeholder="Select the division"
                      size="large"
                      onChange={(e)=>  setCalendarPermission(e)}
                      defaultValue={calendarPermission}
                      value={calendarPermission}
                      style={{ width: '100%'}}
                    >
                      <Option key={1} value='vew'>
                        Vew
                      </Option>
                      <Option key={2} value={MANAGER_PERMISSION}>
                          Vew and edit
                      </Option>
                    </Select> 

                </Space>
        
      </Form>
      )}
    </Modal>
    )
}