import React, { useContext } from "react";
import { AuthContext } from "../Context/auth_context";
import { ALLOWED_DOMINE } from "../../utils/constants";


export function VerifyAllowedUserCompany(){
    const {user} = useContext(AuthContext);
    var usr1 = localStorage.getItem('user')
    var usr2 = JSON.parse(usr1)
    var domine = usr2.email.split('@')[1]
    if (domine.substring(0, ALLOWED_DOMINE.length) === ALLOWED_DOMINE){ return true } else return false  // simple way to validate company
} 
