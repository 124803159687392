import React, { Fragment, useEffect, useState } from 'react';
import {
   Select,
    Row,
    Col,
    Card,
    Checkbox,
    Button,
    Typography,
    Spin,
    Space
} from 'antd';
import "./lna.css";
import { FilterFilled, FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import { baseurl } from '../../lib/settings';
import { GenericErrorMessage } from '../../components/Alerts/genericAlert';

const { Option } = Select;
const antIcon = (size) => <LoadingOutlined style={{ fontSize: size }} spin />;
const thStyle = {
  height: '200px',  
  maxHeight: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
};

function LNAMain() {
  const [receivedData, setReceivedData] = useState([]);
  const [listDivision, setListDivision] = useState([]);
  const [listDepartment, setListdepartment] = useState([]);
  const [selectedDirecao, setSelectedDirecao] = useState(null);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [selectedCursos, setSelectedCursos] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [uniqueRoleTitles, setUniqueRoleTitles] = useState([]);
  const [selectedDepartamentoRoleTitles, setSelectedDepartamentoRoleTitles] = useState([]);
  const [selectedDepartamentos, setSelectedDepartamentos] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [selectedCoursesByRole, setSelectedCoursesByRole] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedCursosByColumn, setSelectedCursosByColumn] = useState({});
  const [divisionLoading, setDivisionLoading] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [coursesLNAList, setCoursesLNAList] = useState([])
  const [courseIsLoading, setCourseIsLoading] = useState(false);
  const [roleTitleLoading, setRoleTitleLoading] = useState(false);
  const [controlData, setControlData] = useState([])

  useEffect(()=> {
      setDivisionLoading(true)
      baseurl.get("v1/manage_divisions/").then((resp) => {
      setListDivision(resp.data)
      setDivisionLoading(false)
    }).catch((error) => {
      console.log("ALGO CORREU MAL")
      setDivisionLoading(false)
    })

    baseurl.get("v1/list_courses/").then((resp) => {
      setCourseIsLoading(true)
      setCoursesLNAList(resp.data)
      setCourseIsLoading(false)
    }).catch((error) => {
      console.log("ALGO CORREU MAL")
      setCourseIsLoading(false)
    })
  }, [])


  const handlePostData = () => {
    
    const dataToPost = {
      division: selectedDirecao,
      departament: selectedDepartamento,
      role_title: selectedCargo,
      courses: selectedCursos,
    };
    console.log('POST DATA: ', dataToPost)
  };

  const handleDirecaoChange = (value) => {
    setSelectedDirecao(value);
    setSelectedDepartamento(null);
    setSelectedCargo(null);
    setSelectedCursos([]);
    setSelectedCargos([]);
    
    setDivisionLoading(true)
    baseurl.post("v1/manage_divisions/", {division:value}).then((resp) => {
      setListdepartment(resp.data)
      console.log(resp.data)
      setDivisionLoading(false)
    }).catch((error) => {
      console.log("ALGO CORREU MAL")
      setDivisionLoading(false)

    })
  };

  const handleDepartamentoChange = (value) => {
    setSelectedDepartamento(value);
    setSelectedCargo(null);
    setDivisionLoading(false)
    setDepartmentLoading(true)
    setRoleTitleLoading(true)
    baseurl.post("v1/manage_departments/", {division:selectedDirecao, department:value}).then((resp) => {
      setReceivedData(resp.data)
      // console.log(resp.data)
      setDivisionLoading(false)
      setDepartmentLoading(false)
      setRoleTitleLoading(false)
    }).catch((error) => {
      console.log("ALGO CORREU MAL", error)
      setDepartmentLoading(false)
      setRoleTitleLoading(false)
    })

  };
  


  const handleCursoChange = (curso, checked, role_title, index) => {
    console.log("C: ", curso, "CH: ", checked, "RL: ", role_title, "index: ", index)
    setSelectedCursosByColumn(prevState => ({
      ...prevState,
      [curso]: checked ? curso : null,
    }));
    if (checked) {
      setSelectedCursos((prevSelectedCursos) => [...prevSelectedCursos, curso]);
    } else {
      setSelectedCursos((prevSelectedCursos) =>
        prevSelectedCursos.filter((c) => c !== curso)
      );
    }
  };

  const generateDataDivision = () => {
    const data = selectedDepartamentos.map((departamento) => ({
      key: departamento,
      division: selectedDirecao,
      department: [departamento],
      roleTitles: selectedDepartamentoRoleTitles,
      courses: selectedCursos,
    }));
    setDataDivision(data)
    console.log("Final data Division", data)
  }

  function get_fromation_details (listed_formation, curent_fromation) {
    var filter_data = curent_fromation.filter((course) => course.id === listed_formation['id'])
    // console.log("Formation Filter Details : ", filter_data[0])
    if (filter_data[0] !== undefined){
      // console.log("Returning ",filter_data[0].required_status )
      return filter_data[0].required_status 
    }
    return false
  }
  const submit_assign_course = (curent_fromation, check, selected_course) => {
    // var filter_data = curent_fromation.filter((course) => course.id === selected_course['id'])
    console.log("Send final data with this params ",curent_fromation, selected_course, check)
    console.log("ADITIONAL: ", selected_course)
    var data = {
      division: selectedDirecao,
      department: selectedDepartamento,
      immediate_manager_id:curent_fromation.immediate_manager,
      check_status: !check,
      selected_course: selected_course['id'],
      role_title_id: curent_fromation.role_title_id
    }

    baseurl.post('v1/verified_trainings/', data).then((resp) => {
      handleDepartamentoChange(selectedDepartamento)
    }).catch((error) => {
      console.log("ALGO CORREU MAL", error)
      GenericErrorMessage(error.response.data.message)
    })
  }


  return (
    <div style={{ padding: '20px' }}>
      {/* <h1 style={{ fontSize: "20px" }}>Select the courses that will be taught in each role title</h1> */}
    <Card 
      title={<h1 style={{ fontSize: "20px" }}>Select the courses that will be delivered for each role title</h1>}
      className="card">
      <Row gutter={[24, 16]}>
          <Col>
          <Space
          direction='horizontal' 

        >
          <Select
            placeholder="Select the division"
            onChange={(e)=>  handleDirecaoChange(e)}
            value={selectedDirecao}
            style={{ width: '100%'}}
            suffixIcon={<FilterOutlined />}
            disabled={divisionLoading}
            loading={divisionLoading}
          >
            {listDivision.map((division, index) => (
              <Option key={index} value={division.id}>
                {division.description}
              </Option>
            ))}
          </Select> 
        
          <Select
            placeholder="All departments"
            onChange={(e) => handleDepartamentoChange(e)}
            value={selectedDepartamento}
            style={{ width: '100%' }}
            disabled={!selectedDirecao}
            suffixIcon={<FilterOutlined />}
          >
            {listDepartment.map((departamento) => (
                <Option key={departamento.id} value={departamento.id}>
                  {departamento.description}
                </Option>
            ))}
          </Select>
        </Space>
          </Col>
          <Col xs={24} md={24} >
            <div className="table-container">
              <table className="freeze-first-column" style={{ fontFamily: 'Montserrat' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '500px', textAlign:'center' }}>ROLE TITLE</th>
                    {coursesLNAList.map((course, index) => (
                      <th key={course} className="left-bottom bottom-align">
                         <span 
                         className={`vertical-title wrap-vertical-title `}
                         style={thStyle}
                         title={course}
                         >
                           {courseIsLoading && (
                            <Spin indicator={antIcon(15)} />
                           ) || (
                            <>{course.description} </>
                           )}
                          </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {receivedData.map((role_title, indexRole) => (

                  (roleTitleLoading && (
                    <Spin indicator={antIcon(15)} />
                  ) || (
                    <tr key={role_title.id}>
                      <td>{role_title.role_title}</td>
                      {coursesLNAList.map((courseDetails, index) => (
                        <td key={courseDetails.id}>
                                {/* {console.log(`Control checked course: ${courseDetails.id} => `)} */}
                                <Checkbox
                                  key={courseDetails.id}
                                  checked={get_fromation_details(courseDetails, role_title.course)}
                                  // defaultChecked={true}
                                  // checked={()}
                                  // defaultChecked={role_title.course[indexRole].required_status}
                                  value={get_fromation_details(courseDetails, role_title.course)}
                                  onChange={(e) => submit_assign_course(role_title, e.target.value, courseDetails)}
                                  // onChange={(e) => handleCursoChange(role_title.course, e.target.value, role_title.role_title, index)}

                              />
                        </td>
                      ))}
                    </tr>
                  ))
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
    </Card>
    </div>
  );
}




export default LNAMain;
