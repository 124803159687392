import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  notification,
  Divider,
} from "antd";
import signinbg from "../../components/assets/images/svg_eng.svg";
import Paragraph from "antd/lib/typography/Paragraph";
import { baseurl } from "../../lib/settings";
import { ErrorAlert } from "../../components/Alerts/genericAlert";
import twiggLogo from "../../components/assets/images/TwiggLogo.png";
import { AuthContext } from "../../components/Context/auth_context";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function SignIn() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const {setUser, controlContext} = useContext(AuthContext)
  const [message, setMessage] = useState("Invalid email or password");

  const openNotification = (placement) => {
    notification.error({
      message: `Invalid Credentials`,
      description: "Invalid email or password",
      placement,
    });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    const data = {
      user_name: values.email,
      password: values.password,
    };
    localStorage.removeItem("token");

    baseurl.post("login", data).then((resp) => {
        if (resp.data.token) {
          localStorage.setItem("token", resp.data.token);
          baseurl.get("user", {
              params: {
                user: resp.data.token,
              },
            }).then((resp) => {
              localStorage.setItem("user", JSON.stringify(resp.data.user));
              setUser(resp.data.user)
              
              
            });
          navigate("/dashboard");
          setIsLoading(false);
        } else {
          setMessage("Invalid email or password");
          setIsLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const RequestVerificationPage = () => {
    navigate("/course/validation");
  };



  return (
    <Layout
      style={{ height: "100vh", fontFamily: 'Montserrat'}}  className="layout-default layout-signin"
    >
        <Header>
          <div className="header-col header-brand">
            <img
              style={{
                marginRight: "5px",
                marginLeft: "20px",  
                height: "42px" }}
                src={twiggLogo}
              alt="Logo"
            />
          </div>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
              <Paragraph></Paragraph>
            </Menu>
          </div>
          <div className="header-btn">
            <Button onClick={RequestVerificationPage} type="primary"  style={{fontFamily: "Montserrat"}}>
              e-Learning
            </Button>
          </div>
          &nbsp;
        </Header>
      <hr style={{ margin: 0 }} />
      <Content className="signin" >
        <Row gutter={[24, 0]} justify="center" align="middle"  style={{ marginTop: "3%", }} >
 
           <Col
            style={{  minWidth: "400px", maxWidth: "30%" }}
            // xs={{ span: 24, offset: 0 }}
            // lg={{ span: 12, offset: 2 }}
            md={{ span: 12, offset: 1 }}
          >
            {error && <ErrorAlert message={message} />}
            <Title className="mb-15" style={{fontFamily: 'Montserrat'}}>Sign In</Title>
            <Title className="font-regular text-muted" level={5} style={{fontFamily: 'Montserrat', fontSize: "16px"}}>
              Enter your email and password to proceed
            </Title>
          <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
              autocomplete="off"
            >
              <Form.Item
                style={{ marginTop: "-5px"}}
                className="username"
                label={<span style={{fontFamily: "Montserrat"}}>Email</span>}
                name="email"
                type="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input style={{fontFamily: "Montserrat"}} placeholder="Email" autocomplete="off" />
              </Form.Item>
              <Form.Item
                className="username"
                label={<span style={{fontFamily: "Montserrat"}}>Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  style={{ fontFamily: "Montserrat"}}
                  type={"password"}
                  placeholder="Password"
                  autocomplete="off"
                />
              </Form.Item>
              <Form.Item
                style={{ marginTop: "-5px"}}
              >
                <Button
                  loading={isloading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", fontFamily: "Montserrat", marginBottom: "3px"}}
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12, minWidth: "400px", maxWidth: "30%"}}
            xs={{ span: 24, offset: 1 }}
            lg={{ span: 12, offset: 1 }}
            md={{ span: 12 }}
          >
            <img src={signinbg} style={{}} alt="" />
          </Col>
        </Row>
      </Content>
      <Divider orientation="right"></Divider>
      <Footer>
        <Menu mode="horizontal">
          <Menu.Item style={{fontFamily: "Montserrat"}}>Copyright &copy; 2023 Speranza Lda. All rights reserved.</Menu.Item>
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>About us</Menu.Item> */}
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>Teams</Menu.Item> */}
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>Products</Menu.Item> */}
        </Menu>
      </Footer>
    </Layout>
  );
}
