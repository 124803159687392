import { Avatar, Card, Col, Row, Table, Input, Typography, Button, Space, Form, Result } from "antd";
import { useEffect, useState } from "react";
import { baseurl } from "../lib/settings";
import moment from "moment";
import { SearchOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PRESET_COLOR_PIKER } from "../utils/constants";
import { GenericErrorMessage, GenericSuccessMessage } from "../components/Alerts/genericAlert";
import CreateFormation from "../components/modal/forms/create_formation";
import EditiFormation from "../components/modal/forms/edit_formation";

const { Title, Paragraph, Text, Link } = Typography;
const { Search } = Input;
const columnsTable = [
    {
      title: "Code",
      dataIndex: "code",
    },
    {
        title: "Training",
        dataIndex: "training",
      },
    {
      title: "Workload",
      dataIndex: "workload",
    },
    {
      title: "Details",
      dataIndex: "details",
      width: "32%",
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Creation date",
      dataIndex: "creation_date",
    },
    {
      title: "Actions",
      dataIndex: "action",
      
    },
  ];

export default function FormationManager() {
    const [listFormation, setListFormation] = useState([]);
    const [listFormationDB, setListFormationDB] = useState([]);
    const [selectedTraining, setSelectedTraining] = useState({});
    const [trainingColor, setTrainingColor] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [formLoading, setFormloading] = useState(false);
    const [formationDataControl, setFormationDataControl] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('none');
    const [form] = Form.useForm();
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 7,
        },
      });

    useEffect(() => {
        setIsloading(true);
        baseurl.get("v1/get_formation/").then((resp) => {
            setListFormation(resp.data);
            setListFormationDB(resp.data)
            setIsloading(false);
        }).catch((err) => {
            setIsloading(false);
            console.log("Error on get treaining list ", err)
        });
        setFormationDataControl(false)
    }, [formationDataControl]);

    

    const list_of_trainings = listFormation.map((item) => ({
        key: item.id,
        code:(<>{item.code}</>),
        
        training: (
          <>
            <Avatar.Group>
              <Avatar className="shape-avatar" src={''} size={25} alt="" />
              <div className="avatar-info">
                <Title level={5}>{item.description}</Title>
              </div>
            </Avatar.Group>
          </>
        ),
        workload: (
            <>
              {item.workload} hours
            </>
        ),
        details: (
            <>
              {item.details}
            </>
        ),
        color: (
          <>
              <div style={{
                height:'5px',
                backgroundColor:`${item.color}`
              }}>
              </div>
          </>
        ),
        creation_date: (
          <>
            <div className="semibold">
              {moment(item.created_at).format("DD/MM/YYYY")}
            </div>
          </>
        ),
        // status: (
        //   <>
        //     {item.is_open ? (
        //       <div className="text-sm">Open</div>
        //     ) : (
        //       <div className="text-sm">Close</div>
        //     )}
        //   </>
        // ),
        action: (
          <>
            <Button onClick={(e) => controlEditorPage('edit', item)}>
            <EditOutlined />
            </Button>
          </>
        ),
      }));


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setListFormation(listFormationDB);
          }
    }

    const searchTraining = (value) => {
        if(value !== ''){
            const searched = listFormation.filter(entry => entry.description.toUpperCase().includes(value.toUpperCase()));
            setListFormation(searched)
        }else{
            setListFormation(listFormationDB);
        } 
    }

   
    const registeNewTraining = (value) => {
        setFormloading(true)
        const data = {
            training_name: value.training_name,
            color: trainingColor,
            workload: value.workload,
            details: value.details,
            operation_type:selectedOption,
            training_id:selectedTraining.id

        }
        baseurl.post('v1/manageformation/', data).then((resp) => {
            setFormloading(false);
            GenericSuccessMessage(resp.data.message);
            setFormationDataControl(true);
            form.resetFields();
            setOpenModal(false);
        }).catch((err) => {
            setFormloading(false)
            GenericErrorMessage('An unexpected error occurred, please try again.')
        })
        console.log("Submit the value ", value)
    }

    const controlEditorPage = (value, object=undefined) => {
      form.resetFields();
      setSelectedTraining({});
      if (value === 'edit'){
          setSelectedTraining(object)
      }
        setSelectedOption(value);
        setOpenModal(true);
    };

    return (
        <Row gutter={[24, 0]}>
            <Col xs={24} md={24}>
                <Card
                    bordered={false}
                    title={"List of formations"}>
                        <Space style={{width:'100%'}} size={5} direction="vertical">
                            <div style={{
                              display:'flex',
                              justifyContent:"space-between"
                            }}>
                              <div style={{width:'40%'}}>
                                  <Input onChange={(e) => searchTraining(e.target.value)} prefix={<SearchOutlined />} placeholder="Search" />
                              </div>
                              <div>
                                <Button onClick={() => controlEditorPage('create')} type="primary"><PlusCircleOutlined size={'50px'} /></Button>
                              </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                loading={isLoading}
                                columns={columnsTable}
                                dataSource={list_of_trainings}
                                pagination={tableParams.pagination}
                                onChange={handleTableChange}
                                className="ant-border-space"
                                //   onRow={(record, rowIndex) => {
                                //     return {
                                //       onClick: event => {getSelectedCourse(events[rowIndex])},
                                //     };
                                //   }}
                                />
                            </div>
                        </Space>
                </Card>
            </Col>
            {/* <Col span={24} md={8} className="mb-24">
                    <Card>
                        {selectedOption == 'none' ?  <Result
                            title="Editing area"
                            subTitle="Select a training to start editing or create a new one."
                            extra={
                            <Button onClick={() => controlEditorPage('create')} type="primary" key="console">
                                Create new Training
                            </Button>
                            }
                        /> : <></>}
                        {selectedOption == 'edit' ?  <p>In development</p> : <></>}
                        {selectedOption == 'create' ? <div style={{
                            // border:'2px solid rgba(140, 140, 140, 0.35)',
                            alignItems:'center',
                            justifyContent:'center',
                            textAlign:'center',
                            padding:'2%',
                            borderRadius:'10px'
                        }}>
                            
                        </div> : <></>}
                        
                    </Card>
            </Col> */}
            {selectedOption == 'edit' ? <EditiFormation 
              loading={formLoading}
              onFinish={registeNewTraining}
              open={openModal}
              setOpen={setOpenModal}
              trainingColor={setTrainingColor}
              traningDetails={selectedTraining}
              formControl={form} /> : <></>}
            {selectedOption == 'create' ? <CreateFormation 
              loading={formLoading}
              onFinish={registeNewTraining}
              trainingColor={setTrainingColor}
              open={openModal} 
              setOpen={setOpenModal} /> : <></>}
        </Row>
    )
};