import { useEffect, useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/TWIGG.png";

export default function Sidenav({ color, user }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [userDetail, setUserDetail] = useState({})
  // useEffect(() => {
  //   setUserDetail(localStorage.getItem('user'));
  //   console.log("Get user permission ", userDetail['web_permission'])
  // })

  function getUserPermission() {
    var usr1 = localStorage.getItem('user')
    var usr2 = JSON.parse(usr1)
    // if (user === undefined){ return usr2.web_permission} else return user.web_permission
    return usr2.web_permission
    
  }
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const badge = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-person-badge-fill"
      viewBox="0 0 16 16"
    >
      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
    </svg>,
  ];

  const feeback_form = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const list_training = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-list-task"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"
      />
      <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
      <path
        fill-rule="evenodd"
        d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"
      />
    </svg>,
  ];

  const documents = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-file-earmark-fill"
      viewBox="0 0 16 16"
    >
      <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z" />
    </svg>,
  ];

  const upload_models = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-cloud-arrow-up-fill"
      viewBox="0 0 16 16"
    >
      <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
    </svg>,
  ];
  const register_user = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-person-fill-add"
      viewBox="0 0 16 16"
    >
      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
    </svg>,
  ];

  const access = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-key-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    </svg>,
  ];

  const formIcondSVG =[
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ui-radios" viewBox="0 0 16 16">
    <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM0 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm7-1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM3 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
  </svg>
  ]
  const manageTrainingIcon = [
    <svg 
    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d9d9d9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect><rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect><line x1="6" y1="6" x2="6.01" y2="6"></line><line x1="6" y1="18" x2="6.01" y2="18"></line></svg>
  ]

  const trainingonDemand =[
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-kanban" viewBox="0 0 16 16">
  <path d="M13.5 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm-11-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M6.5 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm-4 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm8 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1z"/>
</svg>
  ]
  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];
  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <img style={{ 
          marginRight: "5px",
          // marginLeft: "20px",  
          height: "50px",
          marginTop: "-8px"
         }} 
        src={logo} alt=""
        />
        {/* <span></span> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Scheduled</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/badge">
            <span
              className="icon"
              style={{
                background: page === "badge" ? color : "",
              }}
            >
              {badge}
            </span>
            <span className="label">Badge</span>
          </NavLink>
        </Menu.Item>
     
        <Menu.Item key="4">
          <NavLink to="/trainings/documents">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {documents}
            </span>
            <span className="label">Documents</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/feedback_form/list">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {feeback_form}
            </span>
            <span className="billing">Feedback form</span>
          </NavLink>
        </Menu.Item>
        {getUserPermission() === "admin" ? <Menu.SubMenu key="01" rtl title="Administrator">
          <Menu.Item key="9">
              <NavLink to="/forms/control">
                <span
                  className="icon"
                  style={{
                    background: page === "forms/control" ? color : "",
                  }}
                >
                  {formIcondSVG}
                </span>
                <span className="label">Forms</span>
              </NavLink>
          </Menu.Item>

          <Menu.Item key="3">
          <NavLink to="/list_trainigs">
            <span
              className="icon"
              style={{
                background: page === "list_trainigs" ? color : "",
              }}
            >
              {list_training}
            </span>
            <span className="label">Trainings list</span>
          </NavLink>
        </Menu.Item>
          
          <Menu.Item key="0">
              <NavLink to="/formation/manage">
                <span
                  className="icon"
                  style={{
                    background: page === "/formation/manage" ? color : "",
                  }}
                >
                  {manageTrainingIcon}
                </span>
                <span className="label">Training Management</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="15">
              <NavLink to="/formation/on_demand">
                <span
                  className="icon"
                  style={{
                    background: page === "/formation/on_demand" ? color : "",
                  }}
                >
                  {trainingonDemand}
                </span>
                <span className="label">Train on Demand</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="0">
              <NavLink to="/training/manage/edit">
                <span
                  className="icon"
                  style={{
                    background: page === "/training/manage/edit" ? color : "",
                  }}
                >
                  {list_training}
                </span>
                <span className="label">Edit trainings</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="0">
              <NavLink to="/manage/email/sent_emails">
                <span
                  className="icon"
                  style={{
                    background: page === "/manage/email/sent_emails" ? color : "",
                  }}
                >
                  {list_training}
                </span>
                <span className="label">Emails</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="9">
              <NavLink to="/forms/lna/main">
                <span
                  className="icon"
                  style={{
                    background: page === "/forms/lna/main" ? color : "",
                  }}
                >
                  {formIcondSVG}
                </span>
                <span className="label">LNA</span>
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item key="9">
              <NavLink to="">
                <span
                  className="icon"
                  style={{
                    background: page === "" ? color : "",
                  }}
                >
                  {formIcondSVG}
                </span>
                <span className="label">LNA</span>
              </NavLink>
            </Menu.Item> */}
            
            <Menu.Item key="6">
              <NavLink to="/updload_models">
                <span
                  className="icon"
                  style={{
                    background: page === "updload_models" ? color : "",
                  }}
                >
                  {upload_models}
                </span>
                <span className="label">Upload</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="7">
              <NavLink to="/user/register">
                <span
                  className="icon"
                  style={{
                    background: page === "register" ? color : "",
                  }}
                >
                  {register_user}
                </span>
                <span className="label">Register user</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="8">
              <NavLink to="/acess_control">
                <span
                  className="icon"
                  style={{
                    background: page === "register" ? color : "",
                  }}
                >
                  {access}
                </span>
                <span className="label">Access</span>
              </NavLink>
            </Menu.Item>
        </Menu.SubMenu> : <></>}
      </Menu>
    </>
  );
}
