import { useEffect, useState } from "react";
import { Button, Card, Col, Popconfirm, Radio, Row, Table, Typography } from "antd";
import { baseurl } from "../../lib/settings";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { GenericErrorMessage, GenericSuccessMessage } from "../../components/Alerts/genericAlert";


const { Title } = Typography;

export default function TrainingOnDemandPage(){
    const [isLoading, setIsloading] =  useState(false);
    const [activeTrainingControl, setActiveTrainingControl] =  useState(false);
    const [showActiveTraining, setShowActiveTraining] =  useState(true);
    const [activeTrainingDemand, setActiveTrainingDemand] =  useState([]);

    useEffect(() => {
        setIsloading(true);
        baseurl.get('v1/formation_suggestion/').then((resp) => {
            setActiveTrainingDemand(resp.data);
            setIsloading(false);
        }).catch((err) => {
            setIsloading(false);
        });
        setActiveTrainingControl(false);
    },[])

    function getActiveTrainingDemand(){
        baseurl.get('v1/formation_suggestion/').then((resp) => {
            setActiveTrainingDemand(resp.data);
            setIsloading(false);
        }).catch((err) => {
            setIsloading(false);
        });
    }
    const [tableParamsActiveTraining, setTableParamsActiveTraining] = useState({
        pagination: {
          current: 1,
          pageSize: 6,
          total: activeTrainingDemand.lenght,
        },
    });


    const handleActiveTrainingChange = (pagination, filters, sorter) => {
        setTableParamsActiveTraining({
          pagination,
          filters,
          ...sorter,
        });}
    

        const activeData = activeTrainingDemand.map((training) => ({
            key: training.id,
            name: (
              <>
                <div className="avatar-info">
                    <Title level={5}>{training.formation}</Title>
                  </div>{" "}
              </>
            ),
            employee_attend: (
              <>
                <div className="author-info">
                <p>{training.suggestions_count}</p>
                </div>
              </>
            ),

            action: (
                <>
                  <Popconfirm
                    title="Are you sure you want to mark this training as complete?"
                    description=""
                    okText="Yes"
                    onConfirm={() => markTrainingAsComploted(training.id)}
                  >
                    <Button type="dashed" className="tag-primary">
                      <CheckCircleOutlined style={{ fontSize: "17px" }} />
                    </Button>
                  </Popconfirm>
                </>
              ),
        }));

    function markTrainingAsComploted(attendeceTrainingID){
        baseurl.post('v1/formation_suggestion/update/', {
            training_demand_id:attendeceTrainingID,
            training_status: 'Completed'
        }).then((resp) => {
            setActiveTrainingDemand(resp.data['data']);
            setActiveTrainingControl(true);
            getActiveTrainingDemand()
            GenericSuccessMessage(resp.data['message'])
            // setError(false)
        }).catch((err) => {

            // setError(true)
        });
    }

    return(
       <>
        <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
          <div className="tabled">
            <Card
              bordered={false}
              // className="criclebox tablespace mb-24"
              title="Training on demand"
              extra={
                <>
                  <Radio.Group defaultValue="a" onChange={() => console.log('Changue status')}>
                    <Radio.Button value="a">Actives</Radio.Button>
                    {/* <Radio.Button value="b">Complited</Radio.Button> */}
                  </Radio.Group>
                </>
              }
            >
              {showActiveTraining ? <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Table
                  loading={isLoading}
                  columns={activeTrainingColumns}
                  dataSource={activeData}
                  pagination={tableParamsActiveTraining.pagination}
                  onChange={handleActiveTrainingChange}
                  className="ant-border-space"
                />
              </div> : <div className="table-responsive">
                {/* <Table
                  loading={userAccountControl}
                  columns={userAccountsColumns}
                  dataSource={userAccounts}
                  pagination={tableParamsUserAccount.pagination}
                  onChange={handleUserAccountTableChange}
                  className="ant-border-space"
                /> */}
              </div>}
              
            </Card>
          </div>
        </Col>
      </Row>
       </>
    )
}


const activeTrainingColumns = [
    {
      title: "Taining",
      dataIndex: "name",
      key: "name",
      width: "70%",
      
    },
    {
      title: "Total employees to attend",
      dataIndex: "employee_attend",
      key: "employee_attend",
      width: "30%",
    },

    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        // width: "30%",
      },
  ];