import { Col } from "antd";
import ReactECharts from "echarts-for-react";

export default function DetailedFeedbackForm({ answers, feedback_question }){
    var app = {}
    var myChart = {}
    const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      };
      app.config = {
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'top',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        color:'#726f76',
        // formatter: '{c}  {name|{a}}',
        fontSize: 13,
        // rich: {
        //   name: {}
        // }
      };
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          dshow: true,
          orient: 'horizontal',
          left: 'center',
          top: 'bottom',
          data: answers
        },
        
        // toolbox: {
        //   show: true,
        //   orient: 'vertical',
        //   left: 'right',
        //   top: 'center',
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar', 'stack'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['1', '2', '3', '4', '5', '6']
          }
        ],
        yAxis: [
          {
            show:false,
            type: 'value'
          }
        ],
        series: [
          {
            name: feedback_question[0].question_1,
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: feedback_question[1].answer_num
          },
          {
            name: feedback_question[1].question_2,
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: feedback_question[2].answer_num
          },
          {
            name: feedback_question[2].question_3,
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: feedback_question[2].answer_num
          },
          {
            name: feedback_question[3].question_4,
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: feedback_question[3].answer_num
          }
          
        ]
      };
    return (
      <Col xs={24} md={14}>
        <div style={{
          padding: '15px',
          border:'2px solid rgba(140, 140, 140, 0.35)',
          borderRadius:'10px'
        }}>
          <h6 className="font-semibold m-0 chart_title">
            Feedback by Questions
          </h6>
          {feedback_question !== undefined && <ReactECharts style={{ width: "100%", height: "310px" }} option={option} />}</div>
      </Col>
    )
}