import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  notification,
  Alert,
  Divider,
} from "antd";
import signinbg from "../../components/assets/images/film6.svg";
import Paragraph from "antd/lib/typography/Paragraph";
import { baseurl } from "../../lib/settings";
import { ErrorAlert } from "../../components/Alerts/genericAlert";
import twiggLogo from "../../components/assets/images/TwiggLogo.png";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function VerificationPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("Invalid email or password");


  const onFinish = (values) => {
    setIsLoading(true);
    const data = {
      code: values.code,
    };
    baseurl
      .get("/v1/otp/", { params: data })
      .then((resp) => {
        localStorage.setItem("token", resp.data.token);
        setIsLoading(false);
        navigate(
          "/courses/formation/induction"
        );
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 400) {
          setError(true);
          setMessage(e.response.data);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const LoginPage = () => {
    navigate("/");
  };

  return (
    <Layout
      style={{ height: "100vh" }}
      className="layout-default layout-signin"
    >
          <Header>
            <div className="header-col header-brand">
              <img
                style={{
                marginRight: "5px",
                marginLeft: "20px",  
                height: "42px"
               }}
                src={twiggLogo}
                alt="Logo"
              />
            </div>
            <div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Paragraph></Paragraph>
              </Menu>
            </div>
            <div className="header-btn">
              <Button onClick={LoginPage} type="primary" style={{fontFamily: "Montserrat", width: "98px"}}>
                Sign In
              </Button>
            </div>
            &nbsp;
          </Header>
      <hr style={{ margin: 0 }} />
      <Content className="signin">
        <Row gutter={[24, 0]} justify="center" align="middle"  style={{ marginTop: "5%"}} >
          <Col
            style={{ padding: 6, minWidth: "300px", maxWidth: "25%"}}
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 2 }}
            md={{ span: 12, offset: 1 }}
          >
            <Title className="mb-15" style={{fontFamily: "Montserrat"}}>Verification</Title>
            <Title className="font-regular text-muted" level={5} style={{fontFamily: "Montserrat", fontSize: "16px", marginBottom: "-10px"}}>
              Introduce the authentication code
            </Title>
            {error && <ErrorAlert message={message} />}
            <br />
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="code"
                label={<span  style={{fontFamily: "Montserrat" }}>Code</span>}
                name="code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  style={{fontFamily: "Montserrat"}}
                  placeholder="Code"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={isloading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", fontFamily: "Montserrat", marginTop: "-10px" }}
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12, minWidth: "400px", maxWidth: "25%"}}
            xs={{ span: 24, offset: 1 }}
            lg={{ span: 12, offset: 1 }}
            md={{ span: 12 }}
          >
            <img src={signinbg} alt="" />
          </Col>
        </Row>
      </Content>
      <Divider orientation="right"></Divider>
      <Footer style={{fontFamily: "Montserrat"}}>
        <Menu mode="horizontal">
          <Menu.Item style={{fontFamily: "Montserrat"}}>Copyright &copy; 2023 Speranza Lda. All rights reserved.</Menu.Item>
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>About us</Menu.Item> */}
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>Teams</Menu.Item> */}
          {/* <Menu.Item style={{fontFamily: "Montserrat"}}>Products</Menu.Item> */}
        </Menu>
      </Footer>
    </Layout>
  );
}
