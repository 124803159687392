import React from "react";
import TWIGG from "../../../../components/assets/icons/TWIGG.png";
import {
  Button,
  Descriptions,
  Row,
  Statistic,
  Tag,
  Input,
} from "antd";
import "./nav.css";
import { PageHeader } from '@ant-design/pro-layout';
const { Search } = Input;
function CourseNavbar() {
  const routes = [
    {
      path: "index",
      breadcrumbName: "First-level Menu",
    },
    {
      path: "first",
      breadcrumbName: "Second-level Menu",
    },
    {
      path: "second",
      breadcrumbName: "Third-level Menu",
    },
  ];
  return (
    <div className="navbar">
      <PageHeader
        className="site-page-header"
        title={<img className="twigg-logo" src={TWIGG} alt="logo" id="logo" />}
        subTitle="video streaming"
        extra={[
          <Search placeholder="input search text" enterButton="Search" />,
          <Button key="3" type="Login" block>
            Back
          </Button>,
        ]}
      ></PageHeader>
      <br />
    </div>
  );
}

export default CourseNavbar;
