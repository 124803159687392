import React, { useEffect, useRef, useState } from "react";
import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactPlayer from "react-player";
// import "./dns.css";
import {
  Slider,
  Card,
  Form,
  Input,
  Button,
  Radio,
  Col,
  Row,
  Modal,
  Space,
  Collapse, 
  theme,
  Descriptions,
  Typography,
  Divider,
  Spin,
  Image
} from "antd";
import moment from "moment";
import { baseurl } from '../../../lib/settings';
import TextArea from "antd/lib/input/TextArea";
import imageError from '../../../components/assets/images/not_found.png'
import './streaming_css.css'

const { Paragraph, Text, Title } = Typography;

const antIcon2 = (
    <LoadingOutlined
      style={{
        fontSize: 50,
      }}
      spin
    />
  );
  let factor = 0.3;

export default function VideoStreemPage({ selectedCourse, moduleCode, setUpdate }){
    // const [size, setSize] = useState(8);
  const [trackVideoDataControl, setTrackVideoDataControl] = useState(false);
  const [trackVideoDataCount, setTrackVideoDataCount] = useState(0);
  const [trackVideoDataError, setTrackVideoDataError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [comment, setComment] = useState("");
  const [startTime, setStatTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [played, setPlayed] = useState(0);
  const [loadedSeconds, setLoadedSeconds] = useState(0);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [loaded, setLoaded] = useState(0);
  const playerRef = useRef();
  const [size, setSize] = useState('small');

//   const { token } = theme.useToken();


  useEffect(() => {
    setIsLoading(true);
    var params = {
      module_code:moduleCode,
      video_code:selectedCourse.code,
    }
    baseurl.get('v1/streaming/progress/', {params}).then((resp) => {
      setVideoProgress(resp.data);
      setTrackVideoDataControl(false);
    // this.p.seekTo(30.7);
      setIsLoading(false);
    //   playerRef.current.seekTo(parseFloat(resp.data.played_seconds), 'seconds');
      
    }).catch((err  => {
        setTrackVideoDataCount(trackVideoDataCount+1)
        console.log("Error on get initial progress", trackVideoDataCount, err);
        
        
        if (trackVideoDataCount >= 3){
            setIsLoading(false);
            setTrackVideoDataError(true)
        }else{
        setTrackVideoDataControl(true);
        } 
      }))
  },[trackVideoDataControl])

  const onFinish = (values) => {
    setModalOpen(true);
  };

  const marks = {
    0: "0",
    10: "10",
  };

  const isCommentEmpty = comment.trim() === "";
  // const defaultVideoUrl ="/home/mzodqjvb/filemaneger/twigg/videos/twigg-introduction-video.mov";

  const trackPlayedViedio = (e) => {
    setStatTime(moment().format("YYYY-MM-DD hh:mm:ss"))
    var videoProgressId = undefined
    if (Object.keys(videoProgress).length === 0){
      // console.log("Loaded Viedo Progress 1", videoProgress)
      var data = {
        start_time:moment().format("YYYY-MM-DD hh:mm:ss"),
        loaded:loaded,
        loaded_seconds:loadedSeconds,
        module_code:moduleCode,
        video_code:selectedCourse.code,
        watched_completely:false,
      }
      baseurl.post('v1/streaming/progress/', data).then((resp) =>{
        setVideoProgress(resp.data)
      }).catch (err => {
        console.log("Error on start save", err)
      })
    }
    
  }

  const trackPlayedViedioOnPouse = (e) => {
    setStatTime(moment().format("YYYY-MM-DD hh:mm:ss"))
    var videoProgressId = undefined
    if (Object.keys(videoProgress).length > 0){
      // console.log("Loaded Viedo Progress 1", videoProgress)
      videoProgressId = videoProgress.id
    }
    var data = {
      end_time:moment().format("YYYY-MM-DD hh:mm:ss"),
      played:played,
      played_seconds:playedSeconds,
      video_progress_id:videoProgressId,
      module_code:moduleCode,
      video_code:selectedCourse.code,
      watched_completely:false,
    }
    baseurl.post('v1/streaming/progress/', data).then((resp) =>{
      setVideoProgress(resp.data)
    }).catch (err => {
      console.log("Error on start save")
    })
  }

  const onPorcessViedio = (track) => {
    console.log("Track Loaded Video")
    
    setPlayedSeconds(track.playedSeconds)
    setPlayed(track.played)
    setLoadedSeconds(track.loadedSeconds)
    setLoaded(track.loaded)
    // console.log("More details video ", playedSeconds, played, loadedSeconds, loaded)
  }

  const finishVideo = (e) => {

    // console.log("Start video Time ", startTime)
    // console.log("Finishe video Time", endTime)
    // console.log("More details video ", playedSeconds, played, loadedSeconds, loaded)

    var videoProgressId = undefined
    if (Object.keys(videoProgress).length > 0){
      videoProgressId = videoProgress.id
    }
    var data = {
      end_time:moment().format("YYYY-MM-DD hh:mm:ss"),
      played:played,
      played_seconds:playedSeconds,
      video_progress_id:videoProgressId,
      module_code:moduleCode,
      video_code:selectedCourse.code,
      watched_completely:true,
    }
    baseurl.post('v1/streaming/progress/', data).then((resp) => {
      setUpdate(true)
      setVideoProgress(resp.data)
    }).catch (err => {
      console.log("Error on final save")
    })
  }
  function getvideoDescriptionStyled(text) {
    
    return <p
            style={{
            paddingLeft: 24,
            }}
        >
            {text}
        </p>
  }

    return (
      <Col>
      {trackVideoDataError ? <Image
            width={200}
            src={imageError}
        /> : <Card>
                <ReactPlayer
                      key={selectedCourse.id}
                      url={selectedCourse.video}
                      style={{
                        backgroundColor:'black',
                        // pada
                      }}
                      controls={true}
                      width="100%"
                      height="100%"
                      ref={playerRef}
                      onStart={(e) => trackPlayedViedio(e)}
                      onEnded={(e) => finishVideo(e)}
                      onProgress={(e) => onPorcessViedio(e)}
                      onPause={(e) => trackPlayedViedioOnPouse()}
                  />
          </Card>}
      <br/>
      <Title level={3} style={{ fontFamily: 'Montserrat'}}>{selectedCourse.title}</Title>
      <Divider orientation="left">Notes</Divider>
      <Paragraph ellipsis={true} style={{ fontFamily: 'Montserrat'}}>{selectedCourse.notes}</Paragraph>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
            // label="Use the field bellow for comments / notes"
            name="comment"
            rules={[{ message: "Please add your comment." }]}
        >

            <TextArea style={{ fontFamily: 'Montserrat'}}
                placeholder="Write your comments / notes here"
                rows={4} 
                onChange={(e) => setComment(e.target.value)} />
                {" "}
        </Form.Item>
        {!isCommentEmpty && (
            <>
              <Row >
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Form.Item
                    
                    label={<span style={{ fontFamily: 'Montserrat'}}>Rate from 0 to 10</span>}
                    name="rating"
                    rules={[
                      { required: true, message: "Please! Rate the course." },
                    ]}
                  >
                    <Slider 
                      
                      value={size}
                      onChange={(value) => setSize(value)}
                      min={0}
                      max={10}
                      marks={marks}
                    />
                  </Form.Item>
                </Col >
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {/* <Space  size={200}/> */}
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Form.Item 
                    label="How do you rate the content you just saw?"
                    name="reaction"
                    rules={[
                      {
                        required: true,
                        message: "Please! Choose an option.",
                      },
                    ]}
                  >
                    <Radio.Group >
                      <Radio style={{ fontFamily: 'Montserrat'}} value="Good">Good</Radio>
                      <Radio style={{ fontFamily: 'Montserrat'}} value="Very Good">Very Good</Radio>
                      <Radio style={{ fontFamily: 'Montserrat'}} value="Bad">Bad</Radio>
                      <Radio style={{ fontFamily: 'Montserrat'}} value="Very Bad">Very Bad</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                      <Button style={{ fontFamily: 'Montserrat'}} type="primary" htmlType="submit">
                          Submit my feedback
                      </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
        )}
      </Form>
      </Col>
      
    )
}

