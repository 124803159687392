import React, { useEffect, useState } from 'react';
import { Layout, Typography, Form, Button, Divider, Alert, Checkbox, Card, Spin, Col, Row, Image } from 'antd';
// import Question from './Question';
import "./quiz.css"
import CustomModal from '../modal/modal';
import { baseurl } from '../../../lib/settings';
import Question from './Question';
import { LoadingOutlined } from '@ant-design/icons';
import { GenericErrorMessage, GenericSuccessMessage } from '../../../components/Alerts/genericAlert';
import StreamingIcon from '../../../components/assets/icons/no_video_icon.png';
const { Content } = Layout;
const { Title } = Typography;

const antIcon2 = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

const Quiz = ({selectedQuizData, update}) => {
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [quizFormData, setQuizFormData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  // const [selectedValues, setSelectedValues] = useState([]);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    var data = {
      code: selectedQuizData.code
    }
    baseurl.post("v1/quiz/list_selected/", data).then((resp) => {
      setQuizFormData(resp.data)
      console.log("DATA dados: ", resp.data);
      setIsLoading(false)
      setIsLoading(false);
    }).catch((err) => {
      console.log("Error on show quiz modal", err)
    })
  }, [])

  const showModal = () => {
    setIsLoading(true)
    var data = {
      code: selectedQuizData.code
    }
    baseurl.post("v1/quiz/list_selected/", data).then((resp) => {
      // setSelectedCourse(resp.data);
      setQuizFormData(resp.data)
      console.log("DATA dados: ", resp.data);
      setIsLoading(false);
      setModalVisible(true);
    }).catch((err) => {
      console.log("Error on show quiz modal", err)
    })
  };

  const closeModal = () => {
    setModalVisible(false);
  }

  const handleFormChange = (changedValues) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...changedValues }));
  };

  // const handleSubmit = () => {
  //   console.log("selecao: ", selectedValues);
  // };

  // const handleQuestionChange = (questionKey, value) => {
  //   setSelectedValues((prevSelectedValues) => ({
  //     ...prevSelectedValues,
  //     [questionKey]: value,
  //   }));
  // };

  const handleSubmit = () => {


    
    setIsLoadingSubmit(true)
    console.log(selectedValues);

    baseurl.post('v1/quiz/training_questionnaire_answer/', {answers:selectedValues}).then((resp) => {
      setIsLoadingSubmit(false);
      update(true);
      GenericSuccessMessage('Successfully submitted')
      form.resetFields()
    }).catch((err) =>{
      setIsLoadingSubmit(false)
      console.log("Error on save questions")
      GenericErrorMessage("Sorry, we were unable to process your request, please try again.")
    })
  };

  const handleQuestionChange = (value) => {
    console.log("Chancg value ", value)
    setSelectedValues([...selectedValues, value])
    // setSelectedValues((prevSelectedValues) => ({
    //   ...prevSelectedValues, value,
    // }));
  };

  const handleCheckBoxChange = (e) => {
    setCheckBoxChecked(e.target.checked);
    // setSubmitButtonDisabled(!e.target.checked);
  };
  return (

    <Card  hoverable>
      <Content className='main-test-content' style={{ display: 'flex',  width: '100%', minHeight: '50vh' }}>
      {Object.keys(selectedQuizData).length > 0 ? (
          <Col xl={24}>
            <div style={{ fontFamily: 'Montserrat'}} className='top-div'>
                <Title style={{ fontFamily: 'Montserrat'}} level={3}>{selectedQuizData.description}</Title>
              </div>
              <Layout style={{
                overflow:'scroll',
                overflowX:'hidden',
                height:'600px'
              }}>
                <div style={{
                   marginLeft:'20px',
                   marginRight:'20px',
                }}>
                  <Form
                  key={form}
                  style={{width: "100%",}}
                  disabled={isLoadingSubmit}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  onChange={handleFormChange}
                >
               
                  <br />
                  {isLoading ?  <div className="centered">
                    <Spin indicator={antIcon2} />
                  </div> : quizFormData.map((question, index) => (
                    <Question
                    
                    // index={question.id}
                      key={question}
                      index={<span style={{ fontFamily: 'Montserrat'}}>{index+1}</span>}
                      question={<span style={{ fontFamily: 'Montserrat'}}>{question.question_name}</span>}
                      options={[question.first_option, question.second_option, question.third_option, question.fourth_option]}
                      value={question.question1}
                      onChange={(e) => {
                        // handleFormChange({ answer: e.target.value, question_id:question.id });
                        // console.log("Control value change", { answer: e, question_id:question.id })
                        var value = { answer: e, question_id:question.id }
                        handleQuestionChange(value);
                      }}
                      courseCode="1 Point" 
                />
                  ))}
                </Form>
                </div>
              </Layout>
            <Row gutter={[24, 24]}>
            <Divider orientation='left' style={{ fontFamily: 'Montserrat'}}><strong>Honor Code</strong></Divider>
            <Col xs={24} sm={24} md={21} lg={21} xl={21} className="mb-24">
              <Checkbox checked={checkBoxChecked} onChange={handleCheckBoxChange}>
                  <span style={{ fontFamily: 'Montserrat'}}> I<strong></strong> understand that submiting work that isn't my own, may result in permanent failure of this course or deactivation of my account.</span>
                    </Checkbox>
             </Col>
             
             <Col xs={24} sm={24} md={2} lg={2} xl={2}  className="mb-24">
                <Button
                   loading={isLoadingSubmit}
                   type="primary" 
                   onClick={handleSubmit} 
                   disabled={!checkBoxChecked}>
                      Submit answers
                </Button>
             </Col>
            </Row>
          </Col>
        ) : (
          <Col style={{
            textAlign:'center',
            margin:'auto'
          }} xs={24} sm={24} md={24} lg={24} xl={24} >
            <Image 
            preview={false}
            src={StreamingIcon} />
            <br/>
            <p style={{
                fontFamily: 'Montserrat', 
                fontSize: '20px'
            }}>No video selected</p>
          </Col>
        )}
    </Content>
  </Card>
  );
};

export default Quiz;
