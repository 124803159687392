import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import ReactPlayer from "react-player";
import { baseurl } from "../../lib/settings";
import "./main.css";

function MainPage() {
  const [streaming, setStreaming] = useState({});

  useEffect(() => {
    var data = { code: "04" };
    baseurl.post("v1/streaming/trainig/", data).then((resp) => {
      setStreaming(resp.data);
    });
  }, []);

  return (
    <div>
      <div>
        <h2>Página principal</h2>
      </div>
    </div>
  );
}

export default MainPage;
