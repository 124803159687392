import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import React from "react";
import { useState } from "react";
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";

function SingleFileUpload({ trainingDoc, training }) {
  const [document, setDocument] = useState();
  const [files, setFiles] = useState([]);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  function onFileChange(info) {
    setDocument(info.file.originFileObj);
    trainingDoc(info.file.originFileObj);
    console.log("comm", info.file);
  }

  function UploadFile(info) {
    console.log("document", document);
  }

  return (
    <div className="App">
      <FileUpload
        files={files}
        setFiles={setFiles}
        removeFile={removeFile}
        trainingPresence={training}
      />
      <FileList files={files} removeFile={removeFile} />
    </div>
  );
}

export default SingleFileUpload;
