import React, { useEffect, useState } from "react";
import { Button, Cascader, Col, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import { baseurl } from "../../../../lib/settings";
import { DownloadOutlined, PlusOutlined, PoweroffOutlined, SaveOutlined } from "@ant-design/icons";
import { P } from "react-html5video";
import quizQuestIcon from '../../../../components/assets/icons/quiz_icon.png'
import Icon from "@ant-design/icons/lib/components/Icon";
import { GenericErrorMessage, GenericSuccessMessage } from "../../../../components/Alerts/genericAlert";
import EdidFormQuestion from "./edit";
import { EditSelectedQuestion, QuestionForm } from "./edit_question";
const { Paragraph } = Typography;

export default function FormCreator({showModal, setShowModal, loading, setControlQuizHistory}){
    const [form] = Form.useForm();
    const [videoModuleData, setVideoModuleData] = useState([])
    const [chosenModule, setChosenModule] = useState({})
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [questionControl, setQuestionControl] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [quizDescription, setQuizDescription] = useState('');
    const [editQuestionControl, setEditQuestionControl] = useState(false);
    const [editQuestionFormControl, setEditQuestionFormControl] = useState(false);
    const [questionFormIndex, setQuestionFormIndex] = useState(false);
    const [displyQuestionName, setDisplayQuestionName] = useState('Not Selected');
    const rigthAnswerChoise = [
        {
            value: 'first_option',
            label: 'First option',
        },
        {
            value: 'second_option',
            label: 'Second option',
        },
        {
            value: 'third_option',
            label: 'Third option',
        },
        {
            value: 'fourth_option',
            label: 'Fourth option',
        },
    ]

    useEffect(() => {
        loading(true)
        baseurl.get('v1/training/video_module/').then((resp) => {
            loading(false)
            setVideoModuleData(resp.data)
            // console.log("Training Module", resp.data)
        }).catch((err) =>{
            loading(false)
            GenericErrorMessage("Sorry, we were unable to open survey creator")
            // console.log("Error Video Module", err)
        })
    }, [])

    

    const handleOk = () => {
        setConfirmLoading(true);
        var data = {
            video_code:chosenModule[1],
            training_module_code:chosenModule[0],
            quiz_description:quizDescription,
            questions:questions
        }
        baseurl.post('v1/training_questionnaire/', data).then((resp) => {
            GenericSuccessMessage(resp.data['message'])
            setShowModal(false);
            setConfirmLoading(false);
            setControlQuizHistory(true);
        }).catch((err) => {
            GenericErrorMessage("Sorry, something didn't go as expected")
            setConfirmLoading(false);
        })
      };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setShowModal(false);
    };

    const associateTrainingCourse = (e) => {
        setChosenModule(e)
        // console.log("Chosen models", e)
    }

    const buildQuestion =() => {
        // console.log("Quistion Buided", questions)
        setEditQuestionFormControl(false)
        setQuestionControl(false)
        setEditQuestionControl(false)
        setQuestionControl(true)
    }

    const saveQuestion = (values) => {
        // setQuestions((prevFormData) => ({ ...prevFormData, ...values }));
        setQuestions([...questions, { ...values }]);
        // console.log("Form Value", values)
        setQuestionControl(false)
        setEditQuestionControl(false)
        form.resetFields();
    }

    const updateSelectedQuestion = (values) => {
        console.log("Updating data", values)
        if(values.question_name !== undefined){
            questions[questionFormIndex].question_name = values.question_name
        }
        if(values.question_type !== undefined){
            questions[questionFormIndex].question_type = values.question_type
        }
        if(values.first_option !== undefined){
            questions[questionFormIndex].first_option = values.first_option
        }
        if(values.second_option !== undefined){
            questions[questionFormIndex].second_option = values.second_option
        }
        if(values.third_option !== undefined){
            questions[questionFormIndex].third_option = values.third_option
        }
        if(values.fourth_option !== undefined){
            questions[questionFormIndex].fourth_option = values.fourth_option
        }
        if(values.right_answer !== undefined){
            questions[questionFormIndex].right_answer = values.right_answer
        }
        setQuestionControl(false)
        setEditQuestionControl(false)
        form.resetFields();
    }
    
    const editQuestion = (question, index) => {
        setDisplayQuestionName(question.question_name);
        setQuestionFormIndex(index)
        setEditQuestionControl(true)
    }

    const closeEditQuestion = () => {
        setEditQuestionFormControl(false)
        setQuestionControl(false)
        setEditQuestionControl(false)
        form.resetFields();
    }
    
    const editSelectQuestion = () => {
        // console.log("Mostrando console")
        setEditQuestionFormControl(true)
        setQuestionControl(true)
    }

    const deleteSelectedQuestion = () => {
        questions.splice(questionFormIndex, 1)
        setEditQuestionFormControl(false)
        setQuestionControl(false)
        setEditQuestionControl(false)
        form.resetFields();
    }

    return (
            <Modal
                centered
                title="Create Quiz Questions"
                // open={showModal}
                visible={showModal}
                onOk={handleOk}
                okText="Create Form"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                
                    <Col>
                        <Space direction="vertical" size={5} style={{ display: 'flex' }}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Associate with:
                            </Typography.Title>
                            <Cascader
                                size="large"
                                    style={{  width: '100%' }}
                                    maxTagCount="responsive"
                                    onChange={(e) => associateTrainingCourse(e)}
                                options={videoModuleData.map((module, index) => (
                                    {
                                        value: module.code,
                                        label: module.title,

                                        children: module.videos.map((video, index)=>(
                                            {
                                                value: video.code,
                                                label: video.title,
                                            }
                                        )),
                                    }
                                ))}
                            />
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Quiz description:
                            </Typography.Title>
                                <Input
                                    onChange={(e) => setQuizDescription(e.target.value)}
                                    placeholder="Input the quiz description" />
                            <Paragraph >Click to add questions to your survey</Paragraph>
                            <Row gutter={[0, 24]}>
                                <Col span={3}>
                                    <Button
                                        style={{
                                            height:35,
                                            width:50
                                        }}
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={buildQuestion}
                                        // loading={loadings[2]}
                                        // onClick={() => enterLoading(2)}
                                    />
                                </Col>
                                
                                {editQuestionControl ? <EdidFormQuestion 
                                    edit={() => editSelectQuestion()}
                                    close={closeEditQuestion}
                                    questionIndex={questionFormIndex}
                                    question_name={displyQuestionName} /> : questions.map((quest, index) => (
                                    <Col span={3}>
                                        <Button
                                            style={{
                                                display:'inline-flex',
                                                alignItems:'center',
                                            }}
                                            onClick={() => editQuestion(quest, index)}
                                        >P{index+1}</Button>
                                    </Col>
                                ))}
                            </Row>
                        </Space>
                        <br/>
                        {questionControl && <Form
                            form={form}
                            labelCol={{span: 24, }}
                            wrapperCol={{span: 24,}}
                            layout="horizontal"
                            size="large"
                            onFinish={editQuestionFormControl ? updateSelectedQuestion : saveQuestion}
                        >
                            {editQuestionFormControl ? <EditSelectedQuestion
                                deleteItem={deleteSelectedQuestion} 
                                questionFields={questions[questionFormIndex]} 
                                rigthAnswerChoise={rigthAnswerChoise}/> : <QuestionForm rigthAnswerChoise={rigthAnswerChoise} />}
                        </Form>}
                        
                    </Col>
            </Modal>
    )
}