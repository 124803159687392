import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AdminLayout from "./components/_partials/_adminlayout/_admin_layout";
import Dashboard from "./pages/dashboard";
// import "antd/dist/antd.css";
import "antd/dist/reset.css";
import "./components/static/css/main.css";
import "./components/static/css/responsive.css";
import SignIn from "./pages/login/login";
import ScheduleAddEmployee from "./pages/add_employee_to_training";
import Badge from "./pages/badge";
import Documents from "./pages/uploaded_documents";
import PPTX from "./pages/pptx";
import Files from "./pages/show_sigle_training_docs";
import PresenceValidade from "./pages/presence_validate";
import ListTrainings from "./pages/list_trainings";
import FeedbackForm from "./pages/feedback_form";
import ListTrainingDocs from "./pages/docs/list_trainings_docs";
import UploadModelsByDocs from "./pages/docs/upload_employee";
import VerificationPage from "./pages/CourseProgram/verificationPage";
import CourseLayout from "./pages/CourseProgram/CourseLayout/CourseLayout";
import UserRegistratition from "./pages/user/registration";
import CategoryUpload from "./pages/docs/caregory_upload";
import UploadVideoTraining from "./pages/docs/upload_training_video";
import ControlAccess from "./pages/access/control_access";
import CourseSidebar from "./pages/CourseProgram/CourseLayout/sidebar/CourseSidebar";
import CourseDetail from "./pages/CourseProgram/Course/CourseDetail";
import Stream from "./pages/VideoStreaming/stream";
import FormsControl from "./pages/forms_control";
import LNAMain from "./pages/lna/lanMain";
import "./global.css";
import { ConfigProvider } from "antd";
import FormationManager from "./pages/formation";
import AuthProvider from "./components/Context/auth_context";
import StyledLogin from "./pages/login/styled_login";
import TrainingEditManager from "./pages/edit_trainings";
import ReceivedEmails from "./pages/email/received_email_list";
import TrainingOnDemandPage from "./pages/training/on_demand";


function App() {
  return (
    <ConfigProvider theme={{ token: { fontFamily: "Montserrat" } }}>
        <Routes>
          <Route path="/" element={
              <SignIn />} />
              {/* <Route path="/" element={
                <StyledLogin />} 
              /> */}
          <Route
            exact
            path="/dashboard"
            element={
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
            }
          />

          <Route
            exact
            path="/addemployee"
            element={
              <AdminLayout>
                <ScheduleAddEmployee />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/badge"
            element={
              <AdminLayout>
                <Badge />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/trainings/documents"
            element={
              <AdminLayout>
                <Documents />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/doc/trainings/documents/presentation"
            element={
              <AdminLayout>
                <PPTX />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/files"
            element={
              <AdminLayout>
                <Files />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/presence"
            element={
              <AdminLayout>
                <PresenceValidade />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/list_trainigs"
            element={
              <AdminLayout>
                <ListTrainings />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/feedback_form/list"
            element={
              <AdminLayout>
                <FeedbackForm />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/trainigs_docs"
            element={
              <AdminLayout>
                <ListTrainingDocs />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/updload_models"
            element={
              <AdminLayout>
                <CategoryUpload />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/updload_docments"
            element={
              <AdminLayout>
                <UploadModelsByDocs />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/user/register"
            element={
              <AdminLayout>
                <UserRegistratition />
              </AdminLayout>
            }
          />

          <Route exact path="/course/validation" element={<VerificationPage />} />
          <Route
            exact
            path="/courses/formation/induction"
            element={<Stream />}
          />

          <Route
            exact
            path="/courses/formation/induction/DAc2NjM5Mga_5My4wLjAuMAzE1Mga_5My4wLjAuMAzwOC4xD3KS_5My4w*MTy4wLjAuMAzwOC4xD3KS_5My4w*MTI2Njc2NjM5M_5My4w*MTI2y4wLjAuMAzwOC4xD3KS_5My4w*MTI2Njc2NjM5M_5My4w*MTI22NjM5M_5My4w*MTI2Y4Ma*MTI0*MTY4MzEyMzc3Ni4xLjEuMTY4Ma*MTI2Njc2NjM5Mga_5My4w*MTI2Njc2NjM5Mga_5My4wLjAuMAzE1MzEyMzcNga_5LjAuMAzE1MzEyMzcNga_5My4wLjAuMAzE1MzEyMzcNjAuMAzE1MzEyMzcN"
            element={
              <CourseSidebar>
                <CourseDetail />
              </CourseSidebar>
            }
          />

          <Route
            exact
            path="/upload_videos"
            element={
              <AdminLayout>
                <UploadVideoTraining />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/formation/manage"
            element={
              <AdminLayout>
                <FormationManager />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/formation/on_demand"
            element={
              <AdminLayout>
                <TrainingOnDemandPage />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/training/manage/edit"
            element={
              <AdminLayout>
                <TrainingEditManager />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/manage/email/sent_emails"
            element={
              <AdminLayout>
                <ReceivedEmails />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/acess_control"
            element={
              <AdminLayout>
                <ControlAccess />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/forms/control"
            element={
              <AdminLayout>
                <FormsControl />
              </AdminLayout>
            }
          />

          <Route
            exact
            path="/forms/lna/main"
            element={
              <AdminLayout>
                <LNAMain />
              </AdminLayout>
            }
          />
          
        </Routes>
    </ConfigProvider>
  );
}

export default App;
