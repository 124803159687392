import React, { createContext, useState } from "react";

export const AuthContext = createContext({})
export default function AuthProvider({children}){
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const controlContext = 'Context is ranning'
    // set global is loading here
    console.log("Set user in context ", user)
    return(
        <AuthContext.Provider value={{controlContext, user, setUser, loading, setLoading}}>
            {children}
        </AuthContext.Provider>
    )

}