import { Table } from "antd";
import { useState } from "react";

export default function CommentFeedbackTable({tableData, totalPage, loading}) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 3,
          total: tableData.lenght,
        },
      });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });}
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const columns = [
        {
          title: 'Sugestions',
          dataIndex: 'sugestions',
        },
    ];
    const data = [];
    tableData.map((value) => data.push({ 
        key: value.id,
        sugestions: `${value.sugestion}`,
      }))
   
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };
        
        return (
          <div style={{ height: '200px', overflowY: 'auto'}}>
          <Table
            size='small'
            // pagination={tableParams.pagination}
            rowSelection={rowSelection}
            columns={columns}
            onChange={handleTableChange}
            loading={loading}
            dataSource={data}
          />
        </div>
        );
      };
