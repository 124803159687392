import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { baseurl } from "../../../lib/settings";

const { Option } = Select;

const SearchSelectField = ({ formations }) => {
  const [formation, setFormation] = useState([]);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    setIsloading(true);
    baseurl.get("v1/get_formation/").then((resp) => {
      setFormation(resp.data);
      setIsloading(false);
    }).catch((err) => {
      setIsloading(false);
      console.log("Error on get treaining list ", err)
    });
    
  }, []);
  const onHandleSchange = (value) => {
    console.log(value);
    formations(value);
  };
  return (
    <Select
      loading={isloading}
      showSearch
      size="large"
      placeholder="Search here"
      optionFilterProp="children"
      onChange={(value) => onHandleSchange(value)}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {formation.map((item) => {
        return <Option value={item.id}>{item.description}</Option>;
      })}
    </Select>
  );
};

export default SearchSelectField;
