import { Col, Row, Typography, Avatar, Card, Skeleton, Switch, Button } from "antd";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import UploadDataFile from "../../components/Upload/Custom/upload";
import { baseurl } from "../../lib/settings";
import { CustomMessageAlert } from "../../components/Alerts/custom";
import { UPDLOAD_LNA_DOC_DESC, UPDLOAD_LNA_DOC_SUCCESS_DESC } from "../../utils/constants";

const { Meta } = Card;

export default function UploadLNAData() {
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false)
    const [info, setInfo] = useState(false);


    const sendLnaFile = () => {
        setInfoMessage(UPDLOAD_LNA_DOC_DESC);
        setInfo(true)
        setIsLoading(true);
        const formData = new FormData();
        formData.append("excel_file", fileData);
        formData.append("file_type", fileData.type);
        formData.append("data_type", '3');
        baseurl.post("v1/load_data_file/", formData).then((resp) => {
            setMessage("Data uploaded successfully", resp.data);
            setInfoMessage(UPDLOAD_LNA_DOC_SUCCESS_DESC);
            setError(false);
            setInfo(false)
            setSuccess(true);
            setIsLoading(false);
        }).catch((err) => {
            console.log("err", err);
            setError(true);
            setSuccess(false);
            setIsLoading(false);
            if (err.response.status !== 400){
                setMessage(err.response.data.message);
            }else{
                setMessage('Unable to load your data');
            }
            console.log("Have error hera", err.response);
            // setLoading(false);
        });

    }
    return (
        <Row gutter={24}>
            <Col style={{
                textAlign:'-webkit-center'
            }} xs={24} md={24}>
                <div style={{
                    width:'40%',
                    marginTop:10
                    // alignContent:'center',
                    // alignItems:'center',
                    // textAlign:''
                    }}>
                    <Typography style={{ textAlign:'center' }} >Upload Area</Typography>
                <CustomMessageAlert 
                    error={error}
                    info={info}
                    message={infoMessage}
                    success={success} />
                <CustomMessageAlert 
                    error={error}
                    info={false}
                    message={message}
                    success={success} />
                <UploadDataFile loading={isLoading} setFileData={setFileData} />
                {fileData !== null ? <Button
                    block
                    onClick={sendLnaFile}
                    loading={isLoading}
                    type="primary"
                    disabled={isLoading}
                >Submit</Button> : <></>}
                </div> 
            </Col>
            {/* <Col style={{
                // border:'2px solid gray',
            }} xs={24} md={8}>
            <Typography style={{ textAlign:'center' }}>Document Timeline</Typography>
            <div style={{
                border:'2px solid rgba(140, 140, 140, 0.35)',
                borderRadius:'10px'
            }}>
                <Card
                    style={{ height: '65%', width: '90%', marginTop: 16, marginBottom: 8, marginLeft:5, marginRight:5, boxShadow: "0px 0px 0px 5px rgba(208, 216, 243, 0.6)"}}
                    // actions={[
                    // <SettingOutlined key="setting" />,
                    // <EditOutlined key="edit" />,
                    // <EllipsisOutlined key="ellipsis" />,
                    // ]}
                >
                    <Skeleton loading={loading} avatar active>
                    <Meta
                        avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />}
                        title="744 - Feedback_TWIGG.xlsx (versão 0.0.6)"
                        description="Detalhes do dia e a hora de actualizacao"
                    />
                    </Skeleton>
                </Card>
            </div>
            </Col> */}
        </Row>
    )
}