import { Button, Card, Divider, Result, Space, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import { baseurl } from "../../lib/settings";
import { useEffect, useState } from "react";
import { ButtonStyles } from "../static/css/Style/colors";

const { Title, Text } = Typography;

export default function SugettionTrainingBarChart({controlForm, viewControlForm, chartControlFunction, chartControl}){
    const [suggestedTraining, setSuggestedTraining] = useState([])
    const [usersResquest, setUserRequest] = useState([])
    const [dataReload, setDataReload] = useState(false)
    const [filterTraining, setFilterTraining] = useState('')
    const [error, setError] = useState(false);
    const {buttonSyle} = ButtonStyles();
    const data = [];
    var myChart = {}

    useEffect(() => {
        baseurl.get('v1/formation_suggestion/').then((resp) => {
            setSuggestedTraining(resp.data);
            setError(false)
        }).catch((err) => {
            setError(true)
        });
        setDataReload(false);
        chartControlFunction(false);
    },[chartControl])

    const openChart = () => {
        controlForm(false)
    }

    function get_filter_training(training_desc){
      const data = {}
      // if(filterTraining !== training_desc){
      //   baseurl.get('v1/formation_suggestion/', {params:{request_type: 'filter', training:training_desc}}).then((resp) => {
      //     setUserRequest(resp.data);
      //     setFilterTraining(training_desc);
      //     data = resp.data
      //     setError(false)
      //   }).catch((err) => {
      //       setFilterTraining('')
      //       setError(true)
      //   });
      //   console.log("Filter Data", data)
      // }
      
      
    }
   const formationList = suggestedTraining.map((resp) => resp.formation)
   const formationValueList = suggestedTraining.map((resp) => resp.suggestions_count)
    for (let i = 0; i < 5; ++i) {
        data.push(Math.round(Math.random() * 200));
    }
    var app = {}
    app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };

    
    var option = {
      scales: {
          x: {
             ticks: {
               display: false
             }
         }
    },
      tooltip: {
        trigger: 'item',
        responsive: true,
        position: 'top',
        axisPointer: {
          type: 'shadow'
        },
        // formatter: '{c}',
        formatter: (params) => {
          let filtered_data = suggestedTraining.filter((item) => {
            return item.formation.includes(params.name)
        })
          return `
                    <strong>${params.name}</strong>: ${params.data}<br/>
                    ${filtered_data[0]['user'].map((item) => `${item.user_full_name}</strong>: [${item.requested_times}]<br/>`)}`;
        },
        onTooltipMouseOver: (e) => {
          let eventObj = e;
          console.log("{chk ==> ", eventObj)
  },
        backgroundColor: '#fafcfe',
        borderColor: '#c0c0c0',
        // borderWidth: '0.8',
        textStyle: {
            color: '#5a5a5a'
        }
      },
    xAxis: {
        // max: 'dataMax'
        axisLabel: {
          inside: true,
          color: '#fff'
        },
    },
    yAxis: {
        type: 'category',
        position: "left",
        z: 3,
        axisTick: {
            show: false
        },
        
        axisLabel: {
            color: "#000000", //var(--primary-text-color)
            inside: true,
            // ...
            show: true
        },
        nameLocation: "start",
        data: formationList,
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        max: 6 // only the largest 3 bars will be displayed
    },
    series: [
      
        {
            color: '#6699CC',
            realtimeSort: true,
            // name: 'X',
            type: 'bar',
            data: formationValueList,
            // label: labelOption
            label: {
                show: false,
                position: 'right',
                valueAnimation: true
            }
        }
    ],
    legend: {
        show: true
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear'
    };
    // function run() {
    //     for (var i = 0; i < data.length; ++i) {
    //         if (Math.random() > 0.9) {
    //         data[i] += Math.round(Math.random() * 2000);
    //         } else {
    //         data[i] += Math.round(Math.random() * 200);
    //         }
    //     }
    //     myChart.setOption({
    //         series: [
    //         {
    //             type: 'bar',
    //             data
    //         }
    //         ]
    //     });
    // }
    // setTimeout(function () {
    // run();
    // }, 0);
    // setInterval(function () {
    // run();
    // }, 3000);
    
    return (
        <Card style={{
            maxHeight:'500px'
        }} bordered={true} className="criclebox h-full">
            {viewControlForm ? <div className="timeline-box" style={{ alignContent: "center", textAlign: "center", }}>
              <Button onClick={openChart} style={{
                backgroundColor:buttonSyle.defaultColor
              }} block>
                <Title level={5}>Trainings on Demand</Title>
              </Button>
            </div> : <>{error ?  <Result
                        status="warning"
                        title="We were unable to collect your report data."
                        extra={
                        <Button onClick={() => setDataReload(true)} type="primary" key="console">
                            Try again
                        </Button>
                        }
                    /> :<>
                            <h6 className="font-semibold m-0 chart_title">
                                Trainings on demand
                            </h6>
                            <Divider style={{marginBottom:-30}}/>
                                <ReactECharts style={{ width: "100%", height: "400px", marginBottom:10 }} option={option} />
                        </>} </>}
            
            
        </Card>
        
    )
}