import React from "react";
import { Divider } from "antd";
function Footer() {
  var date = new Date();
  return (
    <>
      <Divider />
      <>
        All rights reserved &copy; SPERANZA LDA {date.getFullYear()} <br />
        <br />
      </>
    </>
  );
}

export default Footer;
