import React, { useEffect, useState } from 'react';
import { Modal, Button, Layout, Form,Checkbox, Typography, Divider } from 'antd';
import Question from '../quiz/Question';
import { baseurl } from '../../../lib/settings';
// import "./modal.css"

const { Content } = Layout;
const { Title } = Typography;



const CustomModal = ({ visible, onClose, selectedQuizData }) => {

  const [formData, setFormData] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleFormChange = (changedValues) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...changedValues }));
  };

  const handleSubmit = () => {
    setIsLoading(true)
    console.log(selectedValues);

    baseurl.post('v1/quiz/training_questionnaire_answer/', {answers:selectedValues}).then((resp) => {
      setIsLoading(false)
    }).catch((err) =>{
      setIsLoading(false)
      console.log("Error on save questions")
    })
  };

  const handleQuestionChange = (value) => {
    console.log("Chancg value ", value)
    setSelectedValues([...selectedValues, value])
    // setSelectedValues((prevSelectedValues) => ({
    //   ...prevSelectedValues, value,
    // }));
  };

  const handleCheckBoxChange = (e) => {
    setCheckBoxChecked(e.target.checked);
    setSubmitButtonDisabled(!e.target.checked);
  };

  let questionOrder = 1;

  return (
   <>
    <Modal
      title={selectedQuizData.length > 0 ? selectedQuizData[0].quiz.description : "Loading..."}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      centered
      width="100%"
      bodyStyle={{ minHeight: '90vh', overflowY: 'auto' }}
    >
<Layout style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
      <div style={{ width: "50%"}}>
       <Content style={{ display: 'flex', width: "100%", minHeight: '50vh' }}>
         <Form
          style={{ width: "100%",}}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          onChange={handleFormChange}
        >
          <Title style={{marginTop: "20px"}} level={3}>
            <br />
          </Title>
          {selectedQuizData.length > 0 ? selectedQuizData.map((question, index) => (
            <Question
              // index={question.id}
              key={question}
              index={index+1}
              question={question.question_name}
              options={[question.first_option, question.second_option, question.third_option, question.fourth_option]}
              value={question.question1}
              onChange={(e) => {
                // handleFormChange({ answer: e.target.value, question_id:question.id });
                // console.log("Control value change", { answer: e, question_id:question.id })
                var value = { answer: e, question_id:question.id }
                handleQuestionChange(value);
              }}
              courseCode="1 Point" 
          />
          )): <>Loading questions...</>}
        <Divider orientation='left'><strong>Honor Code</strong></Divider>
        <Form.Item>
        <Checkbox checked={checkBoxChecked} onChange={handleCheckBoxChange}>
            I<strong></strong> understand that submiting work that isn't my own, may result in permanent failure of this course or deactivation of my account.
            </Checkbox>
        </Form.Item>
          <Form.Item >
            <Button type="primary" onClick={handleSubmit} disabled={!checkBoxChecked}>
              Submit answers
            </Button>
          </Form.Item>
        </Form>
      </Content>
      </div>
    </Layout>
    </Modal>
   </>
  );
};

export default CustomModal;
