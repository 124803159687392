import ReactECharts from "echarts-for-react";
import { baseurl } from "../../lib/settings";
import { useEffect, useState } from "react";

export default function ManHourTrainingBarChart(){
    const [trainingData, setTrainingData] = useState([]);
    useEffect(() => {
        baseurl.get('v1/man_hour_training/').then((resp) => {
            setTrainingData(resp.data)
            console.log("Done getting man_hour_training")
        }).catch((err) => {
            console.log("Error on getting man_hour_training", err)
        })
    }, [])
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
        xAxis: {
          type: 'category',
          data: trainingData.map((item) => item.training_desc)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: trainingData.map((item) => item.total_training_hour_per_men),
            type: 'bar'
          }
        ]
      };
    return (
        <ReactECharts style={{ width: "100%", height: "450px" }} option={option} />
    )
}