import React, { useState } from "react";
import { Modal, Form, Input, Radio, DatePicker, TimePicker, Space, Col, Row } from "antd";
import moment from "moment";

// import "antd/dist/antd.css";
import 'antd/dist/reset.css';
import SearchSelectField from "../components/select_search_field";
import { TRAINING_CAPACITY_NUMBER_ERROR, TRAINING_CAPACITY_NUMBER_WARNING } from "../../../utils/constants";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

const CreateForm = (props) => {
  const { visible, setVisible, onCreate, onCancel, date, training, loading } =
    props;
  const [form] = Form.useForm();
  const dates = date;
  const [formation, setFormation] = useState();
  const [rules, setRules] = useState(false);

  const handleCreate = () => {
    form.validateFields().then((values) => {

      const startTime = values.start_time && values.start_time.format(timeFormat);
      const endTime = values.end_time && values.end_time.format(timeFormat);
        
      const updatedValues = {
        ...values,
        start_time: startTime,
        end_time: endTime,
      };

      console.log("DATA: ", updatedValues)

        form.resetFields();
        onCreate(updatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <Modal
      visible={visible}
      title="Training schedule"
      okText="Create"
      onCancel={() => {
        setVisible(false);
        onCancel(false);
      }}
      onOk={handleCreate}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Select Training" name="training" >
          <SearchSelectField
            formations={(value) => {
              setFormation(value);
              training(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Capacity"
          name="capacity"
          rules={[
            {
              required: true,
              message: TRAINING_CAPACITY_NUMBER_WARNING,
            },
            {
              validator(_, value) {
                if (!value || value < 25) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    TRAINING_CAPACITY_NUMBER_ERROR
                  );
                }
              },
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="date" label="Start Date - End Date" rules={[{required: true,}]}>
          <RangePicker onChange={(value) => date(value)} style={{width: "100%",}} />
        </Form.Item>

        <Row gutter={[24, 0]} style={{}}>
          <Col span={12} >
          <Form.Item label="Start Time" name="start_time" rules={[{required: true,}]}>
          <TimePicker format={timeFormat} style={{width: "98%",}} />
        </Form.Item>
          </Col>
          <Col span={12}  >
          <Form.Item label="End Time" name="end_time" style={{width: "100%",}} rules={[{required: true,}]}>
          <TimePicker format={timeFormat} style={{width: "100%",}} />
        </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateForm;
