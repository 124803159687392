import { Avatar, Card, List, Col, Row, ConfigProvider, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import info from "../components/assets/icons/icons8-info.gif";
import book from "../components/assets/icons/icons8-book-30.png";
import calendar from "../components/assets/icons/icons8-calendar-50.png";
import team from "../components/assets/icons/icons8-team-64.png";
import TwinggInfo from "../components/cards/twigg_info";
import Calendare from "../components/calendare/calendare";
import ParticipantNumberChart from "../components/charts/participant_number_bar_chart";
import "../App.css";
import LineChart from "../components/charts/line_chart";
import DonutChart from "../components/charts/donut_chart";
import Footer from "../components/footer/footer";
import BarRace from "../components/charts/bar_race";
import SuggestTraining from "../components/cards/training_suggestion";
import SugettionTrainingBarChart from "../components/charts/suggestion_training_bar_chart";
import { useState } from "react";
import ManHourTrainingBarChart from "../components/charts/bar_chart_man_hour_training";

export default function Dashboard() {
  const [generalFormOpenedControl, setGeneralFormOpenedControl] = useState(false);
  const [chartControl, setChartContro] = useState(false);
  return (
    <>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            {/* <Card bordered={false} className="criclebox h-full"> */}
              <TwinggInfo />
              {/* <hr style={{margin: "5px 0"}}/> */}
              <br />
              <Card>
                <Calendare sugettionTrainingBarChartControl={setChartContro} />
              {/* </Card> */}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card style={{
              maxHeight:'800px'
              }} bordered={false} className="criclebox h-full">
              <Space style={{width:'100%'}} direction="vertical" size={10}>
                <SuggestTraining 
                  chartControlFunction = {setChartContro}
                  viewControlForm={generalFormOpenedControl} 
                  controlForm={setGeneralFormOpenedControl} />
                <SugettionTrainingBarChart 
                  chartControlFunction = {setChartContro}
                  chartControl = {chartControl}
                  viewControlForm={generalFormOpenedControl} 
                  controlForm={setGeneralFormOpenedControl}/>
              </Space>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h6 className="font-semibold m-0 chart_title">
                Total Participant by Month
              </h6>
              <br />
              <ParticipantNumberChart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h6 className="font-semibold m-0 chart_title">
                Total of Trainings by Month
              </h6>
              <br />
              <BarRace />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h6 className="font-semibold m-0 chart_title">
                Gender Distribution
              </h6>
              <DonutChart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h6 className="font-semibold m-0 chart_title">
                Man Hour Training
              </h6>
              <ManHourTrainingBarChart />
              {/* <LineChart /> */}
            </Card>
          </Col>
        </Row>
        <Footer />
    </>
  );
}
