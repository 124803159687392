import {DatePicker } from "antd";
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import moment from "moment";
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';
export default function CustomDatepiker({initDate, endDate, onChange, dateFormat}){
    // const [interInitDate, setInterInitDate] = useState()
    // const [interEndDate, setInterEndDate] = useState()
    // const current = new Date();
    // useEffect(() =>{
    //     setInterEndDate(dayjs(moment(new Date().toDateString()).format(dateFormat).toString(), dateFormat))
    // })
    
    // console.log("My date", moment(current.toDateString()).format(dateFormat))
    console.log("My date", initDate)
    console.log("My date2", endDate)
    return (
        <RangePicker
            onChange={(e) => onChange(e)}
            defaultValue={[initDate, endDate]}
            format={dateFormat}
        />
    )
}

// function getInintDate(){
//     const current = new Date();
    
//     var day = current.getDate()
//     console.log("This is the current day", day)
    
//     if (day == 1){
//         var month = current.getMonth() - 1
//         console.log("This is the Old Moth", month)
//         current = current.getMonth()-1
//     }

//     day = day - 1

//     const final = current.getDate()-day
//     console.log("Final date ", final)
//     return 
// }
