import { Col } from "antd";
import ReactECharts from "echarts-for-react";

export default function PieChartFeedback({feedback}) {

    
    var  option = {
        // title: { 
        //   text: 'Overall Feedback',
        // //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        // legend: {
        //   bottom: 10,
        //   left: 'center',
        //   data: ['CityA', 'CityB', 'CityD', 'CityC', 'CityE']
        // },
        series: [
          {
            
            type: 'pie',
            radius: '65%',
            label: {position: 'inside', formatter: '{d}%',  fontSize:18},
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: [
              { value: feedback.desliked, name: 'Desliked', itemStyle: {
                // borderColor: 'transparent',
                color: '#cb2229'
              },},
              { value: feedback.liked, name: 'Liked', itemStyle: {
                // borderColor: 'transparent',
                color: '#5470c6'
              },}, 
            ],
            emphasis: {
              label: {show: true},
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

    return (
        <Col span={24} md={10} className="mb-24">
            <div style={{
                padding: '15px',
                border:'2px solid rgba(140, 140, 140, 0.35)',
                
                borderRadius:'10px'
                }}>
                    <h6 className="font-semibold m-0 chart_title">
                      Overall Feedback
                    </h6>
                    <ReactECharts style={{ width: "100%", height: "310px" }} option={option} />
            </div>
        </Col>
    )
}