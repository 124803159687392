import { CloseOutlined, EditOutlined, } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";


export default function EdidFormQuestion({question_name, close, edit, questionIndex}) {
    return (
        <Col span={21}> 
            <div 
                style={{
                    alignItems:'center',
                    // background: '#0092ff',   
                    padding: '3px',
                    borderWidth:1,
                    borderStyle:"groove",
                    borderColor:"#90EE90",
                    textAlign:"center",
                }}>
                    <Row gutter={[2, 24]}>
                    <Col span={3}>
                        <Button className="button-align-text">P{questionIndex+1}</Button>
                    
                    </Col>
                    <Col span={17}>
                        <Input placeholder={question_name} disabled/> 
                        
                    </Col>
                    <Col span={2}>
                        <Button
                            type="dashed"
                            onClick={() => edit()}
                            icon={<EditOutlined />}
                        />
                    </Col>
                    <Col span={2}>
                        <Button
                            onClick={() => close()}
                            icon={<CloseOutlined />}
                        />
                    </Col>
                    </Row>
            </div>
        </Col>
    )
}