import { Card, Divider } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import "./coursedetails.css";

function CourseDetail() {
  const location = useLocation();
  if (!location) {
    return null;
  }

  return (
    <div className="main">
      <div>
        <h2>{location.state.title}</h2>
        <p>{location.state.description}</p>
        <iframe
          src={location.state.videoUrl}
          title={location.state.title}
          allowFullScreen
          height={"500px"}
          width={"600px"}
        />
      </div>
    </div>
  );
}

export default CourseDetail;
