import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Col,
  Row,
  Button,
  Typography,
  Radio,
  Table,
  Popconfirm,
  Badge,
  Space,
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import userIcon from "../../components/assets/icons/user.png";
import UserAcessForm from "../../components/AcessControl/user_acess";
import { baseurl } from "../../lib/settings";
import {
  GenericSuccessMessage,
  SomeThingErrorMessage,
} from "../../components/Alerts/genericAlert";
import { userAccountsColumns, userPermissionAccountsColumns } from "./utils/constants";
import { CustomMessageAlert } from "../../components/Alerts/custom";
import { validateUserPermission } from "./utils/validate_permission";
import { ADMIN_PERMISSION, MANAGER_PERMISSION } from "../../utils/constants";
import UserPermissionForm from "../../components/AcessControl/user_permission";


const { Title } = Typography;

export default function ControlAccess() {
  const [formControl, setFormControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userTableLoading, setUserTabeleLoading] = useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);
  const [permissionArea, setPermissionArea] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [visitorLoading, setVisitorLoading] = useState(false);
  const [visitorControl, setVisitorControl] = useState(false);
  const [userAccountControl, setUserAccountsControl] = useState(false);
  const [showVisitorTable, setShowVisitorTable] = useState(true);
  const [xlLeyout, setXlLeyout] = useState(24);
  const [visitors, setVisitors] = useState([]);
  const [userAccounts, setUserAccounts] = useState([]);
  const [userPermissionAccounts, setUserPermissionAccounts] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [showUserPermissionForm, setShowUserPermissionForm] = useState(false);
  const [userCalendarPermission, setUserCalendarPermission] = useState("vew");
  const [userPlatformPermission, setUserPlatformPermission] = useState("supervisor");
  const [userList, setUserList] = useState([]);




  const [tableParamsVisitors, setTableParamsVisitors] = useState({
    pagination: {
      current: 1,
      pageSize: 6,
      total: visitors.lenght,
    },
  });
  const [tableParamsUserAccount, setTableParamsUserAccount] = useState({
    pagination: {
      current: 1,
      pageSize: 6,
      total: userAccounts.lenght,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setUserAccountsControl(true)
    baseurl
      .get("v1/visitors/")
      .then((resp) => {
        setVisitors(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        CustomMessageAlert('Sorry, unable to load visitors!');
        setIsLoading(false);
      });
      getUserDetails();
    setVisitorControl(false);
  }, [visitorControl]);

  function getUserDetails(){
    setUserTabeleLoading(true);
    baseurl.post('v1/acesss/users/').then((resp) => {
      createUserData(resp.data);
      createUserPermissionData(resp.data);
      setUserAccountsControl(false);
      setUserTabeleLoading(false);
    }).catch (e => {
      setUserAccountsControl(false);
      setUserTabeleLoading(false);
      CustomMessageAlert('Sorry, unable to load user accounts!');
    })
    
  }


  const handleVisitorsTableChange = (pagination, filters, sorter) => {
    setTableParamsVisitors({
      pagination,
      filters,
      ...sorter,
    });}

    const handleUserAccountTableChange = (pagination, filters, sorter) => {
      setTableParamsUserAccount({
        pagination,
        filters,
        ...sorter,
    });}

  const validateVisitorCarrier = (contact) => {
    if (contact) {
      if (contact.slice(0, 5) === "25886") {
        return "Movitel";
      } else if (contact.slice(0, 5) === "25887") {
        return "Movitel";
      } else if (contact.slice(0, 5) === "25884") {
        return "Vodacom";
      } else if (contact.slice(0, 5) === "25885") {
        return "Vodacom";
      } else if (contact.slice(0, 5) === "25882") {
        return "TMCEL";
      } else if (contact.slice(0, 5) === "25883") {
        return "TMCEL";
      }
    }
    return "Unavailable";
  };

  const formatContact = (contact) => {
    if (contact) {
      const prefix = contact.slice(0, 5);
      const sufix = contact.slice(-3);
      const hiddenDigits = contact.slice(5, -3).replace(/\d/g, "*");
      return `${prefix}${hiddenDigits}${sufix}`;
    }
    return "Not found";
  };

  const showForm = () => {
    if (formControl === true) {
      setFormControl(false);
      setXlLeyout(24);
    } else {
      setFormControl(true);
      setXlLeyout(16);
    }
  };

  const resendOTP = (values) => {
    setVisitorLoading(true);
    setIsLoading(true);
    const data = {
      full_name: values.user.full_name,
      email: values.user.email,
      cell_number: "258" + values.user.contact,
      option: "sms",
      on_existe: "keep",
    };
    console.log("data: ", data);

    baseurl.post("v1/otp/", data).then((resp) => {
        GenericSuccessMessage(resp.data["message"]);
        setVisitorLoading(false);
        setIsLoading(false);
        setVisitorControl(true);
      })
      .catch((error) => {
        SomeThingErrorMessage("An error ocurred");
        setVisitorLoading(false);
        setIsLoading(false);
        setVisitorControl(true);
      });
    setIsLoading(false);
  };

  const deleteVisitor = (visitor) => {
    setVisitorLoading(true);
    setIsLoading(true);
    baseurl
      .post("v1/visitors/", { user_id: visitor })
      .then((resp) => {
        GenericSuccessMessage(resp.data["message"]);
        setVisitorLoading(false);
        setIsLoading(false);
        setVisitorControl(true);
      })
      .catch((e) => {
        SomeThingErrorMessage("An error ocurred");
        setVisitorLoading(false);
        setIsLoading(false);
        setVisitorControl(true);
      });
     
  };

  const data = visitors.map((visitor) => ({
    key: visitor.user.id,
    name: (
      <>
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={userIcon}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>{visitor.user.full_name}</Title>
            <p>{visitor.user.email}</p>
          </div>
        </Avatar.Group>{" "}
      </>
    ),
    contact: (
      <>
        <div className="author-info">
          <Title level={5}>
            {validateVisitorCarrier(visitor.user.contact)}
          </Title>
          <p>{formatContact(visitor.user.contact)}</p>
        </div>
      </>
    ),
    r_date: (
      <>
        <div className="ant-employed">
          <span>{moment(visitor.created_at).format("DD/MM/Y")}</span>
          {/* <a href="#pablo">Edit</a> */}
        </div>
      </>
    ),
    action: (
      <>
        <Popconfirm
          title="Are you sure to delete this visitor?"
          description="Delete the visitor"
          okText="Yes"
          onConfirm={() => deleteVisitor(visitor.user.id)}
        >
          <Button type="dashed" className="tag-primary">
            <DeleteOutlined style={{ fontSize: "17px" }} />
          </Button>
        </Popconfirm>
      </>
    ),
    resend: (
      <>
        <Popconfirm
          title="Are you to resend the otp code?"
          description="resend OTP code"
          okText="Yes"
          onConfirm={() => resendOTP(visitor)}
        >
          <Button type="dashed">
            <ReloadOutlined style={{ fontSize: "17px" }} />
          </Button>
        </Popconfirm>
      </>
    ),
  }));


  

  const validateTableChangue = (e) => {
    const tab = e.target.value;
    setUserAccountsControl(true);
    switch (tab) {
      case 'visitors':
        setShowVisitorTable(true);
        setShowUsers(false);
        setPermissionArea(false);
        break;
      case 'users':
        setShowVisitorTable(false);
        setPermissionArea(false);
        setShowUsers(true);
          if(userAccounts.length === 0){
            
            baseurl.post('v1/acesss/users/').then((resp) => {
              createUserData(resp.data)
              createUserPermissionData(resp.data);
              setUserAccountsControl(false);
            }).catch (e => {
              setVisitorControl(false);
              CustomMessageAlert('Sorry, unable to load user accounts!');
            })
          }
        break;
      case 'permission':
        setShowVisitorTable(false);
        setShowUsers(false);
        setPermissionArea(true);
        break;
    
      default:
        CustomMessageAlert('Tab not found!');
        break;
    }
    
    setUserAccountsControl(false);
  }

  const createUserData = (users) => {
    const data = users.map((user) => ({
      key: user.user.id,
      name: (
        <>
          <Avatar.Group>
             <Space size='large'>
             <Badge dot color={user.is_online ? "green" : "grey"}>
          <Avatar src={userIcon} shape="square" size="large" />
        </Badge>
            <div className="avatar-info">
              <Title level={5}>{user.user.full_name}</Title>
              <p><strong>{user.user.email}</strong></p>
            </div>
             </Space>
          </Avatar.Group>{" "}
        </>
      ),
      contact: (
        <>
          <div className="author-info">
            <Title level={5}>
              {validateVisitorCarrier(user.user.contact)}
            </Title>
            <p><strong>{formatContact(user.user.contact)}</strong></p>
          </div>
        </>
      ),
      permission: (
        <>
          <div className="author-info">
            <p><strong>{validateUserPermission(user.user.web_permission)}</strong></p>
          </div>
        </>
      ),
     
      last_seen: (
        <>
          <div className="ant-employed">
            <span><strong>{ user.last_seen == null ? 'Never accessed' : moment(user.last_seen).format("DD/MM/Y - HH:mm")}</strong></span>
            {/* <a href="#pablo">Edit</a> */}
          </div>
        </>
      ),
      
    }));

    setUserAccounts(data)
  }


  const createUserPermissionData = (users) => {
    setUserList(users)
    const data = users.map((user) => ({
      key: user.user.id,
      name: (
        <>
          <Avatar.Group>
             <Space size='large'>
             <Badge dot color={user.is_online ? "green" : "grey"}>
          <Avatar src={userIcon} shape="square" size="large" />
        </Badge>
            <div className="avatar-info">
              <Title level={5}>{user.user.full_name}</Title>
              <p><strong>{user.user.email}</strong></p>
            </div>
             </Space>
          </Avatar.Group>{" "}
        </>
      ),
      contact: (
        <>
          <div className="author-info">
            <Title level={5}>
              {validateVisitorCarrier(user.user.contact)}
            </Title>
            <p><strong>{formatContact(user.user.contact)}</strong></p>
          </div>
        </>
      ),
      platform_permission: (
        <>
          <div className="author-info">
            <p><strong>{validateUserPermission(user.user.web_permission)}</strong></p>
          </div>
        </>
      ),
     
      calendar_permission: (
        <>
          <div className="ant-employed">
            <span><strong>{ user.user.calendar_permission != MANAGER_PERMISSION ? <p>View</p> : <p>View and edit</p>}</strong></span>
            {/* <a href="#pablo">Edit</a> */}
          </div>
        </>
      ),
      
    }));

    setUserPermissionAccounts(data)
  }


  async function getCalendarUserPermission(user)  {
    let userPermission = false;
    if (MANAGER_PERMISSION == user.calendar_permission){
      return true
    }
  return userPermission
  }

  
    async function getUserPermissions(user){
        let permission = 'vew'
        setUserPlatformPermission(user.web_permission == ADMIN_PERMISSION ? ADMIN_PERMISSION : "supervisor");
        setUserCalendarPermission(user.calendar_permission);
        // user.list_permissions.map((per, index) => {
        //     if (per.description == MANAGER_PERMISSION){
        //         setUserCalendarPermission(MANAGER_PERMISSION);
                
                
        //     }
        // });
        
        setSelectedUser(user)
            
        console.log("Validate user", user.calendar_permission)
        console.log("Validate user 2", user.calendar_permission)
        setShowUserPermissionForm(true);
         
        
        return permission
    }

  
  

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={xlLeyout} className="mb-24">
          <div className="tabled">
            <Row gutter={[24, 0]} className="ant-row-flex ant-row-flex-middle">
              <Col xs={24} md={12}>
                <h6 className="font-semibold m-0"></h6>
              </Col>
              <Col xs={24} md={12} className="d-flex">
                <Button onClick={showForm} type="primary">
                  {formControl ? <>Colapse</> : <>Give new access</>}
                </Button>
              </Col>
            </Row>
            <br />
            <Card
              bordered={false}
              // className="criclebox tablespace mb-24"
              title="Access Control"
              extra={
                <>
                  <Radio.Group defaultValue="a" onChange={(e) => validateTableChangue(e)}>
                    <Radio.Button value="visitors">Visitors</Radio.Button>
                    <Radio.Button value="users">Users</Radio.Button>
                    <Radio.Button value="permission">Permissions</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              {showVisitorTable && <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Table
                  loading={isLoading}
                  columns={visitorsColumns}
                  dataSource={data}
                  pagination={tableParamsVisitors.pagination}
                  onChange={handleVisitorsTableChange}
                  className="ant-border-space"
                />
              </div>}
              {showUsers && (
                <div className="table-responsive">
                <Table
                  loading={userAccountControl}
                  columns={userAccountsColumns}
                  dataSource={userAccounts}
                  pagination={tableParamsUserAccount.pagination}
                  onChange={handleUserAccountTableChange}
                  className="ant-border-space"
                />
              </div>
              )}
              {permissionArea && (
                <div className="table-responsive">
                <Table
                  loading={userTableLoading}
                  columns={userPermissionAccountsColumns}
                  dataSource={userPermissionAccounts}
                  // pagination={tableParamsUserAccount.pagination}
                  onChange={handleUserAccountTableChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => { 
                        getUserPermissions(userList[rowIndex].user)
                        setUserPlatformPermission(userList[rowIndex].user.web_permission == ADMIN_PERMISSION ? ADMIN_PERMISSION : "supervisor");
                        userList[rowIndex].user.list_permissions.map((per, index) => {
                            if (per == MANAGER_PERMISSION){
                                setUserCalendarPermission(MANAGER_PERMISSION);
                                
                                
                            }
                        });
                        
                        setSelectedUser(userList[rowIndex].user)
                            
                        console.log("Validate user", userList[rowIndex].user.web_permission == ADMIN_PERMISSION ? ADMIN_PERMISSION : "supervisor")
                        console.log("Validate user 2", userPlatformPermission)
                        setShowUserPermissionForm(true);
                       },
                    };
                  }}
                  className="ant-border-space"
                />
              </div>
              )}
              
            </Card>
          </div>
        </Col>
        {!permissionArea && formControl && (
          <Col span={24} md={8} className="mb-24">
            <UserAcessForm visitorControl={setVisitorControl} />
          </Col>
        )}
        {permissionArea && (
          <UserPermissionForm 
          visible={showUserPermissionForm}
          setVisible={setShowUserPermissionForm}
          user={selectedUser}
          calendarPermission={userCalendarPermission}
          platformPermission={userPlatformPermission}
          setCalendarPermission={setUserCalendarPermission}
          setPlatformPermission={setUserPlatformPermission}
          isLoading={isPermissionLoading}
          setIsLoading={setIsPermissionLoading}
          func={getUserDetails}
          />
        )}
      </Row>
    </>
  );

  
}

const visitorsColumns = [
  {
    title: "Visitor",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Registration date",
    key: "r_date",
    dataIndex: "r_date",
  },
  {
    title: "Delete",
    key: "action",
    dataIndex: "action",
  },
  {
    title: "Resend",
    key: "resend",
    dataIndex: "resend",
  },
];