import React, { useState } from "react";
import CreateForm from "./modal_form";

/**
 * @param {function} onChange when change occurs
 */
export const CollectionsPage2 = ({
  onChange,
  onCancel,
  date,
  trainingID,
  loading,
}) => {
  const [visible, setVisible] = useState(true);

  const onCreate = (values) => {
    onChange(values);
    setVisible(false);
  };
  const onCancell = (values) => {
    onCancel(values);
    setVisible(values);
    console.log("In OnCansel");
  };
  const setDate = (values) => {
    date(values);
    console.log("In OnCansel", values);
  };

  return (
    <div>
      <CreateForm
        visible={visible}
        setVisible={setVisible}
        onCreate={onCreate}
        onCancel={onCancell}
        date={setDate}
        training={(value) => {
          trainingID(value);
        }}
        loading={loading}
      />
    </div>
  );
};
