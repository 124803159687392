import { LoadingOutlined } from '@ant-design/icons';
// import './custom_spin.css'
import { Spin, Typography } from 'antd';



export function CustomLoad(){
    return (
        <div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export function DefaultSpinLoading(){
    const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    return <Spin indicator={antIcon} />
}

export function DescriptionSpinLoading({description, size=25}){
    const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
    return (
        <div style={{
            alignItems:'center',
            alignContent:'center',
            textAlign:'center'
        }}>
            <Spin indicator={antIcon} />
            <Typography color="grey">{description}</Typography>
        </div>
    )
}