import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { baseurl } from "../../lib/settings";

export default function BarRace() {
  const [dataTraining, setDataTraining] = useState([]);
  useEffect(() => {
    baseurl.get("v1/training_by_month").then((resp) => {
      setDataTraining(resp.data);
    });
  }, []);

  function processDataChart(trainingData) {
    trainingData.map((training) => {
      if (training.date === "Jan") {
        Jan = training.total_training;
      }
      if (training.date === "Feb") {
        Feb = training.total_training;
      }
      if (training.date === "Mar") {
        Mar = training.total_training;
      }
      if (training.date === "Apr") {
        Apr = training.total_training;
      }
      if (training.date === "May") {
        May = training.total_training;
      }
      if (training.date === "Jun") {
        Jun = training.total_training;
      }
      if (training.date === "Jul") {
        Jul = training.total_training;
      }
      if (training.date === "Aug") {
        Aug = training.total_training;
      }
      if (training.date === "Sep") {
        Sept = training.total_training;
      }
      if (training.date === "Oct") {
        Oct = training.total_training;
      }
      if (training.date === "Nov") {
        Nov = training.total_training;
      }
      if (training.date === "Dec") {
        Dec = training.total_training;
      }
    });
    console.log(`Chart data ${[Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sept, Oct, Nov, Dec]}`)
    return [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sept, Oct, Nov, Dec];
  }
  var option;
  var Jan = 0,
    Feb = 0,
    Mar = 0,
    Apr = 0,
    May = 0,
    Jun = 0,
    Jul = 0,
    Aug = 0,
    Sept = 0,
    Oct = 0,
    Nov = 0,
    Dec = 0;
  const data = [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sept, Oct, Nov, Dec];

  option = {
    xAxis: {
      max: "dataMax",
    },
    yAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      inverse: true,
      animationDuration: 300,
      animationDurationUpdate: 300,
      max: 11,
    },
    series: [
      {
        realtimeSort: false,
        name: "X",
        type: "bar",
        data: processDataChart(dataTraining),
        label: {
          show: true,
          position: "right",
          valueAnimation: true,
        },
      },
    ],
    legend: {
      show: false,
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: "linear",
    animationEasingUpdate: "linear",
  };
  function run() {
    for (var i = 0; i < data.length; ++i) {
      if (Math.random() > 0.9) {
        data[i] += Math.round(Math.random() * 2000);
      } else {
        data[i] += Math.round(Math.random() * 200);
      }
    }
  }

  return (
    <ReactECharts style={{ width: "100%", height: "500px" }} option={option} />
  );
}
