import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import doc from "../components/assets/images/documents/WorkAtHeight.jpg";
import iconPLAY from "../components/assets/icons/play.png";
import iconPDF from "../components/assets/icons/pdf.png";
import iconPPTX from "../components/assets/icons/powerpoint.png";
import downloads from "../components/assets/images/documents/downloads.png";
import "./size.css";
import {
  List,
  Divider,
  Avatar,
  Space,
  Col,
  Row,
  Card,
  Skeleton,
  Button,
  Popover,
} from "antd";
import {
  MessageOutlined,
  LikeOutlined,
  StarOutlined,
  FilePdfTwoTone,
  DownloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { baseurl } from "../lib/settings";
import ExamplePDFViewer from "./pdfViewer";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import PPT from "./pptx";
import { useNavigate } from "react-router-dom";
import DonutChart from "../components/charts/donut_chart";
import Footer from "../components/footer/footer";

const { Meta } = Card;
const listData = [];
for (let i = 1; i < 23; i++) {
  listData.push({
    href: "https://ant.design",
    title: `Training ${i} title`,
    avatar: "https://joeschmoe.io/api/v1/random",
    description: "Here you can have a brief description of the training.",
    content: "Here you can have a brief description of the training.",
  });
}

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const props = {
  name: "file",
  headers: {
    authorization: "authorization-text",
  },

  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const style = {
  padding: "8px 0",
};

export default function Documents() {
  const [trainings, setTrainings] = useState([]);
  const [control, setControl] = useState();
  const [isOpen, setOpen] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const navigate = useNavigate();
  var year = new Date();

  useEffect(() => {
    baseurl.get("v1/upload_document_file/").then((resp) => {
      setTrainings(resp.data);
    });
    setControl(false);
  }, [control]);

  function clicarVIDEO(file) {
    var file_url = file.map((url) => url.url).toString();
    setUrlVideo(file_url);
    setOpen(true);
  }

  function clicarPDF(file) {
    var file_url = file.map((url) => url.pdf).toString();
    window.open(file_url);
  }

  function clicarPOWERPOINT(file) {
    navigate("/doc/trainings/documents/presentation", { state: file });
  }

  const content = trainings.map((item) => (
    <div>{item.training.formation.details}</div>
  ));

  function onHover(item) {
    return <>{item.training.formation.details}</>;
  }

  return (
    <div>
      <Divider orientation="left">Documents</Divider>
      <br />
      <Row gutter={[24, 24]}>
        {trainings.map((item) => {
          return (
            <Col span={6}>
              <Card
                hoverable
                bordered={false}
                className="criclebox h-full"
                cover={<img alt="example" src={item.training.formation.img} />}
                actions={[
                  <Avatar
                    shape="square"
                    size={30}
                    src={iconPLAY}
                    onClick={() => clicarVIDEO(item.video)}
                  />,
                  <Avatar
                    shape="square"
                    size={30}
                    src={iconPDF}
                    onClick={() => clicarPDF(item.pdf)}
                  />,
                  <Avatar
                    shape="square"
                    size={30}
                    src={iconPPTX}
                    onClick={() => clicarPOWERPOINT(item.powerpoint)}
                  />,
                ]}
              >
                <Popover content={() => onHover(item)}>
                  <Meta
                    title={<b>{item.training.formation.description}</b>}
                    description={"This is the training description"}
                  />
                </Popover>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Footer />

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={urlVideo}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
