import React, { useState, useEffect } from "react";
import { baseurl } from "../../../../lib/settings";
import CourseListItem from "../../Course/CourseListItem";
import CourseDetail from "../../Course/CourseDetail";
import { Col, Row } from "antd";
import MainPage from "../../MainPage";
import CourseNavbar from "../navbar/CourseNavbar";
import CourseFooter from "../footer/CourseFooter";
import "./side.css";

function CourseSidebar({ children }) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [streaming, setStreaming] = useState({});

  useEffect(() => {
    var data = { code: "04" };
    baseurl.post("v1/streaming/trainig/", data).then((resp) => {
      setStreaming(resp.data);
    });
  }, []);

  const courses = [
    {
      id: 1,
      title: "Full induction",
      description: "Learn how to behave inside the company",
      videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    },
    {
      id: 2,
      title: "Light duty Vehicle",
      description: "Learn how to drive well with all rules",
      videoUrl: "https://www.youtube.com/embed/6jMhMVEjEQg",
    },
  ];

  const courseItems = courses.map((course) => (
    <CourseListItem
      key={course.id}
      course={course}
      onCourseSelect={setSelectedCourse}
    />
  ));

  return (
    <div>
      <CourseNavbar />
      <Row>
        <Col span={5}>
          <div className="sidebar">{courseItems}</div>
        </Col>
        <Col span={12} style={{ height: "500px" }}>
          {children}
        </Col>
      </Row>
      <CourseFooter />
    </div>
  );
}

export default CourseSidebar;
