import React from "react";
import { Layout } from "antd";
import "./footer.css";

const { Footer } = Layout;

const date = new Date();
const year = date.getFullYear();

function CourseFooter() {
  return (
    <div className="footer">
      <Footer>Speranza LDA {year} &copy; all rights reserved.</Footer>
    </div>
  );
}

export default CourseFooter;
