import React, { useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Avatar,
  Menu,
  Dropdown,
  Spin,
  Affix,
  Layout,
  Row,
  Col
} from "antd";
import {
  LoadingOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { PageHeader } from '@ant-design/pro-layout';
import TWIGG from "../../components/assets/icons/TWIGG.png";
import { useNavigate } from "react-router-dom";
import "./nav.css";
import { baseurl } from "../../lib/settings";
import CustomModal from "./modal/modal";

import './NavBar/s_nav.css'
const { Header, Content, Sider } = Layout;

function Navbar() {
  const [userData, setUserData] = useState({});
  const eLearningToken = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const doLogout = () => {
    setIsLoading(true);
    baseurl
      .post("v1/logout-session/")
      .then((resp) => {
        localStorage.removeItem("token");
        window.location.replace("/");
        setIsLoading(false);
      })

      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    baseurl.get("/v1/visitors_details/", eLearningToken).then((resp) => {
      setUserData(resp.data);
    });
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const menu = (
    <Menu>
      <Menu.Item>Resume your last course</Menu.Item>
      <Menu.Item>Completed courses</Menu.Item>
      <Menu.Item onClick={doLogout}>
        Logout <LogoutOutlined />
      </Menu.Item>
    </Menu>
  );

  return (
        <Header
          style={{ 
            display: 'flex', 
            alignItems: 'center',
            background: 'white',
            borderBottom:'solid 1px #e8e8e8',
            boxShadow:'0 0 30px #f3f1f1'
            
          }}
        >
          <div style={{
            display:'flex',
            justifyContent:'space-between',
            width:'100%'
          }} >
            <Col >
              <div >
                <img
                    src={TWIGG}
                    alt="Logo"
                    style={{
                      height: "42px",
                      marginRight: "5px",
                      marginLeft: "20px",
                    }}
                  /> 
              </div>
            </Col>
            <Col>
              {userData.visitor_name ? (
                  <Dropdown overlay={menu} placement="bottomRight">
                    <span>
                      <span style={{ marginRight: "8px" }}>
                        <strong>{userData.visitor_name}</strong>
                      </span>
                      {isLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <Avatar
                          alt={userData.visitor_name}
                          size="64"
                          icon={<UserOutlined />}
                          style={{ backgroundColor: "lightblue" }}
                        />
                      )}
                    </span>
                  </Dropdown>
                ) : (
                  <Spin indicator={antIcon} onClick={doLogout} />
                )}
            </Col>
          </div>
        </Header>
  );
}

export default Navbar;
