import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';
import { DownloadOutlined } from "@ant-design/icons";
import "./size.css";
import {
  Radio,
  Divider,
  Checkbox,
  Space,
  Col,
  Row,
  Card,
  Form,
  Button,
  Table,
  Spin,
} from "antd";
import { useLocation } from "react-router-dom";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { baseurl } from "../lib/settings";
import UploadDocuments from "../components/Upload/upload";
import SingleFileUpload from "../components/Upload/SingleFileUpload";
import { GenericSuccessMessage } from "../components/Alerts/genericAlert";
import { LoadingOutlined } from "@ant-design/icons";
import Badge from "./badge";
import DonutChart from "../components/charts/donut_chart";
import Footer from "../components/footer/footer";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Department",
    dataIndex: "department",
  },
  {
    title: "Presence",
    dataIndex: "presence",
  },
  {
    title: "Approved",
    dataIndex: "approved",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    name: record.name,
  }),
};

export default function PresenceValidade() {
  const [loading, setLoading] = useState(false);
  const [update, setUpadate] = useState(false);
  const [selectionType, setSelectionType] = useState();
  const [trainingPresence, setTrainingPresence] = useState({});
  const [employee, setEmployee] = useState([]);
  const [size, setSize] = useState("large");
  const location = useLocation();
  const [doc, setTrainigDoc] = useState();
  const loadIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

  useEffect(() => {
    baseurl
      .get(`v1/get_training_by_id_for_presence/${location.state.item}/`)
      .then((resp) => {
        setTrainingPresence(resp.data);
        setEmployee(resp.data["employee"]);
      });
    setUpadate(false);
  }, [update]);

  function finishRequirement() {
    alert("Finish requirement");
  }

  const data = employee.map((item) => ({
    key: `${item.id}`,
    name: `${item.description}`,
    department: item.department.description,
    presence: (
      <>
        {(item.isPresent && <Checkbox checked disabled></Checkbox>) || (
          <Checkbox
            onChange={(e) => {
              assinPresence("Presence", e, item);
            }}
          ></Checkbox>
        )}
      </>
    ),
    approved: (
      <>
        {(item.isAproved && <Checkbox checked disabled></Checkbox>) || (
          <Checkbox
            onChange={(e) => {
              assinPresence("Approved", e, item);
            }}
          ></Checkbox>
        )}
      </>
    ),
  }));
  function assinPresence(status, e, item) {
    setLoading(true);

    if (status === "Presence") {
      const data = {
        isPresent: e.target.checked,
        isApproved: false,
        employeeID: item.id,
        formationID: trainingPresence["formation"]["id"],
      };
      console.log("Sending...", data);

      baseurl.post("v1/training_presence/", data).then((resp) => {
        setLoading(false);
        setUpadate(true);
        GenericSuccessMessage("Presence marked successfully");
      });
    }
    if (status === "Approved") {
      const data = {
        isPresent: false,
        isApproved: e.target.checked,
        employeeID: item.id,
        formationID: trainingPresence["formation"]["id"],
      };
      console.log("Sending...", data);

      baseurl.post("v1/training_presence/", data).then((resp) => {
        setLoading(false);
        setUpadate(true);
        GenericSuccessMessage("Employee successfully approved");
      });
    }
  }

  function uploadFile() {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("pdfFile", doc);
    formData.append("description", "Description training");
    formData.append("status", "pdfFile");
    formData.append("trainigID", trainingPresence["formation"]["id"]);
    baseurl.post("v1/upload_document_file/", formData, headers).then((resp) => {
      console.log("Done Upload");
    });
  }

  return (
    <div>
      <Divider orientation="left" plain>
        <b>
          {" "}
          {Object.keys(trainingPresence).length > 0 &&
            trainingPresence["formation"]["description"]}
        </b>
      </Divider>
      <Card bordered={false} className="criclebox h-full">
        <div className="main">
          <div className="or-left">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Attendance list
          </div>
          <div className="or-right">
            {loading && <Spin indicator={loadIcon} />}
          </div>
        </div>
        <Table columns={columns} dataSource={data} />
      </Card>
      <br />
      <Card align="center" bordered={false} className="criclebox h-full">
        <br />
        <SingleFileUpload
          trainingDoc={setTrainigDoc}
          training={trainingPresence}
        />
        <br />
      </Card>
      <Footer />
    </div>
  );
}
