import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./FileUpload.scss";
import axios from "axios";
import { baseurl } from "../../../lib/settings";
import {
  GenericErrorMessage,
  GenericSuccessMessage,
  SomeThingErrorMessage,
} from "../../Alerts/genericAlert";

const FileUpload = ({ files, setFiles, removeFile, trainingPresence }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFiles([...files, file]);
    console.log("FILE", file);
    const formData = new FormData();

    formData.append("file", file);
    formData.append("description", "Description training");
    formData.append("status", "all");
    formData.append("type", "private");
    formData.append("fileType", file.type);
    formData.append("trainigID", trainingPresence["id"]);
    formData.append("formationDate", trainingPresence["start_date"]);
    baseurl
      .post("v1/upload_document_file/", formData)
      .then((res) => {
        file.isUploading = false;
        GenericSuccessMessage("Document uploaded successfully");
        setFiles([...files, file]);
      })
      .catch((err) => {
        console.log(`CHECK ERROR LENTH ${err.response.data.length}`);
        GenericErrorMessage(err.response.data);
        removeFile(file.name);
      });
  };

  return (
    <>
      <div className="file-card">
        <div className="file-inputs">
          <input type="file" onChange={uploadHandler} />
          <button>
            <i>
              <FontAwesomeIcon icon={faPlus} />
            </i>
            Upload
          </button>
        </div>
        <p className="main">Supported files</p>
        <p className="info">PDF, EXCEL, PowerPoint</p>
      </div>
    </>
  );
};

export default FileUpload;
