import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Input,
  Button,
  Form,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { baseurl } from "../../lib/settings";
import VisitorConfirmation from "../modal/visitor/confimation";
import {
  GenericSuccessMessage,
  SomeThingErrorMessage,
} from "../Alerts/genericAlert";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
export default function UserAcessForm({ visitorControl }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(true);
  const [smsAndEmail, setSmsAndEmail] = useState(true);
  const [sms, setSms] = useState(false);
  const [option, setOption] = useState("email");
  const [callModal, setCallModal] = useState(false);
  const [visitor, setVisitor] = useState({});
  const [confim, setConfirm] = useState({});
  const [visible, setModalVisible] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      full_name: values.full_name,
      email: values.email,
      cell_number: "258" + values.phone,
      option: option,
      on_existe: "keep",
    };
    baseurl
      .post("v1/otp/", data)
      .then((resp) => {
        GenericSuccessMessage(resp.data["message"]);
        setLoading(false);
        visitorControl(true);
        form.resetFields();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data['error'] === true){
            SomeThingErrorMessage(err.response.data.message);
          }else{
            if (err.response.data.created === false) {
              setVisitor(err.response.data.visitor);
              setModalVisible(true);
              setCallModal(true);
            }
          }
          
          setLoading(false);
        }
        SomeThingErrorMessage("Something didn't go as expected. Try again");
        setLoading(false);
        console.log("Falha", err.response);
      });
  };

  const validateOption = (type, valeu) => {
    if (type === "email") {
      setEmail(true);
      setSms(false);
      setSmsAndEmail(false);
    } else if (type === "sms") {
      setEmail(false);
      setSms(true);
      setSmsAndEmail(false);
    } else if (type === "all") {
      setEmail(false);
      setSmsAndEmail(true);
      setSms(false);
    }
    setOption(type);
  };
  return (
    <>
      <Card
        bordered={false}
        className="header-solid h-full ant-invoice-card"
        title={[<h6 className="font-semibold m-0">Give access</h6>]}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          disabled={loading}
        >
          <Form.Item name="full_name">
            <Input type="text" placeholder="Full name" />
          </Form.Item>

          <Form.Item name="email">
            <Input type="text" placeholder="E-mail" />
          </Form.Item>

          <Form.Item name="phone">
            <Input prefix="+258" type="number" placeholder="Phone number" />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={email}
                  onChange={(e) => {
                    validateOption("email", e.target.checked);
                  }}
                >
                  Send OTP by Email
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={sms}
                  onChange={(e) => {
                    validateOption("sms", e.target.checked);
                  }}
                >
                  Send OTP by SMS
                </Checkbox>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item>
                <Checkbox
                  checked={smsAndEmail}
                  onChange={(e) => {
                    validateOption("all", e.target.checked);
                  }}
                >
                  Send OTP by Email and SMS
                </Checkbox>
              </Form.Item>
            </Col> */}
          </Row>
          <Form.Item>
            <Button block loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {callModal && (
        <VisitorConfirmation
          setVisible={setModalVisible}
          visible={visible}
          setConfirmation={setConfirm}
        />
      )}
    </>
  );
}
