import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Radio, Button, Popconfirm } from "antd";
import { baseurl } from "../../lib/settings";
import { Link, useLocation } from "react-router-dom";
import { trainigDocTableColum } from "../../components/Tables/columns";
import {
  FileDoneOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { GenericSuccessMessage } from "../../components/Alerts/genericAlert";

const data = [
  {
    key: "1",
    name: "John Brown",
    creation_date: 98,
    created_by: 60,
    actions: 70,
  },
  {
    key: "2",
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: "3",
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: "4",
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
];
export default function ListTrainingDocs() {
  const location = useLocation();
  const [docTraining, setDocTraining] = useState({});
  const [trainingName, setTrainingName] = useState("None");
  const [pdfDocs, setPdfDocs] = useState([]);
  const [excel, setExel] = useState([]);
  const [powerPowen, setPowerPowen] = useState([]);
  const [controlTable, setControlTable] = useState("pdf");
  const [tableActionLoading, setTableActionLoading] = useState(false);
  const [dataControl, setDataControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTrainingName(location.state.item["formation"]["description"]);
    var param = {
      trainingDate: location.state.item["start_date"],
      trainingID: location.state.item["id"],
    };
    baseurl.get(`v1/doc_training/`, { params: param }).then((resp) => {
      setPdfDocs(resp.data["pdf"]);
      setExel(resp.data["excel"]);
      setPowerPowen(resp.data["powerpoint"]);
      setDocTraining(resp.data);
      setIsLoading(false);
    });
    setDataControl(false);
  }, [dataControl]);
  const onChange = (check) => {
    if (check.target.checked) {
      setControlTable(check.target.value);
    }
  };

  const exelData = excel.map((doc) => ({
    key: `${doc.id}`,
    name: doc.file_name,
    creation_date: doc.updated_at,
    created_by: doc.created_by.user_name,
    actions: (
      <>
        <a href={doc.excel} download target="_blank">
          <Button onClick={() => onActionClick(doc, "download")}>
            <CloudDownloadOutlined />
          </Button>
        </a>
        &nbsp;
        <Popconfirm
          title="Are you sure to delete this document?"
          description="Delete the document"
          okText="Yes"
          onConfirm={() => onActionClick(doc, "delete", "Excel")}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    ),
  }));

  const pdfData = pdfDocs.map((doc) => ({
    key: `${doc.id}`,
    name: doc.file_name,
    creation_date: doc.updated_at,
    created_by: doc.created_by.user_name,
    actions: (
      <>
        <a href={doc.pdf} download="My_File.pdf" target="_blank">
          <Button onClick={() => onActionClick(doc, "download")}>
            <CloudDownloadOutlined />
          </Button>
        </a>
        &nbsp;
        <Popconfirm
          title="Are you sure to delete this document?"
          description="Delete the document"
          okText="Yes"
          onConfirm={() => onActionClick(doc, "delete", "PDF")}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    ),
  }));
  const powerpointData = powerPowen.map((doc) => ({
    key: `${doc.id}`,
    name: doc.file_name,
    creation_date: doc.updated_at,
    created_by: doc.created_by.user_name,
    actions: (
      <>
        <a href={doc.powerpoint} download target="_blank">
          <Button onClick={() => onActionClick(doc, "download")}>
            <CloudDownloadOutlined />
          </Button>
        </a>
        &nbsp;
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          okText="Yes"
          onConfirm={() => onActionClick(doc, "delete", "PowerPoint")}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    ),
  }));

  function onActionClick(doc, fuction, docType) {
    if (fuction === "download") {
    } else if (fuction === "share") {
    } else if (fuction === "delete") {
      deleteDoc(doc, docType);
    }
  }
  function deleteDoc(doc, docType) {
    setTableActionLoading(true);
    var data = {
      dc_id: doc.id,
      dc_type: docType,
      trainig_id: docTraining["training"]["id"],
      training_date: docTraining["training"]["start_date"],
    };
    baseurl.post("v1/delete/doc/", data).then((resp) => {
      setTableActionLoading(false);
      GenericSuccessMessage("Successfully deleted document");
      setDataControl(true);
    });
  }
  return (
    <div>
      <Col xs={24} className="mb-24">
        <Card
          className="header-solid h-full ant-card-p-0"
          title={
            <>
              <Row
                gutter={[24, 0]}
                className="ant-row-flex ant-row-flex-middle"
              >
                <Col xs={24} md={12}>
                  <h6 className="font-semibold m-0">
                    List of {trainingName} documents
                  </h6>
                </Col>
                <Col xs={24} md={12} className="d-flex">
                  {tableActionLoading && <SyncOutlined spin />}&nbsp;&nbsp;
                  <Radio.Group onChange={onChange} defaultValue="pdf">
                    <Radio.Button value="pdf">PDF</Radio.Button>
                    <Radio.Button value="excel">Excel</Radio.Button>
                    <Radio.Button value="ppoint">PowerPoint</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            </>
          }
        >
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title=""
          >
            <div className="table-responsive">
              {controlTable == "pdf" && (
                <Table
                  columns={trainigDocTableColum}
                  dataSource={pdfData}
                  pagination={false}
                  loading={isLoading}
                  className="ant-border-space"
                />
              )}
              {controlTable == "excel" && (
                <Table
                  columns={trainigDocTableColum}
                  dataSource={exelData}
                  pagination={false}
                  loading={isLoading}
                  className="ant-border-space"
                />
              )}
              {controlTable == "ppoint" && (
                <Table
                  columns={trainigDocTableColum}
                  dataSource={powerpointData}
                  pagination={false}
                  loading={isLoading}
                  className="ant-border-space"
                />
              )}
            </div>
          </Card>
        </Card>
      </Col>
    </div>
  );
}
