import React, { useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  PlayCircleTwoTone,
  ReadFilled,
  TeamOutlined,
  UserOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { Breadcrumb, Col, Image, Layout, Menu, Popover, Row, Tag, Typography } from 'antd';
import Icon from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";
import playIcon from '../../../components/assets/icons/play_icon.png';
import playDoneIcon from '../../../components/assets/icons/play_done_icon.png';
import playLigthIcon from '../../../components/assets/icons/play_ligth_icon.png';
import quizIcon from '../../../components/assets/icons/quiz_icon.png';
import './custom_css.css'
// import World from '../../../components/assets/images/world2.png'
import World from '../../../components/assets/images/world5.png'


const { Title, Text } = Typography;

const MenuItem = styled(Menu.Item)`
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid transparent !important;
  height: 10px;
}`;

// const MenuItem = styled(Menu.Item)`
//   && {
//     margin: -5px; / Adjust the desired height /
//     line-height: 15px; / Align text vertically within the item /
//     .anticon {
//       font-size: 18px; / Adjust the icon size /
//     }
    
//     .course-title-subtitle {
//       display: flex;
//       align-items: center;
//     }
//     margein: 0;
//   }
// `;


const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Option 1', '1', <PieChartOutlined />),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('User', 'sub1', <UserOutlined />, [
    getItem('Tom', '3'),
    getItem('Bill', '4'),
    getItem('Alex', '5'),
  ]),
  getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  getItem('Files', '9', <FileOutlined />),
];


export default function SecondSidBar({ courses, handleCourseSelection, formControl, setModuleCode}) {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const content = <> Assigned Trainings:</>;

    const handleVideoClick = (status, video, moduleCode) => {
        console.log("Module Code", moduleCode)
        setModuleCode(moduleCode)
        handleCourseSelection(video, status);
        setSelectedVideo(video.id);
    };

    function validateVideoQuizPassedAndAproved(course, index) {
        // console.log(`Video control ${index}`, index === 0 ? false : !course.videos[index === 0 ? index : index - 1].video_progress.watched_completely, course.videos[index === 0 ? index : index - 1].description)
        if(index === 0){
            return false
        }
        if (course.videos[index === 0 ? index : index - 1].video_progress.watched_completely){
            // console.log("Waths quiz", course.videos[index === 0 ? index : index - 1].quizs[index === 0 ? index : index - 1])
            if (course.videos[index === 0 ? index : index - 1].quizs[index === 0 ? index : index - 1] !== undefined ){
                // console.log(`Validate quiz ${course.videos[index === 0 ? index : index - 1].description}`, course.videos[index === 0 ? index : index - 1].quizs[index === 0 ? index : index - 1]['answer_details']['answered'])
                if (course.videos[index === 0 ? index : index - 1].quizs[index === 0 ? index : index - 1]['answer_details']['answered']){
                    if(course.videos[index === 0 ? index : index - 1].quizs[index === 0 ? index : index - 1]['answer_details']['all_questions_correct']){
                        return false;
                    }
                    return true
                }
                return true
            }   
            
            return false
        }
        return true
    }

    return (
            <Sider theme='light' width={"370px"} style={{
            height:'100%',
            backgroundColor:'white',
            backgroundRepeat:'no-repeat',
            backgroundAttachment:'fixed',
            backgroundPosition:'left 3.9em bottom 2em',
            backgroundSize:'250px',
            // opacity:'27%',
            // backgroundSize:'cover',
            backgroundImage:`url(${World})`
        }}>   
            <br/>
            <br/>
            {/* <div className="demo-logo-vertical" /> */}
            {/* <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} /> */}
            <Menu theme="light" mode="inline">
                <p style={{
                    fontFamily: 'Montserrat', 
                    fontSize: '20px',
                    paddingBottom:'7px', 
                    marginLeft: "5%", 
                    // fontSize: "16px", 
                    color: "black" }}>
                    Select Your Desired Course:
                </p>        
                {courses.map((course, index) => (
                    <SubMenu 
                    // style={{
                    //     border: '1px',
                    //     borderRadius: '7px',
                    //     maxHeight: '240px'
                    //   }}
                        key={course.id}
                        title={
                           <div >
                             <Text  style={{
                            fontFamily: 
                            'Montserrat', 
                            fontSize: '15px'
                           }} >{index + 1}. {course.training_title}</Text>
                           </div>
                            // <h2 style={{
                            //     color:'black'
                            // }}>
                            //   <span style={{fontWeight: 'bold'}}>{course.training_title}</span>
                            // </h2>
                            }
                            >
                                {/* <div className='align-main' style={{ height: "18px", marginLeft: "50px", display: "flex", alignItems: "center", overflow: "hidden"}}> 
                                    Assigned Trainings:
                                </div> */}
                        {course.videos.map((video, index) => (
                            <>
                                {console.log(`Validate validateVideoQuizPassedAndAproved Function for  ${video.description}:${video.id}`, validateVideoQuizPassedAndAproved(course, index))}
                                <MenuItem 
                                    disabled={validateVideoQuizPassedAndAproved(course, index)}
                                    onClick={(e)=> handleVideoClick('video', video, course.module_code)} 
                                    className="menu-item" 
                                    key={video.id}>
                                        <span style={{
                                            fontFamily: 'Montserrat', fontSize: '14px'
                                        }} className="course-title-subtitle">
                                            {/* {console.log(`Videp ${video.description}:${video.id}`, video.video_progress.watched_completely)} */}
                                        <PlayCircleTwoTone twoToneColor={video.video_progress.watched_completely ? "green" : "blue"}/> &nbsp; {video.description}
                                        </span>
                                </MenuItem>
                                {video.quizs.map((quiz) => (
                                <MenuItem
                                    disabled={!course.videos[index === 0 ? index : index - 1].video_progress.watched_completely}
                                    onClick={(e)=> handleVideoClick('quiz', quiz, course.module_code)} 
                                    className="menu-item"
                                >
                                     <span style={{
                                        fontFamily: 'Montserrat'
                                     }} className="course-title-subtitle">
                                     <Row gutter={24}>
                                        <Col span={18}>
                                        <ReadFilled /> &nbsp;  <span style={{
                                            fontFamily: 'Montserrat'
                                        }}>{quiz.description}</span>
                                        </Col>
                                        <Col style={{
                                            display:'flex',
                                            alignContent:'center',
                                            // alignItems:'center',
                                            // textAlign:'center'
                                            fontFamily: 'Montserrat'
                                        }} span={4}>
                                            {quiz.answer_details.answered ? <div style={{
                                                fontFamily: 'Montserrat'
                                                // height:'25px',
                                                // width:'45px',
                                                // fontSize:'13px',
                                                // textAlign:'center',
                                                // borderRadius:'15px',
                                                // backgroundColor:quiz.answer_details.all_questions_correct ? '#4CBB17' : '#FF2E2E' 
                                            }}>
                                                <Popover 
                                                content={quiz.answer_details.percentage} 
                                                trigger="hover" 
                                                >
                                                    <Tag  color={quiz.answer_details.all_questions_correct ? 'green' : 'red' }>{quiz.answer_details.quiz_tag}</Tag>
                                                </Popover> 
                                            </div> : <></>}
                                        
                                        </Col>
                                     </Row>
                                    </span>
                                </MenuItem>
                            ))}
                            </>
                        ))}
                </SubMenu>
                ))}
            </Menu>
            {/* <Col style={{
                    // marginTop:'100%'
                    // height:'200px',
                    top:'300px',
                    opacity:'45%',
                    alignItems:'center',
                    alignContent:'center',
                    textAlign:'center'
                }}>
                    <Image
                    height='600px'
                    width='600px'
                    preview={false}
                    src={World} />                           
                </Col> */}
        </Sider>
    )
}