import React, { useEffect, useState } from "react"
import { baseurl } from "../../../../lib/settings"
import { Button, Card, Col, Row, Space, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (size) => <LoadingOutlined style={{ fontSize: size }} spin />;

export default function LastEditedQuiz({setControlQuizData, controlQuizData}) {
    const [quizDetails, setQuizDetails] = useState([])
    const [quizError, setQuizError] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    useEffect(() =>{
        setIsLoading(true)
        baseurl.get('v1/quiz/list_selected/').then((resp) => {
            setQuizDetails(resp.data);
            setIsLoading(false)
            setQuizError(false)
        }).catch((err => {
            setQuizError(true);
            setIsLoading(false);
            setControlQuizData(false);
            console.log("Erro na lista das quizs", err)
        }))
        setControlQuizData(false);
    },[controlQuizData])
    return (
        <>
            {isloading ? <Spin indicator={antIcon(25)} /> : <> {quizError ? <Button
            style={{
                display:'inline-flex', 
                alignItems:'center',
            }}
            onClick={() => setControlQuizData(true)}
        >Try Again</Button> : <>
        {quizDetails.length > 0 ? <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {quizDetails.map((quiz, index) => (
            <Col style={{textAlign:"center"}} className="gutter-row" span={6}>
                <Space direction="vertical" >
                    <Card
                        size="small" 
                        style={{ 
                            justifyContent:"center",
                            alignItems:"center",
                            textAlign:"center",
                            height:150,
                            width: 250 }}>
                        
                    </Card>
                    {/* <Title type="secondary" level={4}>Module {index}</Title>   */}
                    <h6 className="font-semibold m-0">
                    {quiz.description}
                    </h6>
                </Space>        
            </Col>
        ))}
    </Row> : <>No Quiz History</>}
        </>}    </>}
        </>       
    )
}