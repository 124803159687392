import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table, Input, message, Space, Button, Popconfirm } from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { baseurl } from '../../lib/settings';

const { Search } = Input;


const ReceivedEmails = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    baseurl.get('v1/check_all_sent_emails/').then((resp) => {
      setEmails(resp.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log('Error fetching received emails:', err);
    });
  }, []);

  const deleteEmail = (record) => {
    // Implement your delete logic here
    console.log('Deleting email:', record);
  };


const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Sender Email',
      dataIndex: 'sender_email',
      key: 'sender_email',
    },
    {
      title: 'Receiver Email',
      dataIndex: 'receiver_email',
      key: 'receiver_email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <Space>
    //       <Popconfirm
    //         title="Delete Email"
    //         description="Are you sure to delete this email?"
    //         onConfirm={() => deleteEmail(record)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Button danger>Delete</Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];

  const searchEmail = (value) => {
    // Implement your search logic here
    console.log('Searching for email:', value);
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={24}>
        <Card bordered={false} title="Sent Emails">
          <Space style={{ width: '100%' }} size={5} direction="vertical">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* <div style={{ width: '40%' }}>
                <Search
                  onChange={(e) => searchEmail(e.target.value)}
                  prefix={<SearchOutlined />}
                  placeholder="Search"
                />
              </div> */}
            </div>
            <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
              <Table
                loading={loading}
                columns={columns}
                dataSource={emails}
                pagination={{ pageSize: 7 }}
              />
            </div>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default ReceivedEmails;
