
import { Col, List, Radio, Row, Space, Tabs, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import CommentFeedbackTable from "../Tables/comment_feedback";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./custom_setimental_barchart.css"

export default function SentimentalBarChart({barData, sugestionData, loading, filterDataNumber, setFilterDataNumber}){
    const tab_items = [
      {
        key: '1',
        label: 'Question List',
        children: <>
         
          <List
              // style={{
              //   height:'465px'
              // }}
              // header={<div>Question List</div>}
              // footer={<div>Footer</div>}
                bordered
                dataSource={barData.questions}
                renderItem={(item, index) => (
                  <List.Item>
                    <Typography.Text mark>[{index+1}]</Typography.Text> {item}
                  </List.Item>
                )}
            />
          
        </>,
      },
      {
        key: '2',
        label: 'Last suggestions',
        children: <>
          <Space style={{width:'100%'}} size={5} direction="vertical">
          <div style={{
            float:'right'
          }}>
          <Radio.Group value={filterDataNumber} onChange={(e) => setFilterDataNumber(e.target.value)}>
            <Radio.Button value={10}>10</Radio.Button>
            <Radio.Button value={20}>20</Radio.Button>
            <Radio.Button value={30}>30</Radio.Button>
          </Radio.Group>
          </div>
                <CommentFeedbackTable
                  tableData={sugestionData}
                  loading={loading}
                  totalPage={filterDataNumber}
                />
          </Space>
        </>
      },
    ];
    let xAxisData = [];
    let data1 = [];
    let data2 = [];
    // let data3 = [];
    // let data4 = [];
    for (let i = 0; i < 6; i++) {
    xAxisData.push(1+ i);
    data1.push(+(Math.random() * 2).toFixed(2));
    data2.push(+(Math.random() * 5).toFixed(2));
    // data3.push(+(Math.random() + 0.3).toFixed(2));
    // data4.push(+Math.random().toFixed(2));
    }
    // console.log("Detalhes dos dados filtrados", barData.questions, xAxisData, data1)

    var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
    };

    var emphasisStyle1 = {
      itemStyle: {
        shadowBlur: 10,
        color: 'rgba(238, 0, 0, 0.77)',
        shadowColor: 'rgba(238, 0, 0, 0.77)'
      }
  };
    var option = {
      
      legend: {
        data: ['Satisfied', 'Unsatisfied'],
        dshow: true,
        orient: 'horizontal',
        left: 'center',
        top: 'bottom',
      },
        
      tooltip: {
        trigger: 'item',
        responsive: true,
        position: 'top',
        axisPointer: {
          type: 'shadow'
        },
        // formatter: '{c}',
        },
        xAxis: {
          data: xAxisData,
          // name: 'Questions',
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {show:false},
        grid: {
          bottom: 100
        },
        series: [
          
          {
            name: 'Unsatisfied',
            type: 'bar',
            stack: 'one',
            emphasis: emphasisStyle1,
            itemStyle: {
              color: 'rgba(238, 0, 0, 0.77)'
            },
            label: {
              show: true
            },
            data: barData.negative_feedback
          },
          // {
          //   symbol: ['none', 'none'],
          //   label: { show: false },
          //   // data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
          // },
          {
            name: 'Satisfied',
            type: 'bar',
            stack: 'one',
            emphasis: emphasisStyle,
            label: {
              show: true
            },
            data: barData.posetive_feedback
          },
       
        ]}
        
    return(
      <Row gutter={[10, 0]}>
        <Col xs={24} md={12}>
        <div style={{
          border:'2px solid rgba(140, 140, 140, 0.35)',
          padding: '15px',
          height:'100%',
          borderRadius:'10px'
        }}>
          <Tabs style={{height: '100%',}} defaultActiveKey="1" items={tab_items}  /></div>
        
          
        </Col>
        <Col xs={24} md={12}>
        <div style={{
          border:'2px solid rgba(140, 140, 140, 0.35)',
          padding: '15px',
          borderRadius:'10px'
        }}>
          <h6 className="font-semibold m-0 chart_title">
            Training Sessions Feedback
          </h6>
          <ReactECharts style={{ width: "100%", height: "310px" }} option={option} />
        </div>
        </Col> 
        
      </Row>
    )
}