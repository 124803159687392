import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';
import "./size.css";
import {
  List,
  Divider,
  Avatar,
  Space,
  Col,
  Row,
  Card,
  Skeleton,
  Button,
} from "antd";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { useLocation } from "react-router-dom";

export default function Files() {
  const [trainings, setTrainings] = useState([]);
  const [control, setControl] = useState();
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (location.state != undefined) {
      setFile(location.state);
    }
  });

  return (
    <div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={file}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.description}>
              Card content {item.discription}
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}
