import React, { useState } from 'react';
import { Radio } from 'antd';

const OptionAnswer = ({ option, onChange }) => {
  const[checkedOption, setChekedOption] = useState(false)
  // console.log("OP: ", option, "onC: ", onChange, "chck: ", checked)
  return (
    <Radio
    style={{ fontFamily: 'Montserrat'}}
      value={option} 
      onChange={(e) => {
        onChange(e.target.value);
      }}
      >
      {option}
    </Radio>
  );
};

export default OptionAnswer;
