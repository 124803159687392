import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Progress,
  Avatar,
  Button,
  Radio,
  Table,
  Typography,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FileDoneOutlined } from "@ant-design/icons";

import ava1 from "../components/assets/images/engineering.png";
import pencil from "../components/assets/images/pencil.svg";
import { baseurl } from "../lib/settings";
import { CollectionsPage2 } from "../components/modal/scheduled/create_training";
import Footer from "../components/footer/footer";

const { Title } = Typography;
const project = [
  {
    title: "Training",
    dataIndex: "name",
    width: "32%",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
  },
  {
    title: "Capacity",
    dataIndex: "completion",
  },
  {
    title: "Presence",
    dataIndex: "presence",
  },
  {
    title: "Docs",
    dataIndex: "docs",
  },
];

const dataproject = [
  {
    key: "1",

    name: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    employees: (
      <>
        <div className="semibold">$14,000</div>
      </>
    ),
    time: (
      <>
        <div className="text-sm">working</div>
      </>
    ),
    completion: (
      <>
        <div className="ant-progress-project">
          <Progress percent={30} size="small" />
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
  },
];

const formatTime = (time) => {
  const formattedTime = moment(time, "HH:mm:ss").format("HH[h]mm");
  return formattedTime;
};

export default function ListTrainings() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [control, setControl] = useState(false);
  const [training, setTraining] = useState();
  const { Option } = Select;
  const location = useLocation();
  const [schedule, setSchedule] = useState([]);
  const [eventFilter, setEventFilter] = useState();
  const [feedBackform, setFeedbackform] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsloading(true);
    baseurl.get("v1/get_training/", {params: {range_of_day:'all', request_type:'all'}}).then((resp) => {
      setEvents(resp.data);
    }).catch((err) => {
      console.log("Error on geting treaning List", err)
    });
    baseurl.get("v1/submit_feedback_form/").then((resp) => {
      setFeedbackform(resp.data);
      console.log("feedbackForm", resp.data);
    });
    let getuser = localStorage.getItem("user");
    var obj = JSON.parse(getuser);
    baseurl.get(`v1/get_user_by_team/${obj.id}/`).then((response) => {
      setUsers(response.data);
    });
    if (location.state != undefined) {
      setSchedule(location.state.item);
    }

    setIsloading(false);
    setControl(false);
  }, [control]);

  const fordAutos = events.filter((auto) =>
    auto.formation.description.includes(schedule.title)
  );
  const employee = fordAutos.map((item) => item.employee);

  const userfilter = users.filter((auto) =>
    auto.description.includes(schedule.title)
  );
  const res = users.map((el1) => ({
    id: el1.id,
    description: el1.description,
    match: check_if_user_have_training(el1.description),
  }));
  console.log("Dados Processados", res);
  const capacity = fordAutos.map((item) => item.capacity).toString();

  function check_if_user_have_training(user) {
    const format = events.filter((auto) =>
      auto.formation.description.includes(schedule.title)
    );
    const usersss = format.map((item) => item.employee);

    if (usersss.length != 0) {
      console.log("Pronto para filtrar", usersss);
      if (usersss[0].length != 0) {
        console.log("mas que pronto entao I Will filter here ", usersss);
        if (employee[0].filter((e2) => e2.description === user).length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
      }
    } else {
      return false;
    }
  }
  const list_of_trainings = events.map((item) => ({
    key: item.id,
    name: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>{item.formation.description}</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    date: (
      <>
        <div className="semibold">
          {moment(item.start_date).format("DD/MM")} -{" "}
          {moment(item.end_date).format("DD/MM")}
        </div>
      </>
    ),
    time: (
      <>
       <div className="semibold">{formatTime(item.start_time)} - {formatTime(item.end_time)}</div>
        {/* {item.is_open ? (
          <div className="text-sm">Open</div>
        ) : (
          <div className="text-sm">Close</div>
        )} */}
      </>
    ),
    completion: (
      <>
        <div className="ant-progress-project">
          <Progress
            percent={((item.employee.length / item.capacity) * 100).toFixed(0)}
            size="small"
          />
          <span>
            <div className="text-sm">
              {item.employee.length}/{item.capacity}
            </div>
          </span>
        </div>
      </>
    ),
    presence: (
      <>
        <Button onClick={() => setPresences(item)}>Presence</Button>
      </>
    ),
    docs: (
      <>
        <Button onClick={() => docRedirect(item)}>
          <FileDoneOutlined />
        </Button>
      </>
    ),
  }));

  function setPresences(item) {
    navigate("/presence", { state: { item: item.id } });
  }
  function docRedirect(item) {
    navigate("/trainigs_docs", { state: { item: item } });
  }
  async function sendData(value, status) {
    const emp = fordAutos.map((item) => item.employee.length);

    if (Number(schedule.capacity) === Number(emp)) {
      console.log("Sala Cheia");
      return Modal.info({
        title: schedule.title,
        content: `The Room has reached the maximum number of participants.`,
      });
    } else if (
      Number(selectedUsers.length) >
      Number(schedule.capacity) - Number(emp)
    ) {
      console.log("Excedeo o Limite");
      return Modal.info({
        title: schedule.title,
        content: `You can only add more than ${
          Number(schedule.capacity) - Number(emp)
        } members of your team.`,
      });
    } else {
      if (status === "Modal") {
        const data = {
          formation: training,
          capacity: parseInt(value.capacity),
          start_date: moment(value.date[0]._d).format("YYYY-MM-DD"),
          end_date: moment(value.date[1]._d).format("YYYY-MM-DD"),
        };
        const send = await baseurl.post("v1/booking/training/", data);
        setControl(true);
      } else if (status === "AdddEmployeer") {
        const data = {
          employee: selectedUsers,
          formation: schedule.id,
        };
        const send = await baseurl.post("v1/training/add_employee/", data);
        setControl(true);
        setSelectedUsers([]);
      }
    }
  }

  const CallModal = () => {
    setIsModal(true);
  };

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Col xs={24} className="mb-24">
            <Card
              className="header-solid h-full ant-card-p-0"
              title={
                <>
                  <Row
                    gutter={[24, 0]}
                    className="ant-row-flex ant-row-flex-middle"
                  >
                    <Col xs={24} md={12}>
                      <h6 className="font-semibold m-0">
                        Scheduled Trainings
                      </h6>
                    </Col>
                    <Col xs={24} md={12} className="d-flex">
                      <Button onClick={CallModal} type="primary">
                        Add New Schedule
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            >
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title=""
                extra={
                  <>
                    <Radio.Group onChange={onChange} defaultValue="all">
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="online">Done</Radio.Button>
                      <Radio.Button value="store">Expired</Radio.Button>
                    </Radio.Group>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={project}
                    dataSource={list_of_trainings}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
                <div className="uploadfile pb-15 shadow-none"></div>
              </Card>
            </Card>
          </Col>
        </Col>

        {isModal && (
          <CollectionsPage2
            onChange={(item) => {
              sendData(item, "Modal");
              setIsModal(item);
            }}
            onCancel={(item) => {
              setIsModal(item);
            }}
            date={(item) => {}}
            trainingID={(item) => {
              setTraining(item);
            }}
          />
        )}
      </Row>

      <Footer />
    </>
  );
}
