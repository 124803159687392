import { Button, Modal, Typography, ColorPicker, Form, Row, Col, Input, Space } from "antd";
import { useState } from "react";
import { DEFAULT_PRESET_COLOR_PIKER } from "../../../utils/constants";

const { Title, Paragraph, Text, Link } = Typography;


export default function EditiFormation({open, formControl, setOpen, onFinish, loading, trainingColor, traningDetails}) {
    const [trainingName] = useState(traningDetails.description);

    const handleCancel = () => {
        
        setOpen(false);
    };
    return (
        <Modal
        open={open}
        destroyOnClose={true}
        title={<div style={{
          // border:'2px solid rgba(140, 140, 140, 0.35)',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          padding:'2%',
          borderRadius:'10px'
      }}>
        <Title level={4}>Edit training</Title>
          
      </div>}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Return
        //   </Button>,
        //   <Button key="submit" type="primary" loading={loading} >
        //     Submit
        //   </Button>,
        //   <Button
        //     key="link"
        //     href="https://google.com"
        //     type="primary"
        //     loading={loading}
        //   >
        //     Search on Google
        //   </Button>,
        // ]}
      >
        <Form
            // initialValues={{
            //     training_name:traningDetails.description
            // }}
            disabled={loading}
            layout="vertical"
            name="basic"
            form={formControl}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row gutter={24}>
                <Col xs={24} sm={12}>
                    <Form.Item
                        initialValue={traningDetails.description}
                        val
                        name="training_name"
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                        >
                        <Input placeholder="Training" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        // label="Workload "
                        initialValue={traningDetails.workload}
                        name="workload"
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                        >
                        <Input type="number" placeholder="Workload" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="details"
                initialValue={traningDetails.details}
                rules={[
                    {
                    required: false,
                    message: '',
                    },
                ]}
                >
                <Input.TextArea style={{
                    height:145
                }} placeholder="Details" />
            </Form.Item>
            <Form.Item
                label='Training color'
                name="color"
                rules={[
                    {
                    required: false,
                    message: '',
                    },
                ]}
                >
                    <Space direction="horizontal">
                        <div
                            style={{
                            display:'flex',
                            color:'black',  
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                            alignItems:'center',
                            borderRadius: '9px',
                            padding:'3px',
                            height:'4.4vh',
                            width:'13vh'
                            }}
                        >
                            <div style={{
                                    backgroundColor:`${traningDetails.color}`,
                                    color:'transparent',
                                    width:'3.6vh',
                                    height:'100%',
                                    borderRadius: '7px',
                                    marginRight:'7px'
                                }}>dfd</div>
                        <Paragraph style={{marginTop:'10px', fontSize:'11px', fontWeight:'bold'}}>Curent color</Paragraph>
                        </div>
                        <div style={{float:'left'}}>
                            <ColorPicker initialValue={traningDetails.color} disabled={loading} onChange={(value, hex) => trainingColor(hex)}presets={DEFAULT_PRESET_COLOR_PIKER} size="large" showText />
                        </div>
                    </Space>
                    
            </Form.Item>

            <Form.Item>
            <Button loading={loading} disabled={loading} block type="primary" htmlType="submit">
                Submit
            </Button>
            </Form.Item>
        </Form>
      </Modal>
    )
}