import React from 'react';
import { Card, Col, Radio, Space, Typography } from 'antd';
import OptionAnswer from './OptionAnswer';
import "./question.css"

const { Title } = Typography;


const Question = ({ question, options, onChange, value, index, courseCode }) => {
  return (
   <Col >
      <Title style={{fontStyle: "none", fontWeight: "normal" }} level={5}>

        <div className='central'> 
        <div className='esquerda'>
          {index}. {question}
          </div>
          <div className='direita'><span style={{ fontFamily: 'Montserrat'}}>{courseCode}</span></div>
        </div>

        </Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Radio.Group
          onChange={(e) => onChange(e.target.value)}
        >
          <Space direction='vertical'>
          {options.map((option, index) => (
            <ul>
              <li>
              <OptionAnswer 
                key={index}
                option={option}
                onChange={(e) => onChange(e)}
            />
              </li>
            </ul>
            
          ))}
          <br/> <br/>
          </Space>
        </Radio.Group>
        </div>
   </Col>
  );
};

export default Question;
