import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./principal.css";
import {
  Slider,
  Card,
  Form,
  Input,
  Button,
  Radio,
  Col,
  Row,
  Modal,
} from "antd";
import moment from "moment";
import { baseurl } from "../../lib/settings";
// import defaultVideoUrl from "../../components/assets/vid/balama-introduction.mov";

const { TextArea } = Input;

function PainelPrincipal({ selectedCourse, moduleCode }) {
  const [size, setSize] = useState(8);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [comment, setComment] = useState("");
  const [startTime, setStatTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [played, setPlayed] = useState(0);
  const [loadedSeconds, setLoadedSeconds] = useState(0);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [loaded, setLoaded] = useState(0);
  const playerRef = useRef();


  useEffect(() => {
    console.log("SELECTED COURSE", selectedCourse)
    setIsLoading(true);
    var params = {
      module_code:moduleCode,
      video_code:selectedCourse.code,
    }
    baseurl.get('v1/streaming/progress/', {params}).then((resp) => {
      setVideoProgress(resp.data);
    // this.p.seekTo(30.7);
      console.log("Back time", parseFloat(resp.data.played_seconds))
      playerRef.current.seekTo(parseFloat(resp.data.played_seconds), 'seconds');
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      console.log("Error on get initial progress")
    })
  },[])

  const onFinish = (values) => {
    setModalOpen(true);
  };

  const marks = {
    0: "0",
    10: "10",
  };

  const isCommentEmpty = comment.trim() === "";
  // const defaultVideoUrl ="/home/mzodqjvb/filemaneger/twigg/videos/twigg-introduction-video.mov";

  const trackPlayedViedio = (e) => {
    setStatTime(moment().format("YYYY-MM-DD hh:mm:ss"))
    var videoProgressId = undefined
    if (Object.keys(videoProgress).length === 0){
      // console.log("Loaded Viedo Progress 1", videoProgress)
      var data = {
        start_time:moment().format("YYYY-MM-DD hh:mm:ss"),
        loaded:loaded,
        loaded_seconds:loadedSeconds,
        module_code:moduleCode,
        video_code:selectedCourse.code,
        watched_completely:false,
      }
      baseurl.post('v1/streaming/progress/', data).then((resp) =>{
        setVideoProgress(resp.data)
      }).catch (err => {
        console.log("Error on start save")
      })
    }
    
  }

  const trackPlayedViedioOnPouse = (e) => {
    setStatTime(moment().format("YYYY-MM-DD hh:mm:ss"))
    var videoProgressId = undefined
    if (Object.keys(videoProgress).length > 0){
      // console.log("Loaded Viedo Progress 1", videoProgress)
      videoProgressId = videoProgress.id
    }
    var data = {
      end_time:moment().format("YYYY-MM-DD hh:mm:ss"),
      played:played,
      played_seconds:playedSeconds,
      video_progress_id:videoProgressId,
      module_code:moduleCode,
      video_code:selectedCourse.code,
      watched_completely:false,
    }
    baseurl.post('v1/streaming/progress/', data).then((resp) =>{
      setVideoProgress(resp.data)
    }).catch (err => {
      console.log("Error on start save")
    })
  }

  const onPorcessViedio = (track) => {
    console.log("Track Loaded Video")
    
    setPlayedSeconds(track.playedSeconds)
    setPlayed(track.played)
    setLoadedSeconds(track.loadedSeconds)
    setLoaded(track.loaded)
    // console.log("More details video ", playedSeconds, played, loadedSeconds, loaded)
  }

  const finishVideo = (e) => {

    // console.log("Start video Time ", startTime)
    // console.log("Finishe video Time", endTime)
    // console.log("More details video ", playedSeconds, played, loadedSeconds, loaded)

    var videoProgressId = undefined
    if (Object.keys(videoProgress).length > 0){
      videoProgressId = videoProgress.id
    }
    var data = {
      end_time:moment().format("YYYY-MM-DD hh:mm:ss"),
      played:played,
      played_seconds:playedSeconds,
      video_progress_id:videoProgressId,
      module_code:moduleCode,
      video_code:selectedCourse.code,
      watched_completely:true,
    }
    baseurl.post('v1/streaming/progress/', data).then((resp) => {
      setVideoProgress(resp.data)
    }).catch (err => {
      console.log("Error on final save")
    })
  }

  return (
    <main className="main-content">
      <h1 className="message">{selectedCourse ? "" : ""}</h1>

      {!selectedCourse ? (
        <Card hoverable>
          <ReactPlayer
            // url={defaultVideoUrl}
            controls={true}
            width="100%"
            height="100%"
          />
          <h2 className="message-second-title">
            <strong>Balama and Vidalia - May 2022</strong>
          </h2>
        </Card>
      ) : (
        <Card hoverable>
          {isLoading ? <>Loading...</> : <ReactPlayer
            key={selectedCourse.id}
            url={selectedCourse.video}
            controls={true}
            width="100%"
            height="800px"
            ref={playerRef}
            onStart={(e) => trackPlayedViedio(e)}
            onEnded={(e) => finishVideo(e)}
            onProgress={(e) => onPorcessViedio(e)}
            onPause={(e) => trackPlayedViedioOnPouse()}
            
          />}

          <h2 className="message-second-title">
            {selectedCourse ? <strong>{selectedCourse.title}</strong> : ""}
          </h2>
          <Card>{selectedCourse.notes}</Card>
          <br />
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Use the field bellow for comments / notes"
              name="comment"
              rules={[{ message: "Please add your comment." }]}
            >
              <TextArea rows={3} onChange={(e) => setComment(e.target.value)} />{" "}
            </Form.Item>
            {!isCommentEmpty && (
              <>
                <Row>
                  <Col span={9}>
                    <Form.Item
                      label="Rate from 0 to 10"
                      name="rating"
                      rules={[
                        { required: true, message: "Please! Rate the course." },
                      ]}
                    >
                      <Slider
                        value={size}
                        onChange={(value) => setSize(value)}
                        min={0}
                        max={10}
                        marks={marks}
                      />
                    </Form.Item>
                  </Col>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Col span={12}>
                    <Form.Item
                      label="How do you rate the content you just saw?"
                      name="reaction"
                      rules={[
                        {
                          required: true,
                          message: "Please! Choose an option.",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="Good">Good</Radio>
                        <Radio value="Very Good">Very Good</Radio>
                        <Radio value="Bad">Bad</Radio>
                        <Radio value="Very Bad">Very Bad</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit my feedback
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </Card>
        
      )}
      {/* <Modal
        title="TWIGG Exploration & Mining Limitada"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        footer={[
          <Button key="back" type="primary" onClick={() => setModalOpen(false)}>
            Ok
          </Button>,
        ]}
      >
        <p>We are happy with your contribution. Thank you!</p>
      </Modal> */}
    </main>
  );
}

export default PainelPrincipal;
