import { Avatar, Card, Col, Row, Table, Input,message, Typography, Button, Space, Form, Result, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { baseurl } from "../lib/settings";
import moment from "moment";
import { SearchOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { DEFAULT_PRESET_COLOR_PIKER } from "../utils/constants";
import { GenericErrorMessage, GenericSuccessMessage } from "../components/Alerts/genericAlert";
import CreateFormation from "../components/modal/forms/create_formation";
import EditiFormation from "../components/modal/forms/edit_formation";
import EditTrainingDetails from "../components/modal/forms/edit_training_modal";

const { Title, Paragraph, Text, Link } = Typography;
const { Search } = Input;
const columnsTable = [
      {
        title: "Training",
        dataIndex: "training",
      },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "Start date",
      dataIndex: "start_date",
    },
    {
      title: "End date",
      dataIndex: "end_date",
    },
    {
      title: "Start time",
      dataIndex: "start_time",
    },   
    {
      title: "End time",
      dataIndex: "end_time",
    },
    {
      title: "Scheduled at",
      dataIndex: "scheduled_at",
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: "10%",

    },
  ];

  

export default function TrainingEditManager() {
    const [listFormation, setListFormation] = useState([]);
    const [listFormationDB, setListFormationDB] = useState([]);
    const [selectedTraining, setSelectedTraining] = useState({});
    const [trainingColor, setTrainingColor] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [formLoading, setFormloading] = useState(false);
    const [formationDataControl, setFormationDataControl] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('none');
    const [totalScheduledTraining, setTotalScheduledTraining] = useState([])
    const [form] = Form.useForm();
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 7,
        },
      });

    useEffect(() => {
        setIsloading(true);
        baseurl.get("v1/manage_trainings_details/").then((resp) => {
            setListFormation(resp.data);
            setListFormationDB(resp.data)
            setTotalScheduledTraining(resp.data.length)
            setIsloading(false);
        }).catch((err) => {
            setIsloading(false);
            console.log("Error on get treaining list ", err)
        });
        setFormationDataControl(false)
    }, [formationDataControl]);

    const cancel = (e) => {
      console.log(e);
      message.error('Click on No');
    };

    const confirm = (e) => {
      console.log(e);
      message.success('Click on Yes');
    };

    
    const list_of_trainings = listFormation.map((item) => ({
        key: item.id,        
        training: (
          <>
            <Avatar.Group>
              <Avatar className="shape-avatar" src={''} size={25} alt="" />
              <div className="avatar-info">
              <div className="semibold">
              <Title level={5}>{item.formation.description}</Title>
            </div>
              </div>
            </Avatar.Group>
          </>
        ),
        capacity: (
            <>
              <div className="semibold">
              {item.capacity} Elements
            </div>
            </>
        ),
        start_date: (
            <>
              <div className="semibold">
              {/* {item.start_date} */}
              {moment(item.start_date).format('DD/MM/y')}

            </div>
            </>
        ),
        end_date: (
          <>
            <div className="semibold">
              {/* {item.end_date} */}
              {moment(item.end_date).format('DD/MM/y')}
            </div>
          </>
      ),
        start_time: (
          <>
            <div className="semibold">
              {/* {item.start_time} */}
              {moment(item.start_time, "HH:mm:ss").format("HH[h]mm")}
            </div>
          </>
      ),
        end_time: (
          <>
            <div className="semibold">
              {/* {item.end_time} */}
              {moment(item.end_time, "HH:mm:ss").format("HH[h]mm")}

            </div>
          </>
        ),
        // status: (
        //   <>
        //     {item.is_open ? (
        //       <div className="text-sm">Open</div>
        //     ) : (
        //       <div className="text-sm">Close</div>
        //     )}
        //   </>
        // ),
     
        scheduled_at: (
          <>
            <div className="semibold">
              {moment(item.created_at).format('DD/MM/y')}
            </div>
          </>
        ),
        action: (
            <div style={{
              display: "flex",
              
              justifyContent: "center"
              }}>
             {/* <div> */}
             {/* <Button onClick={(e) => controlEditorPage('edit_training', item)}>
              <EditOutlined /> Edit
            </Button> */}
                {/* &nbsp;
                &nbsp;
                &nbsp; */}
            {/* <Button style={{color: "red"}} onClick={(e) => deleteTrainingDetails(item)}>
              <DeleteOutlined /> Delete
            </Button> */}

<Popconfirm
    title="Delete TRAINING"
    description="Are you sure to delete this training??"
    onConfirm={(e) => deleteTrainingDetails(item)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Button danger>Delete</Button>
  </Popconfirm>
             {/* </div> */}
          </div>
        ),
      }));
      
      

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setListFormation(listFormationDB);
          }
    }

    const searchTraining = (value) => {
        if(value !== ''){
            const searched = listFormation.filter(entry => entry.description.toUpperCase().includes(value.toUpperCase()));
            setListFormation(searched)
        }else{
            setListFormation(listFormationDB);
        } 
    };

   
    const registeNewTraining = (value) => {
        setFormloading(true)
        const data = {
            training_name: value.training_name,
            color: trainingColor,
            workload: value.workload,
            details: value.details,
            operation_type: selectedOption,
            training_id: selectedTraining.id
        }

        baseurl.post('v1/manageformation/', data).then((resp) => {
            setFormloading(false);
            GenericSuccessMessage(resp.data.message);
            setFormationDataControl(true);
            form.resetFields();
            setOpenModal(false);
        }).catch((err) => {
            setFormloading(false)
            GenericErrorMessage('An unexpected error occurred, please try again.')
        })
        console.log("Submit the value ", value)
    };

    const controlEditorPage = (value, object=undefined) => {
      form.resetFields();
      setSelectedTraining({});
      if (value === 'edit_training'){
          setSelectedTraining(object)
      }
        setSelectedOption(value);
        setOpenModal(true);
    }

    const deleteTrainingDetails = (value) => {
      const data = {
        current_operation_type: "delete_training",
        training_id: value.id,
        deleted: true
      }

      baseurl.post('v1/manage_trainings_details/', data).then((resp) => {
        setFormloading(false);
        GenericSuccessMessage(resp.data.message);
        setFormationDataControl(true);
        form.resetFields();
        // setOpenModal(false);
    }).catch((err) => {
        setFormloading(false)
        GenericErrorMessage('An unexpected error occurred, please try again.')
    });
    }

    

    return (
        <Row gutter={[24, 0]}>
            <Col xs={24} md={24}>
                <Card
                    bordered={false}
                    title={"Training list"}>
                      <div style={{width:'40%'}}>
                                  Total scheduled trainings: {totalScheduledTraining}
                              </div>
                        <Space style={{width:'100%'}} size={5} direction="vertical">
                            <div style={{
                              display:'flex',
                              justifyContent:"space-between"
                            }}>
                              {/* <div style={{width:'40%'}}>
                                  <Input onChange={(e) => searchTraining(e.target.value)} prefix={<SearchOutlined />} placeholder="Search" />
                              </div> */}
                               
                              {/* <div>
                                <Button onClick={() => controlEditorPage('create')} type="primary"><PlusCircleOutlined size={'50px'} /></Button>
                              </div> */}
                            </div>
                            <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                <Table
                                loading={isLoading}
                                columns={columnsTable}
                                dataSource={list_of_trainings}
                                pagination={tableParams.pagination}
                                onChange={handleTableChange}
                                className="ant-border-space"
                                //   onRow={(record, rowIndex) => {
                                //     return {
                                //       onClick: event => {getSelectedCourse(events[rowIndex])},
                                //     };
                                //   }}
                                />
                            </div>
                        </Space>
                </Card>
            </Col>
            {/* <Col span={24} md={8} className="mb-24">
                    <Card>
                        {selectedOption == 'none' ?  <Result
                            title="Editing area"
                            subTitle="Select a training to start editing or create a new one."
                            extra={
                            <Button onClick={() => controlEditorPage('create')} type="primary" key="console">
                                Create new Training
                            </Button>
                            }
                        /> : <></>}
                        {selectedOption == 'edit' ?  <p>In development</p> : <></>}
                        {selectedOption == 'create' ? <div style={{
                            // border:'2px solid rgba(140, 140, 140, 0.35)',
                            alignItems:'center',
                            justifyContent:'center',
                            textAlign:'center',
                            padding:'2%',
                            borderRadius:'10px'
                        }}>
                            
                        </div> : <></>}
                        
                    </Card>
            </Col> */}
            {selectedOption == 'edit_training' ? <EditTrainingDetails 
              loading={formLoading}
              onFinish={registeNewTraining}
              open={openModal}
              setOpen={setOpenModal}
              trainingColor={setTrainingColor}
              traningDetails={selectedTraining}
              formControl={form} /> : <>
              </>}
            {/* {selectedOption == 'create' ? <CreateFormation 
              loading={formLoading}
              onFinish={registeNewTraining}
              trainingColor={setTrainingColor}
              open={openModal} 
              setOpen={setOpenModal} /> : <></>} */}
        </Row>
    )
};